:root {
	--white: white;
	--light-brown: #3c2a21;
	--midnight-blue-2: #1a2238;
	--background-color: #fafbfc;
	--tomato: #ff6a3d;
	--beige-dark: #e2dec7;
	--dark-slate-grey: #3f3f3f;
	--black-4: #161e33;
	--light-steel-blue: #9daad4;
	--dodger-blue: #3e85ed;
	--aquamarine: #3eedb6;
	--medium-aquamarine: #24ca95;
	--royal-blue-2: #1258bf;
	--midnight-blue: #1d1e50;
	--nav-hover-white: rgba(250, 251, 252, 0.05);
	--black: #333;
	--dark-brown: black;
	--royal-blue: #0e51b4;
	--dodger-blue-2: rgba(62, 133, 237, 0.75);
	--orange-red: #f95726;
	--black-3: rgba(22, 22, 22, 0.5);
	--beige-2: #f5f3e8;
	--maroon: #491615;
	--floral-white: #763811;
	--blue-client: #8d835b;
	--white-smoke: #f7f8f8;
	--slate-grey: #5c6e91;
	--dark-slate-blue-2: #485875;
	--olive-2: #8d835b;
	--beige: #efedda;
	--border-color: #9c3f3b;
	--maroon-2: #672300;
	--black-2: rgba(51, 51, 51, 0.5);
	--coral: #f87c2a;
	--goldenrod: #f5b62e;
	--dark-cyan: #108791;
	--teal: #097372;
	--dark-slate-grey-2: #046362;
	--chocolate: #e96a15;
	--olive: #8d835b;
	--dark-slate-blue: #756c48;
	--font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.w-layout-grid {
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.navbar {
	z-index: 1000;
	width: 100%;
	height: 110px;
	max-width: none;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 1px rgba(250, 251, 252, 0.25);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0;
	display: none;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: visible;
	box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);
}

.nav-link {
	width: auto;
	color: var(--white);
	letter-spacing: 0;
	text-transform: uppercase;
	justify-content: center;
	align-items: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding: 1.3rem 2rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 500;
	text-decoration: none;
	transition: background-color 0.3s;
	display: flex;
	position: static;
}

.nav-link:hover {
	width: auto;
	border-bottom: 3px none var(--light-brown);
	background-color: var(--light-brown);
	color: var(--white);
}

.nav-link.last {
	padding-right: 2rem;
}

.nav-link.first {
	text-transform: uppercase;
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 400;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.nav-link.first:hover {
	background-color: var(--light-brown);
}

.slider {
	height: 600px;
	border: 10px solid var(--beige-dark);
	top: 141px;
	left: 0%;
	right: 0%;
	box-shadow: 0 14px 4px rgba(0, 0, 0, 0.2);
}

.slider-one {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.container {
	height: 100vh;
}

.slide-nav {
	font-size: 10px;
	left: 0%;
}

.right-arrow,
.left-arrow {
	font-size: 2rem;
	display: block;
}

.nav-menu {
	width: 100%;
	height: auto;
	color: var(--white);
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-left: auto;
	font-family: Poppins, sans-serif;
	display: flex;
	position: static;
}

.brand {
	width: 100%;
	height: 125px;
	background-image: url('/assets/images/logo.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 100px;
	padding-top: 40px;
	padding-left: 0;
}

.home-content-boxes {
	height: 450px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.div-block {
	width: 33.3%;
	height: 300px;
	background-image: linear-gradient(rgba(62, 133, 237, 0.6), rgba(62, 133, 237, 0.6)), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-barbell.jpg');
	background-position: 0 0, 0 0, 0%;
	background-repeat: repeat, repeat, no-repeat;
	background-size: auto, auto, cover;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	padding-left: 64px;
	padding-right: 64px;
	display: flex;
	position: static;
	left: 14%;
}

.div-block.four {
	width: 33.3%;
	cursor: pointer;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-barbell-2.jpg');
	background-position: 0 0, 0%;
	background-repeat: repeat, no-repeat;
	background-size: auto, 700px;
	left: 0%;
}

.div-block.four:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-barbell-2.jpg');
	background-position: 0 0, 0 0, 0%;
	background-repeat: repeat, repeat, no-repeat;
	background-size: auto, auto, 700px;
}

.div-block-2 {
	width: 33.3%;
	height: 300px;
	cursor: pointer;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-weights.jpg');
	background-position: 0 0, 0%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 64px;
	padding-right: 64px;
	transition: transform 0.4s;
	display: flex;
	position: static;
	left: 5%;
}

.div-block-2:hover {
	opacity: 1;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-weights.jpg'), url('/assets/images/image-barbell.jpg');
	background-position: 0 0, 0 0, 0%, 0%;
	background-repeat: repeat, repeat, no-repeat, no-repeat;
	background-size: auto, auto, cover, cover;
}

.div-block-2.two {
	width: 66.7%;
	cursor: auto;
	background-image: linear-gradient(rgba(62, 133, 237, 0.6), rgba(62, 133, 237, 0.6)), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-barbell.jpg');
	background-position: 0 0, 0 0, 0%;
	background-repeat: repeat, repeat, no-repeat;
	background-size: auto, auto, cover;
	justify-content: center;
	align-items: center;
	padding-left: 64px;
	padding-right: 64px;
	display: flex;
	left: 0%;
}

.div-block-3 {
	width: 33.4%;
	height: 300px;
	cursor: pointer;
	background-image: url('/assets/images/image-ropes.jpg'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	background-position: 50% 80%, 0 0;
	background-repeat: no-repeat, repeat;
	background-size: 700px, auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-left: 64px;
	padding-right: 64px;
	display: flex;
	position: static;
	left: 0%;
}

.div-block-3:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-ropes.jpg'), linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
	background-position: 0 0, 50% 90%, 0 0;
	background-repeat: repeat, no-repeat, repeat;
	background-size: auto, 700px, auto;
}

.slide-heading {
	max-width: 600px;
	color: var(--white);
	letter-spacing: 0;
	text-transform: capitalize;
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 4rem;
	font-weight: 400;
	line-height: 4.7rem;
}

.slide-inner-content {
	height: 100%;
	max-width: none;
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.6), rgba(255, 255, 255, 0));
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 0 10rem;
	display: flex;
	position: relative;
	top: 0%;
	left: 0%;
}

.text-block {
	color: var(--white);
	letter-spacing: 2px;
	text-transform: uppercase;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 400;
	display: flex;
}

.text-block.gray {
	color: var(--dark-slate-grey);
	text-align: left;
	justify-content: center;
	margin-bottom: 0;
	line-height: 20px;
	position: relative;
	left: 0%;
}

.text-block.gray.about {
	text-transform: none;
	justify-content: flex-start;
	margin-bottom: 0;
	font-family: Work Sans, sans-serif;
	font-size: 16px;
}

.text-block.gray.icon {
	text-align: center;
	margin-bottom: 0;
	font-size: 12px;
}

.text-block.gray.newsletter {
	color: var(--white);
}

.text-block.white {
	text-align: center;
	margin-bottom: 0;
	font-size: 16px;
}

.text-block.hero-image {
	letter-spacing: 0;
	text-transform: none;
	margin-bottom: 40px;
	font-family: Work Sans, sans-serif;
	font-size: 1.15rem;
}

.div-block-4 {
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	display: flex;
}

.button {
	z-index: 5;
	background-color: var(--black-4);
	color: var(--light-steel-blue);
	letter-spacing: 0.5px;
	text-transform: capitalize;
	cursor: pointer;
	background-image: url('/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button:hover {
	background-color: var(--light-steel-blue);
	opacity: 1;
	color: var(--white);
}

.button.two {
	background-color: var(--white);
	color: var(--dodger-blue);
	display: none;
	top: 180px;
}

.button.training {
	background-color: var(--aquamarine);
	position: static;
	top: 80%;
}

.button.training:hover {
	background-color: var(--medium-aquamarine);
}

.button.training-session {
	background-color: var(--royal-blue-2);
	position: relative;
	top: auto;
}

.button.secondary {
	border-bottom: 1px none var(--midnight-blue-2);
	color: var(--white);
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/purple-arrow-01.svg');
	padding-left: 0;
}

.button.secondary:hover {
	border-bottom: 1px none var(--white);
	color: var(--white);
	background-position: 93%;
}

.button.secondary.inside-pages {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 1rem;
}

.div-block-5 {
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-top: 5rem;
	display: flex;
}

.button-border {
	width: 180px;
	height: 38px;
	border: 2px solid var(--dodger-blue);
	position: absolute;
	top: 135px;
	bottom: 0%;
	left: 44%;
	right: 0%;
}

.button-border.two {
	width: 90px;
	border-color: var(--white);
	display: none;
	top: 190px;
	left: 45%;
}

.button-border.training {
	top: 83%;
	left: 8%;
}

.div-block-7 {
	height: 200px;
	background-color: #a33434;
}

.content-headings {
	max-width: 500px;
	border-right: 5px none var(--aquamarine);
	color: var(--black-4);
	text-align: center;
	letter-spacing: 1px;
	text-indent: 0;
	text-transform: capitalize;
	margin-top: 0;
	margin-bottom: 0.5rem;
	margin-right: 0;
	padding-right: 0;
	font-family: Work Sans, sans-serif;
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 2.25rem;
	display: inline-block;
}

.content-headings.quote {
	text-align: left;
	text-indent: -10px;
	font-size: 18px;
}

.content-headings.gray {
	color: var(--dark-slate-grey);
	font-size: 18px;
	position: relative;
	left: 5%;
}

.content-headings.blue {
	color: var(--dodger-blue);
	text-indent: 0;
	justify-content: center;
	margin-top: 0;
	display: flex;
	position: relative;
	left: 0%;
}

.content-headings.blue.about {
	text-align: center;
	text-indent: 0;
	justify-content: flex-start;
	font-size: 18px;
}

.content-headings.blue.icon {
	text-indent: 0;
	margin-bottom: 20px;
}

.content-headings.blue.newsletter {
	color: var(--white);
	text-align: left;
}

.content-headings.white {
	color: var(--beige-dark);
	text-align: left;
	text-indent: 0;
	text-transform: capitalize;
	border-right-style: none;
	margin-top: 0;
	margin-bottom: 0.5rem;
	padding-right: 0;
	font-family: Libre Baskerville, sans-serif;
	font-weight: 400;
	line-height: 2.3rem;
}

.content-headings.white.review-text {
	color: var(--midnight-blue);
	text-align: left;
	text-transform: none;
	margin-right: 0;
	padding-right: 0;
}

.content-headings.white.form-section {
	margin-bottom: 0.5rem;
}

.content-headings.white.contact {
	text-align: center;
	margin-bottom: 1rem;
}

.content-headings.footer {
	max-width: none;
	color: var(--background-color);
	text-align: left;
	letter-spacing: 0.5px;
	text-indent: 0;
	border-right-style: none;
	margin-top: 0;
	margin-right: 0;
	padding-right: 0;
	font-size: 1.2rem;
}

.content-headings.testimonials {
	color: var(--black-4);
	margin-bottom: 2.5rem;
	font-size: 2.25rem;
	line-height: 2.25rem;
}

.div-block-8 {
	height: 300px;
}

.div-block-9 {
	width: 100%;
	height: 300px;
	flex-direction: row;
	justify-content: flex-start;
	display: flex;
}

.div-block-10 {
	width: 100%;
	height: 300px;
	flex-direction: row;
	justify-content: flex-end;
	display: flex;
}

.home-content-section {
	height: auto;
	background-color: var(--white);
	justify-content: center;
	align-items: center;
	display: flex;
}

.home-content-section.trainer {
	height: auto;
	background-color: var(--background-color);
	display: flex;
}

.home-content-section.reviews {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
}

.home-content-section.banner {
	height: auto;
	background-color: var(--background-color);
	flex-direction: column;
	display: flex;
}

.home-content-section.banner.image {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	position: static;
}

.home-content-section.banner.two {
	height: 300px;
	flex-direction: row;
	justify-content: center;
	display: none;
}

.section-content {
	width: auto;
	height: auto;
	max-width: 1600px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	padding: 4rem;
	display: flex;
	position: static;
	left: 5%;
}

.section-content.contact-call-out {
	max-width: none;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/austin-distel-wawEfYdpkag-unsplash.jpg');
	background-position: 0 0, 50% 0;
	background-size: auto, cover;
	padding-top: 5rem;
	padding-bottom: 5rem;
}

.div-block-12 {
	width: 40%;
	height: 400px;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-ropes.jpg');
	background-position: 50% 90%;
	background-size: 800px;
	margin-left: 200px;
	display: block;
	position: static;
	left: 5%;
}

.div-block-13 {
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	padding: 4rem;
	display: flex;
	position: relative;
	left: 0%;
}

.paragraph {
	width: auto;
	max-width: 1000px;
	color: #672300;
	text-align: center;
	margin-bottom: 2.25rem;
	padding-left: 0;
	padding-right: 0;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.paragraph.newsletter {
	width: 100%;
	color: var(--white);
}

.paragraph.white {
	max-width: 500px;
	color: var(--white);
	text-align: left;
	margin-bottom: 2rem;
	font-size: 1rem;
	line-height: 1.25rem;
}

.paragraph.white.center,
.paragraph.section {
	margin-bottom: 0;
}

.div-block-14 {
	width: 100%;
	height: auto;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	display: flex;
}

.div-block-14.newsletter {
	width: auto;
	justify-content: flex-start;
	margin-top: 0;
}

.div-block-15 {
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	margin-bottom: 40px;
	padding-top: 40px;
	display: block;
	position: relative;
	left: 0%;
}

.div-block-16 {
	width: auto;
	height: 200px;
	max-width: 1600px;
	margin-left: 0;
	display: flex;
}

.review-block {
	width: 100%;
	height: 200px;
	padding-left: 32px;
	padding-right: 32px;
	position: static;
	left: 10%;
}

.review-block.two {
	border: 3px none var(--dodger-blue);
	left: 40%;
}

.review-block.three {
	left: 70%;
}

.div-block-18 {
	width: 30%;
	height: 200px;
	position: absolute;
	left: 40%;
}

.div-block-19 {
	width: 30%;
	height: 200px;
	position: absolute;
	left: 70%;
}

.paragraph-2,
.paragraph-3 {
	text-align: left;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
}

.paragraph-4 {
	text-align: left;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 400;
}

.review-name {
	text-transform: uppercase;
	font-family: Montserrat, sans-serif;
	font-size: 10px;
	font-weight: 600;
}

.rights-container-block {
	width: 100%;
	max-width: 1600px;
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid rgba(255, 255, 255, 0.05);
	justify-content: space-between;
	align-items: stretch;
	padding-top: 1rem;
	padding-left: 5rem;
	padding-right: 5rem;
	display: flex;
	position: static;
	left: 10%;
}

.footer-column-rights {
	width: 50%;
	justify-content: flex-start;
	margin-left: 0;
	margin-right: 0;
	padding-bottom: 0.5rem;
	display: flex;
}

.footer-text {
	color: var(--white);
	text-align: left;
	letter-spacing: 0;
	text-transform: capitalize;
	cursor: pointer;
	flex: 0 auto;
	justify-content: center;
	margin: 0 20px 20px 0;
	padding: 0;
	font-family: Work Sans, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
	display: flex;
}

.footer-text:hover {
	border-bottom: 3px none var(--dodger-blue);
	color: var(--tomato);
	text-decoration: none;
}

.footer-text.rights {
	margin-bottom: 0;
}

.footer-text.rights:hover {
	color: var(--white);
	border-bottom-style: none;
}

.grip-link {
	width: 50%;
	justify-content: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding-bottom: 0.5rem;
	display: flex;
}

.footer-section {
	border-top: 5px none var(--aquamarine);
	background-color: var(--black-4);
	flex-direction: column;
	align-items: center;
	margin-left: 0;
	display: flex;
}

.quote-block {
	width: 50px;
	height: 50px;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 35px;
	margin-bottom: 0;
}

.heading-2 {
	color: var(--white);
}

.div-block-37 {
	min-width: 0%;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	display: none;
	position: static;
	left: 0%;
}

.div-block-38,
.div-block-39,
.div-block-40 {
	width: 30%;
	height: 200px;
	justify-content: center;
	align-items: center;
	display: flex;
}

.program-block {
	width: 30%;
	height: 300px;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-weights.jpg');
	background-position: 0 0, 50% 100%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	position: absolute;
	left: 10%;
}

.program-block.two {
	background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/assets/images/image-barbell.jpg');
	background-position: 0 0, 0%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	left: 40%;
}

.program-block.three {
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-ropes.jpg');
	background-position: 0 0, 50% 100%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	position: absolute;
	left: 70%;
}

.footer-heading {
	color: #fff;
	letter-spacing: -0.02rem;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 0.5rem;
	font-size: 1.15rem;
	font-weight: 400;
	line-height: 1.7rem;
}

.footer-nav-list-link {
	color: #fff;
	text-transform: capitalize;
	margin-top: 0.25rem;
	padding-left: 0;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.25rem;
	text-decoration: none;
	transition: transform 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
	display: block;
}

.footer-nav-list-link:hover {
	color: var(--tomato);
}

.footer-right-col-wrap {
	min-width: auto;
	padding-top: 0;
	padding-bottom: 0;
}

.container-block {
	max-width: 1270px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.container-block.footer-container {
	width: 100%;
	max-width: 1600px;
	justify-content: space-around;
	margin-left: 0;
	margin-right: 0;
	padding: 4rem 5rem;
	display: block;
	position: static;
	left: 10%;
}

.div-block-41 {
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.submit-button {
	display: none;
}

.social-media-link-block {
	width: 30px;
	height: 30px;
	border: 0.25px solid var(--white);
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0%;
	flex: none;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	margin-right: 0.5rem;
	transition: all 0.25s cubic-bezier(0.842, 0.007, 0.772, 1.001);
	display: flex;
}

.social-media-link-block:hover {
	border-style: solid;
	border-color: var(--light-steel-blue);
	background-color: var(--light-steel-blue);
	border-radius: 0;
}

.social-media-link-block.first-social-link {
	border: 1px solid var(--white);
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0%;
	margin-left: 0;
}

.social-media-link-block.first-social-link:hover {
	background-color: var(--dodger-blue);
	opacity: 1;
	border-style: none;
	border-radius: 0;
	margin-right: 0;
}

.social-media-link-block.twitter,
.social-media-link-block.youtube,
.social-media-link-block.linkedin {
	border-style: solid;
}

.footer-nav-wrapper {
	width: 100%;
	max-width: none;
	justify-content: space-between;
	padding-top: 0;
	padding-bottom: 0;
	display: flex;
}

.footer-nav-item {
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-weight: 300;
}

.footer-paragraph {
	color: #fff;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 16px;
}

.footer-paragraph.phone-paragraph {
	padding-left: 0;
}

.footer-col-wrap {
	width: 40%;
	min-width: auto;
	margin-right: 0;
	padding-right: 1rem;
}

.footer-col-wrap.footer-col-4 {
	width: 25%;
	min-width: auto;
	margin-right: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.footer-col-wrap.quick-links,
.footer-col-wrap.support {
	width: auto;
	min-width: auto;
	margin-right: 1rem;
	padding-left: 1rem;
	padding-right: 1rem;
}

.footer-col-wrap.connect {
	width: auto;
	min-width: auto;
	flex-direction: column;
	align-items: flex-start;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.goog-te-combo {
	min-height: 34px;
	min-width: 220px;
	margin-top: 2px;
	padding: 8px 20px 8px 8px;
	font-size: 0.9rem;
	line-height: 1.5rem;
}

.form-block {
	display: block;
}

.google-translate {
	clear: both;
}

.social-media-wrapper {
	z-index: 1000;
	width: 100%;
	height: 2.5rem;
	float: left;
	flex: 0 auto;
	order: 0;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
	position: relative;
}

.multicol-wrap {
	width: 25%;
	justify-content: space-between;
	display: flex;
}

.contact-link {
	color: #fff;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.contact-link:hover {
	color: var(--tomato);
}

.google-translate-outer-wrap {
	display: none;
}

.footer-nav-list {
	color: #fff;
	margin-top: 0.25rem;
	font-weight: 300;
	text-decoration: none;
	display: block;
}

.footer-nav-list:hover {
	color: #b3d236;
}

.footer-nav-list.secondcolumn {
	padding-left: 60px;
}

.nav-dropdown:hover {
	color: var(--dodger-blue);
}

.div-block-42 {
	width: auto;
	height: auto;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	display: flex;
	position: relative;
	left: 0%;
}

.div-block-43 {
	justify-content: center;
	align-items: center;
	margin-top: 20px;
	display: flex;
	position: static;
	left: 5%;
}

.div-block-44 {
	width: auto;
	justify-content: center;
	align-items: stretch;
	margin-left: 200px;
	display: flex;
	position: static;
	left: 6%;
}

.icon-block {
	width: 200px;
	height: 250px;
	cursor: pointer;
	background-image: url('/assets/images/workout-icons-02.png');
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: 200px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	margin-left: 80px;
	margin-right: 80px;
	padding-left: 32px;
	padding-right: 32px;
	transition: all 0.4s;
	display: flex;
}

.icon-block:hover {
	transform: scale(1.05);
	box-shadow: 8px 8px 16px -8px rgba(0, 0, 0, 0.2);
}

.icon-block.two {
	height: auto;
	background-image: url('/assets/images/workout-icons-03.png');
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: 200px;
}

.icon-block.three {
	height: auto;
	background-image: url('/assets/images/workout-icons-04.png');
	background-position: 50% 0;
	background-repeat: no-repeat;
	background-size: 200px;
	margin-left: 60px;
	margin-right: 60px;
}

.div-block-45 {
	width: 45px;
	height: 50px;
	background-image: url('/assets/images/arrow-icons-04.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 35px;
	position: relative;
	left: 6%;
}

.div-block-46 {
	display: none;
}

.secondary-nav-text {
	float: left;
	display: none;
}

.secondary-nav-text.indicator-text {
	min-width: 18px;
	text-align: center;
	letter-spacing: 0;
	background-color: #000;
	justify-content: center;
	align-self: center;
	align-items: center;
	padding: 2px 3px;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 0.625rem;
	font-weight: 700;
	line-height: 0.875rem;
	display: flex;
	position: absolute;
	bottom: 0;
	right: 0;
}

.container-4 {
	max-width: 1200px;
	padding-left: 25px;
	padding-right: 25px;
}

.container-4.nav-container-wrap {
	max-width: 1600px;
	text-align: center;
	justify-content: center;
	align-items: stretch;
	padding-left: 2%;
	padding-right: 2%;
	display: flex;
}

.container-4.header-bar-container {
	max-width: 1600px;
	justify-content: space-between;
	padding-left: 2%;
	padding-right: 2%;
}

.nav-drop-list-column-title {
	color: #000;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	background-color: #d0d0d4;
	margin-bottom: 0;
	padding: 1.25em 1.5rem 1.125em 0.8125rem;
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1em;
}

.nav-drop-shade-tile-link-label {
	text-align: center;
	letter-spacing: 0.05em;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0.25);
	padding: 0.75rem 0.25rem;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1em;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}

.nav-drop-column-wrap {
	min-width: 25%;
	flex-grow: 1;
	box-shadow: -1px 0 #e6e6e6;
}

.nav-drop-column-wrap.first-nav-drop-column {
	box-shadow: none;
	border-left-style: none;
}

.nav-menu-wrap {
	width: 100%;
	float: left;
	text-align: left;
	border-bottom: 1px #000;
	justify-content: flex-end;
	align-items: stretch;
	display: flex;
}

.nav-drop-column-list {
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	padding-left: 0;
	font-size: 1rem;
	line-height: 1.25em;
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list.w--open {
	background-color: #fff;
	border-top: 1px solid #ccccd6;
	flex: 1;
	order: 1;
	display: flex;
	top: 100%;
	left: 0;
	box-shadow: 0 3px 15px -5px rgba(0, 0, 0, 0.6);
}

.nav-drop-shade-tile-link {
	color: #fff;
	background-color: #3128b1;
	flex-grow: 1;
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
}

.nav-drop-shade-tile-link:hover {
	color: #fff;
}

.secondary-nav-list {
	height: 100%;
	justify-content: flex-end;
	align-self: stretch;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 1rem;
	line-height: 1.375em;
	display: flex;
}

.nav-drop-icon {
	margin-right: 1.5rem;
	font-size: 0.75em;
	line-height: 1.125em;
	left: auto;
	right: 0;
}

.nav-drop-shade-tile {
	flex-grow: 1;
	flex-basis: 14%;
	display: flex;
}

.nav-drop-shade-tile.intro-shade-tile {
	flex-basis: 100%;
	padding: 0;
}

.logo {
	width: auto;
	height: 100%;
	float: none;
	border-bottom-style: none;
	flex: none;
	order: -1;
	align-self: center;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 0.4rem;
	font-size: 1.625rem;
	font-weight: 600;
	line-height: 1.25rem;
	display: none;
	overflow: hidden;
}

.logo:hover {
	color: #fff;
}

.logo.footer {
	width: 30%;
	height: auto;
	margin-bottom: 1rem;
	margin-left: -0.5rem;
	display: flex;
}

.nav-drop-list-link {
	color: #000;
	background-position: 0 10px;
	background-repeat: no-repeat;
	background-size: 7px 7px;
	padding: 0.325em 1.5rem 0.325em 0.625rem;
	font-size: 0.9375rem;
	font-weight: 300;
	line-height: 1.25em;
	display: block;
}

.nav-drop-list-link:hover {
	color: #534bc5;
	padding-left: 1rem;
	padding-right: 1.125rem;
}

.nav-drop-list-link.w--current {
	color: #fff;
}

.nav-drop-shade-tile-text-tile {
	color: #000;
	text-align: left;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	background-color: #d0d0d4;
	background-image: none;
	flex-grow: 1;
	align-items: center;
	padding: 1.25em 1.5rem 1.125em 0.8125rem;
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 1em;
	display: flex;
}

.nav-link-2 {
	box-shadow: none;
	color: #000;
	text-align: center;
	text-transform: uppercase;
	flex: 1 0 auto;
	margin-right: 1px;
	padding: 1rem 0.5rem;
	font-size: 1.25rem;
	font-weight: 800;
	line-height: 1.375rem;
	display: inline-block;
}

.nav-link-2:hover {
	color: #3128b1;
	background-color: rgba(0, 0, 0, 0);
	box-shadow: inset 0 -3px #3128b1;
}

.nav-link-2.w--current {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -3px #fff;
}

.nav-link-2.w--current:hover {
	color: #fff;
	background-color: rgba(0, 0, 0, 0.1);
}

.nav-drop-wrap {
	width: auto;
	flex-flow: wrap;
	flex: 1 0 auto;
	justify-content: center;
	align-items: center;
	display: flex;
	position: static;
}

.secondary-nav-list-item {
	float: left;
	letter-spacing: 0.01em;
	border-right: 1px rgba(255, 255, 255, 0.25);
	align-self: stretch;
	align-items: center;
	margin-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.header-menu-wrap {
	width: 100%;
	border-bottom: 3px solid var(--dark-slate-grey);
	background-color: var(--white);
	justify-content: space-between;
	display: block;
	position: static;
}

.nav-drop-toggle-link {
	width: auto;
	color: var(--dark-slate-grey);
	text-align: center;
	letter-spacing: 1px;
	text-transform: uppercase;
	padding: 1rem 2rem 1rem 0.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 11px;
	font-weight: 800;
	text-decoration: none;
}

.nav-drop-toggle-link:hover,
.nav-drop-toggle-link.w--current {
	color: #3128b1;
}

.link-2.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-2.dropdown-list-column-title-link {
	color: #000;
}

.link-2.dropdown-list-column-title-link:hover {
	color: #534bc5;
}

.two-part-button-wrap {
	max-width: 768px;
	margin-left: auto;
	margin-right: auto;
	display: block;
	position: relative;
}

.nav-drop-shade-inner-wrap {
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.nav-drop-list-item {
	letter-spacing: 0.03em;
	margin-bottom: 0;
	padding-left: 0.75rem;
	padding-right: 1.5rem;
}

.nav-drop-list-column-subtitle {
	text-transform: uppercase;
	margin-left: 1px;
	padding-top: 0.125em;
	padding-bottom: 0.125em;
	font-size: 1.1875rem;
	font-weight: 800;
	line-height: 1.25em;
	display: inline-block;
}

.logo-image {
	width: 150px;
	float: none;
}

.secondary-nav-icon {
	width: 22px;
	height: 22px;
	float: left;
	margin-left: auto;
	margin-right: auto;
	padding: 0;
	display: block;
}

.secondary-nav-icon.basket-icon {
	width: 1.625em;
	height: 1.625em;
}

.secondary-nav-icon.account {
	width: 100%;
	height: 100%;
}

.secondary-nav-list-link {
	float: left;
	opacity: 1;
	color: #fff;
	border-bottom-style: none;
	justify-content: center;
	align-self: stretch;
	align-items: center;
	padding-left: 1.5rem;
	padding-right: 1.5rem;
	font-size: 0.75rem;
	display: flex;
	position: relative;
}

.secondary-nav-list-link:hover {
	opacity: 1;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
}

.secondary-nav-list-link.account {
	width: 1.5rem;
	height: 1.5rem;
	padding-right: 0;
	overflow: hidden;
}

.nav-drop-toggle {
	width: 50%;
	color: rgba(0, 0, 0, 0.4);
	order: -1;
	justify-content: space-between;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	transition: all 0.2s;
	display: flex;
	position: relative;
	box-shadow: 0 -3px rgba(0, 0, 0, 0);
}

.nav-drop-toggle:hover {
	color: #3128b1;
}

.nav-drop-toggle.w--open {
	color: #3128b1;
	box-shadow: 0 -3px rgba(0, 0, 0, 0), inset 0 -3px #3128b1;
}

.nav-drop-shade-tile-image {
	width: 100%;
	flex-grow: 1;
	flex-basis: 100%;
	transition: all 0.35s;
}

.nav-drop-shade-tile-image:hover {
	opacity: 0.7;
	filter: grayscale(30%);
}

.nav-bar-link {
	color: var(--black);
	text-align: center;
	letter-spacing: 1px;
	text-transform: capitalize;
	justify-content: center;
	align-items: flex-end;
	padding: 1rem 2rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	text-decoration: none;
	transition: color 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
	display: flex;
}

.nav-bar-link:hover {
	border-bottom: 5px none var(--dodger-blue);
	color: var(--dodger-blue);
}

.header-bar-column {
	width: auto;
	height: 100%;
	flex-grow: 1;
	justify-content: flex-end;
	align-self: stretch;
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	display: none;
}

.header-bar-column.header-bar-left-column {
	justify-content: flex-start;
	align-self: center;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 0.875rem;
	line-height: 1.5em;
	display: none;
}

.header-mobile-search-reveal {
	height: auto;
	min-height: auto;
	background-color: var(--dark-brown);
	align-items: center;
	margin-right: 0;
	transition: background-color 0.2s;
	display: flex;
}

.header-mobile-search-reveal:hover {
	background-color: var(--light-brown);
}

.form-field {
	width: auto;
	max-width: 100%;
	border-radius: 0;
	margin-bottom: 0;
	padding: 4px 10px;
	font-size: 0.875em;
	position: relative;
}

.form-field.header-search-field {
	width: 100px;
	height: 2.25rem;
	background-color: var(--white);
	color: rgba(0, 0, 0, 0);
	border: 0 rgba(0, 0, 0, 0);
	border-radius: 20px 0 0 20px;
	margin-bottom: 0;
	padding-left: 10px;
	padding-right: 10px;
	font-size: 0.75em;
	transition: all 0.35s;
}

.form-field.header-search-field:focus {
	width: 180px;
	background-color: rgba(33, 41, 49, 0.15);
	border: 0 rgba(0, 0, 0, 0);
}

.header-bar-content-wrap {
	width: 100%;
	max-width: none;
	justify-content: space-around;
	align-items: center;
	display: flex;
}

.header-search-form {
	border: 1px rgba(255, 255, 255, 0.27);
	border-bottom-color: rgba(255, 255, 255, 0.6);
	padding: 2px 5px;
	display: flex;
}

.button-2 {
	color: #fff;
	text-align: center;
	letter-spacing: 0.07em;
	text-transform: uppercase;
	background-color: #000;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1em;
	font-size: 1.0625em;
	font-weight: 700;
	line-height: 1.125em;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s;
	display: inline-block;
}

.button-2:hover {
	color: #fff;
	background-color: #3128b1;
}

.button-2:active {
	background-color: #211a80;
	box-shadow: 0 0 rgba(0, 0, 0, 0), inset 0 0 rgba(0, 0, 0, 0);
}

.button-2:focus {
	background-color: #534bc5;
}

.button-2.header-search-button {
	z-index: 5;
	width: 38px;
	background-color: var(--royal-blue);
	color: rgba(0, 0, 0, 0);
	text-transform: uppercase;
	background-image: url('/assets/images/icn_search-white.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 1em 1em;
	border: 1px #000;
	border-radius: 100%;
	margin: 0 0;
	padding: 0 0;
	font-size: 1.125em;
	line-height: 1em;
	transition: all 0.35s;
	position: relative;
	left: -15px;
}

.button-2.header-search-button:hover {
	background-color: #3128b1;
	box-shadow: 0 1px #3128b1;
}

.header-search-session-message {
	max-height: 2.25rem;
	max-width: 220px;
	text-align: left;
	background-color: #3128b1;
	padding: 3px 5px;
	font-size: 0.75rem;
	line-height: 1.375em;
	overflow: hidden;
}

.header-search-session-message.error-message {
	max-height: 3.5rem;
	margin-top: 0.5rem;
	padding-top: 5px;
	padding-bottom: 5px;
	position: absolute;
	top: 100%;
	right: 0;
}

.header-bar-promo-icon {
	width: 1.125em;
	height: 1.125em;
	margin-right: 0.625em;
}

.header-bar-wrap {
	width: 100%;
	background-color: var(--dodger-blue);
	color: #fff;
	position: relative;
}

.header-search-wrap {
	color: var(--white);
	margin-bottom: 0;
	margin-left: 1rem;
	position: relative;
}

.header-bar-wrapper {
	width: 100%;
	max-width: none;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 0 solid rgba(255, 255, 255, 0.25);
	justify-content: flex-start;
	align-items: stretch;
	padding: 0;
	display: none;
	position: relative;
}

.nav-bar-dropdown {
	color: var(--dark-slate-grey);
	letter-spacing: 1px;
	text-transform: uppercase;
	align-items: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 500;
	transition: background-color 0.3s;
	display: flex;
}

.nav-bar-dropdown:hover {
	border-bottom: 5px none var(--dodger-blue);
}

.text-block-2 {
	color: var(--dark-slate-grey);
	transition: color 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.text-block-2:hover {
	color: var(--dodger-blue);
}

.dropdown-toggle {
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 20px;
}

.dropdown-toggle:hover {
	color: var(--dodger-blue);
}

.dropdown-wrap {
	padding: 0 10px 0 0;
}

.dropdown-wrap:hover {
	color: var(--dodger-blue);
}

.dropdown-wrap.w--open {
	background-color: var(--light-brown);
}

.dropdown-list {
	margin-top: -5px;
}

.dropdown-list.w--open {
	border-top: 4px none var(--midnight-blue);
	background-color: var(--white);
	margin-top: 0;
	top: 62px;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.25);
}

.dropdown-link {
	color: var(--black);
	letter-spacing: 0;
	text-transform: capitalize;
	background-position: 5%;
	background-repeat: no-repeat;
	background-size: 6px;
	border-bottom: 1px solid rgba(51, 51, 51, 0.03);
	padding: 10px 1.5rem;
	font-family: Poppins, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	transition: transform 0.35s cubic-bezier(0.39, 0.575, 0.565, 1), color 0.35s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.dropdown-link:hover {
	background-color: var(--background-color);
	color: var(--light-brown);
	background-position: 5%;
	background-repeat: no-repeat;
	background-size: 6px;
}

.dropdown-list-2 {
	margin-top: -3px;
}

.dropdown-list-2.w--open {
	border-top: 4px solid var(--dodger-blue);
	background-color: var(--black);
	margin-top: 0;
	top: 86px;
}

.content-heading {
	color: #fff;
	text-align: left;
	letter-spacing: 2px;
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 20px;
	font-family: Montserrat, sans-serif;
	font-size: 2.75rem;
	line-height: 2.75rem;
}

.content-heading.projects {
	color: #fff;
	text-align: left;
	margin-bottom: 20px;
	font-size: 2.75rem;
	font-weight: 700;
	line-height: 2.75rem;
	text-decoration: none;
}

.paragraph-5 {
	color: #fff;
	text-align: left;
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	line-height: 1rem;
}

.paragraph-5.hero-image {
	margin-bottom: 20px;
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.25rem;
}

.project-block-content {
	width: 100%;
	height: 450px;
	max-width: 1600px;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: row;
	justify-content: space-around;
	margin-top: 0;
	padding: 4rem;
	display: flex;
}

.div-block-68 {
	align-items: stretch;
	display: flex;
}

.div-block-69 {
	padding-left: 20px;
}

.div-block-70 {
	height: 45px;
	cursor: pointer;
	border: 3px #fff;
	justify-content: flex-start;
	align-items: center;
	margin-right: 0;
	padding-left: 0;
	display: flex;
}

.links-a {
	width: 100%;
	height: 100%;
	background-color: rgba(14, 35, 105, 0.85);
	padding-top: 80px;
	padding-left: 40px;
	padding-right: 20px;
	transition: background-color 0.5s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.links-a:hover {
	background-color: #0e2369;
}

.links-a.project-1 {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #233e99;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-right: 40px;
	text-decoration: none;
	transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	display: flex;
}

.links.project-1:hover {
	background-color: rgba(62, 133, 237, 0.75);
}

.links._2 {
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	flex: 0 auto;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-right: 40px;
	text-decoration: none;
	transition-duration: 0.5s;
	transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
	display: flex;
}

.links._2:hover {
	background-color: rgba(62, 133, 237, 0.75);
}

.links._3 {
	background-color: var(--dodger-blue-2);
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-right: 40px;
	text-decoration: none;
	transition-duration: 0.25s;
	display: flex;
}

.links._3:hover {
	background-color: var(--dodger-blue-2);
}

.div-block-65 {
	width: 2px;
	height: 40px;
	background-color: #fd7e0f;
	border: 1px #000;
	border-radius: 0;
	margin-bottom: 20px;
}

.div-block-65.projetcs {
	width: 6px;
}

.div-block-58 {
	width: 50px;
	height: 50px;
	background-image: none;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	margin-left: -20px;
	display: block;
}

.project-block {
	width: 33.33%;
	height: 450px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/cover-image.jpg');
	background-position: 50% 75%;
	background-repeat: no-repeat;
	background-size: cover;
	border-right: 1px solid rgba(241, 238, 238, 0.25);
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	margin-right: 0;
	padding-left: 0;
	padding-right: 0;
	text-decoration: none;
	display: flex;
	position: static;
	left: 5%;
}

.project-block:hover {
	opacity: 1;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-ropes.jpg');
	background-position: 50% 75%;
	background-size: 1000px;
}

.project-block._2 {
	width: 33.33%;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-weights.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	border-top: 1px rgba(56, 56, 56, 0.25);
	border-right-style: solid;
	margin-right: 0;
}

.project-block._2:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-weights.jpg');
	background-position: 0 0;
	background-size: cover;
}

.project-block.black {
	width: 33.33%;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-barbell.jpg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: cover;
	border-right-style: none;
	margin-right: 0;
}

.project-block.black:hover {
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/image-barbell.jpg');
	background-position: 0 0;
	background-size: cover;
}

.project-block.black._04 {
	background-image: url('/assets/images/image-barbell.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
}

.project-block.two {
	width: 66.67%;
	background-image: linear-gradient(to bottom, var(--dodger-blue-2), var(--dodger-blue-2)), url('/assets/images/image-barbell.jpg');
	cursor: auto;
	background-position: 0 0, 0 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	border-right-style: none;
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-right: 0;
	padding-left: 64px;
	padding-right: 64px;
	display: flex;
	left: 0%;
}

.arrow-button {
	align-items: center;
	margin-top: 0%;
	display: flex;
}

.arrow-button.projects {
	z-index: 5;
	margin-top: 200px;
	padding-left: 40px;
	position: absolute;
}

.link-text {
	color: #fff;
	letter-spacing: 1px;
	align-items: center;
	margin-left: 0;
	margin-right: 20px;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	text-decoration: none;
	display: flex;
}

.link-text:hover {
	color: #fff;
}

.button-border-2 {
	width: 180px;
	height: 38px;
	border: 2px solid #3e85ed;
	position: absolute;
	top: 135px;
	bottom: 0%;
	left: 44%;
	right: 0%;
}

.button-border-2.two {
	width: 90px;
	border-color: #fff;
	display: none;
	top: 190px;
	left: 45%;
}

.button-3 {
	z-index: 5;
	letter-spacing: 2px;
	text-transform: uppercase;
	background-color: #3e85ed;
	font-family: Montserrat, sans-serif;
	font-size: 10px;
	font-weight: 700;
	transition: all 0.4s, background-color 0.4s;
	position: absolute;
	top: auto;
}

.button-3:hover {
	opacity: 1;
	color: #fff;
	background-color: #0e51b4;
}

.button-3.two {
	color: #3e85ed;
	background-color: #fff;
	display: none;
	top: 180px;
}

.content-headings-2 {
	color: #fff;
	text-align: left;
	letter-spacing: 2px;
	text-indent: -10px;
	text-transform: uppercase;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 26px;
}

.content-headings-2.quote {
	text-align: left;
	text-indent: 0;
	text-transform: none;
	font-size: 2rem;
	line-height: 2.75rem;
}

.text-block-3 {
	padding-left: 10px;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.content-block-two {
	width: 100%;
	height: 550px;
	background-color: var(--background-color);
	background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 100%;
	background-size: auto, cover;
	justify-content: center;
	align-items: flex-end;
	padding: 0;
	display: flex;
}

.content-block-two.solid-background {
	width: auto;
	height: 450px;
	background-color: var(--black-4);
	background-image: none;
	align-items: center;
	margin-left: auto;
	padding: 3rem;
	position: relative;
	right: 0;
}

.div-block-72 {
	width: 50%;
	height: 450px;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/image-weights.jpg');
	background-position: 0 0, 0 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	justify-content: center;
	align-items: center;
	padding: 45px;
	display: flex;
}

.form-2 {
	width: 250px;
	flex-flow: wrap;
	justify-content: flex-start;
	align-items: center;
	display: flex;
}

.post-button {
	color: #fff;
	letter-spacing: 0.5px;
	margin-bottom: 0;
	padding-right: 5px;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	font-weight: 400;
	line-height: 1rem;
}

.post-button.gray {
	color: #0915f8;
	font-weight: 500;
}

.newsletter-form {
	max-width: none;
	flex-direction: row;
	flex: 0 auto;
	justify-content: center;
	align-items: center;
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.div-block-50 {
	flex-direction: column;
	margin-bottom: 10px;
	display: flex;
}

.div-block-50.example {
	cursor: pointer;
	flex-direction: row;
	align-items: center;
	margin-top: -8px;
	margin-bottom: 0;
	padding-top: 0;
	line-height: 2rem;
	display: flex;
}

.div-block-73 {
	width: 25px;
	height: 25px;
	background-image: none;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 20px;
	display: block;
	position: relative;
	left: 0;
}

.div-block-73.gray {
	background-image: none;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
}

.text-field-2 {
	color: #9d2a2a;
	background-color: #fff;
	margin-bottom: 0;
	margin-right: 0;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	font-weight: 400;
}

.slider-two {
	background-position: 0 0, 0 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.section-inner-content {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.content-block {
	width: 30%;
	background-image: url('/assets/images/cover-image-2.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 2.5rem 2rem;
	text-decoration: none;
	transition: all 0.35s;
	display: block;
}

.content-block:hover {
	background-image: url('/assets/images/cover-image-2.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-block._2 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block._2:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.content-block._3 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block._3:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.form-block-wrapper {
	width: 100%;
}

.field-label {
	color: var(--background-color);
	font-family: Work Sans, sans-serif;
	font-weight: 400;
}

.content-block-inner-content {
	width: auto;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 0;
	padding: 0;
	display: flex;
	position: static;
	left: 5%;
}

.form-style {
	width: 500px;
	height: auto;
	border-radius: 5px;
	margin-bottom: 1rem;
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.9rem;
}

.form-style.message {
	height: 100px;
}

.form-style.email {
	margin-bottom: 2rem;
}

.text-field-4 {
	height: 75px;
	border-radius: 5px;
	padding-top: 0.75px;
	padding-bottom: 0.75px;
	font-family: Work Sans, sans-serif;
}

.image-2 {
	width: 200px;
}

.content-block-wrapper {
	width: 100%;
	height: auto;
	max-width: 1600px;
	background-color: rgba(62, 133, 237, 0);
	flex-direction: column;
	align-items: center;
	padding: 4rem;
	display: flex;
}

.div-block-76 {
	width: 40%;
	height: 50vh;
	background-image: url('/assets/images/cover-image.jpg');
	background-position: 0 0;
	background-size: cover;
}

.columns {
	width: auto;
	padding-top: 0;
	padding-bottom: 0;
}

.div-block-77 {
	margin-bottom: 0;
	display: flex;
}

.div-block-78 {
	width: 60%;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	padding: 4rem 2rem;
	display: flex;
}

.slider-2 {
	width: 100%;
	height: 50vh;
	background-color: rgba(0, 0, 0, 0);
	display: none;
}

.left-arrow-2 {
	color: var(--black);
	left: 0;
}

.right-arrow-2 {
	color: var(--black);
	right: 0;
}

.container-5 {
	height: 100%;
	max-width: none;
	justify-content: center;
	align-items: center;
	padding-left: 4rem;
	padding-right: 4rem;
	display: flex;
}

.testimonial-wrapper {
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2rem;
	display: flex;
}

.icon-4 {
	width: 50px;
	height: 50px;
	border: 1px none var(--black);
	border-radius: 0;
	padding-left: 0;
	padding-right: 0;
	font-size: 2rem;
}

.text-span {
	color: var(--dodger-blue);
}

.testimonials-name {
	color: var(--black);
	text-transform: capitalize;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	font-weight: 400;
}

.slide-3 {
	background-color: var(--background-color);
}

.icon-5 {
	font-size: 2rem;
}

.block-quote {
	border-left-style: none;
	border-left-color: var(--aquamarine);
	color: var(--midnight-blue);
	margin-bottom: 2rem;
	padding: 0;
	font-family: Work Sans, sans-serif;
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.email-link {
	color: var(--white);
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.email-link:hover {
	color: var(--tomato);
}

.div-block-80 {
	width: 50px;
	height: 5px;
	background-color: var(--aquamarine);
	margin-bottom: 2rem;
}

.text-block-5 {
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 400;
	line-height: 1.25em;
}

.text-block-5:hover {
	color: var(--aquamarine);
}

.search-icon {
	width: auto;
	height: auto;
	min-height: 3rem;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
	padding: 1rem 1.5rem;
}

.search-icon:hover {
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 17px;
}

.search-bar {
	z-index: 100;
	max-width: none;
	background-color: var(--white);
	justify-content: space-between;
	align-items: center;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	font-family: Work Sans, sans-serif;
	display: block;
	position: absolute;
	top: 0;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.search-bar-wrap {
	justify-content: space-around;
	align-items: center;
	margin-bottom: 0;
	display: flex;
}

.search-input {
	width: 95%;
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.5rem 0;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.search-input-exit {
	width: 5%;
	background-color: rgba(0, 0, 0, 0);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 12px 12px;
	display: block;
}

.search-input-exit.two {
	width: 20px;
	z-index: 5;
	width: 20px;
	z-index: 5;
	width: 20px;
	padding: 0 0;
	display: none;
	position: relative;
}

.div-block-81 {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0;
	cursor: pointer;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link.white {
	background-size: 150px 29px;
	margin-top: 0;
	margin-bottom: 0;
}

.grip-logo-text-box {
	color: #000;
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	font-size: 0.5rem;
	font-weight: 700;
	line-height: 0.5rem;
	text-decoration: none;
}

.grip-logo-text-box.webdevby-white {
	color: #fff;
	width: auto;
	color: #fff;
	letter-spacing: 0;
	font-weight: 400;
}

.testimonials-column-wrap {
	justify-content: space-around;
	padding-left: 0;
	padding-right: 0;
	display: none;
}

.testimonial-column {
	width: 100%;
	border-right: 3px solid var(--white);
	cursor: pointer;
	background-color: rgba(51, 51, 51, 0.03);
	flex: 0 auto;
	padding: 2.5rem 2rem;
}

.testimonial-column.no-border {
	border-right-style: none;
}

.header-contents-wrap {
	z-index: 1000;
	width: 100%;
	height: 50%;
	max-width: none;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 0 solid rgba(250, 251, 252, 0.15);
	justify-content: space-between;
	align-items: center;
	padding-left: 4.5rem;
	padding-right: 2.5rem;
	display: flex;
	position: relative;
}

.div-block-83 {
	padding-right: 2rem;
}

.div-block-84 {
	width: 30%;
}

.header-container {
	max-width: 1340px;
	flex-flow: row;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.brand-2 {
	width: auto;
	height: 46%;
	display: block;
	overflow: hidden;
}

.image-8 {
	width: 350px;
}

.nav-link-box-3 {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.mobile-brand.w--current {
	width: 70px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: none;
}

.nav-hover-1 {
	width: 0%;
	height: 3px;
	opacity: 0;
	background-color: #fff;
}

.nav-hover-1.div-block-10,
.nav-hover-1.div-10 {
	background-color: #414044;
}

.nav-link-box-1 {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.cart-link {
	width: 1.7rem;
	height: 1.7rem;
	margin-top: 0.25rem;
	margin-left: 1.5rem;
	margin-right: 1.5rem;
	overflow: hidden;
}

.dropdown-link-2 {
	width: auto;
	color: #dad8d8;
	margin-top: 0;
	margin-left: 0;
	margin-right: 0;
	padding-top: 5px;
	padding-bottom: 5px;
	font-weight: 400;
	transition: all 0.2s;
}

.dropdown-link-2:hover {
	color: #414044;
	background-color: #fff;
	font-size: 14px;
	font-weight: 400;
}

.dropdown-link-2.w--current {
	color: #414044;
	background-color: #fff;
}

.top-header-wrapper {
	width: auto;
	height: 100%;
	border-left: 1px rgba(255, 255, 255, 0.62);
	justify-content: flex-end;
	align-items: center;
	margin-left: auto;
	padding: 0;
	display: flex;
}

.icon-wrapper {
	height: 100%;
	border-left: 1px #dad8d8;
	align-items: center;
	padding-left: 0;
	display: flex;
}

.nav-link-box-2 {
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	display: flex;
}

.dropdown {
	display: block;
}

.account-icon {
	width: 100%;
	opacity: 1;
	margin-right: 0;
	transition: all 0.3s;
	transform: translate(0);
}

.account-icon:hover {
	opacity: 1;
}

.div-block-86 {
	width: 100%;
	height: 100%;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.dropdown-toggle-2 {
	height: 100%;
	padding: 22px 0;
}

.dropdown-toggle-2:hover {
	color: #fff;
}

.dropdown-toggle-2.w--open {
	padding-top: 22px;
	padding-bottom: 22px;
}

.nav-link-3 {
	color: #dad8d8;
	padding: 10px;
	font-size: 17px;
	font-weight: 600;
	transition: all 0.2s;
}

.nav-link-3:hover {
	color: #fff;
	border: 1px #000;
}

.nav-link-3.w--current {
	color: #005290;
}

.nav-link-box-5 {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.account-link {
	width: 1.7rem;
	height: 1.7rem;
	margin-top: 0.25rem;
	margin-right: 0;
	display: none;
	overflow: hidden;
}

.mobile-dropdown {
	flex-direction: column;
	align-items: center;
	display: none;
}

.dropdown-list-3 {
	color: #414044;
}

.dropdown-list-3.w--open {
	width: 300px;
	color: #fff;
	background-color: #414044;
	margin-top: 0;
	padding: 10px;
}

.div-block-87 {
	width: 60%;
	height: 4px;
	background-color: #005290;
	border-radius: 20px;
	display: block;
	position: static;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.nav-menu-2 {
	height: 100%;
	align-items: center;
	margin-right: 20px;
	display: flex;
}

.div-block-88 {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.icon-wrapper-2 {
	width: 75px;
	height: 60px;
	background-color: rgba(0, 0, 0, 0);
	border: 2px #ed762e;
	border-radius: 50px;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 0;
	display: flex;
}

.blue-icon {
	width: 75px;
	height: 55px;
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 50px;
}

.blue-icon._4 {
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 45px;
}

.blue-icon.white {
	background-position: 0 0, 0 0;
	background-repeat: no-repeat, no-repeat;
	background-size: 50px, 50px;
	display: none;
	position: absolute;
}

.blue-icon.white._4 {
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: 45px;
}

.left-nav-list-content-wrap {
	height: auto;
	border-right: 1px rgba(0, 0, 0, 0.05);
	margin-top: 0;
	margin-right: 1.5rem;
	padding-bottom: 1.5rem;
	display: block;
	overflow: hidden;
}

.image-block {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.image-block.inside-page {
	width: 100%;
	height: 200px;
	margin-bottom: 2rem;
}

.button-styling {
	align-items: center;
	padding-left: 0;
	font-family: Open Sans, sans-serif;
	font-weight: 800;
	display: flex;
}

.table-div {
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.inside-page-header-wrap {
	border-bottom: 0.5px solid rgba(51, 51, 51, 0.15);
	margin-bottom: 1rem;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.h6-header-link {
	color: var(--midnight-blue);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link:hover {
	color: var(--light-steel-blue);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.main-content-column {
	padding-left: 0;
	padding-right: 0;
}

.h2-header-link {
	color: var(--midnight-blue-2);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link:hover {
	color: var(--light-steel-blue);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.section-wrap {
	background-color: var(--background-color);
	margin-top: 100px;
	font-family: Poppins, sans-serif;
	overflow: hidden;
}

.h3-header-link {
	color: var(--midnight-blue);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link:hover {
	color: var(--light-steel-blue);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.left-nav-column {
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.heading-6 {
	width: 100%;
	color: var(--midnight-blue-2);
	background-color: rgba(0, 0, 0, 0);
	margin-top: 0;
	margin-bottom: 1.25rem;
	padding: 0;
	font-family: Libre Baskerville, sans-serif;
	font-size: 2.75rem;
	font-weight: 700;
}

.h4-header-link {
	color: var(--midnight-blue);
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link:hover {
	color: var(--light-steel-blue);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 0, 0, 0);
	font-family: Work Sans, sans-serif;
	font-weight: 400;
}

.unordered-list {
	margin-bottom: 1rem;
}

.breadcrumb-list {
	text-transform: uppercase;
	margin: 0 0.125rem;
	padding-left: 0;
	font-size: 0.6875rem;
	line-height: 1em;
}

.block-quote-2 {
	border-left-color: var(--tomato);
	color: var(--black);
	background-color: rgba(0, 0, 0, 0);
	margin-top: 10px;
	margin-bottom: 20px;
	font-family: Work Sans, sans-serif;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.75rem;
}

.main-content-section {
	max-width: none;
	margin-right: 1rem;
}

.left-nav-nested-list-link {
	color: #333;
	text-transform: capitalize;
	border: 2px rgba(0, 0, 0, 0);
	padding: 0.75em 1.5rem;
	font-family: Montserrat, sans-serif;
	font-size: 0.85em;
	font-weight: 500;
	line-height: 1.375em;
	text-decoration: none;
	display: block;
}

.left-nav-nested-list-link:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: none;
	border: 2px #48bdd7;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild {
	color: #333;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild:hover {
	color: var(--medium-aquamarine);
}

.left-nav-nested-list-link.great-grandchild {
	color: #333;
	padding-left: 2.5rem;
	font-size: 0.825em;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: var(--medium-aquamarine);
}

.left-nav-nested-list-link.grandchild {
	color: #333;
	background-image: none;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: auto;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild:hover {
	color: #0915f8;
	background-image: url('/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 12px;
	transform: translate(5px);
}

.left-nav-nested-list-link.great-grandchild {
	color: #333;
	padding-left: 2.5rem;
	font-size: 0.825em;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #0915f8;
}

.left-nav-nested-list-link.grandchild-nav-link {
	color: var(--midnight-blue-2);
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild-nav-link:hover {
	color: var(--orange-red);
}

.left-nav-nested-list-link.grandchild-nav-link.great {
	color: var(--midnight-blue-2);
	padding-left: 2.5rem;
}

.left-nav-nested-list-link.grandchild-copy {
	color: #333;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.left-nav-nested-list-link.grandchild-copy:hover {
	color: var(--medium-aquamarine);
}

.breadcrumb-link-1 {
	color: #0915f8;
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	text-decoration: none;
}

.breadcrumb-link-1:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.breadcrumb-link-1.w--current {
	color: #28313b;
	font-weight: 700;
}

.breadcrumb-link-1.breadcrumb-link {
	color: var(--black);
	text-transform: capitalize;
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-family: Work Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
}

.breadcrumb-link-1.breadcrumb-link:hover {
	color: var(--tomato);
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
}

.breadcrumb-link-1.breadcrumb-link {
	color: #333;
	text-transform: capitalize;
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	text-decoration: none;
}

.breadcrumb-link-1.breadcrumb-link:hover {
	color: #0915f8;
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
}

.breadcrumb-link-1.breadcrumb-link-2 {
	color: var(--black);
	text-transform: capitalize;
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-family: Work Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1rem;
	text-decoration: none;
}

.breadcrumb-link-1.breadcrumb-link-2:hover {
	color: var(--tomato);
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
}

.inside-page-background-image {
	height: 200px;
	background-color: rgba(0, 0, 0, 0);
	background-image: linear-gradient(to bottom, var(--black-3), var(--black-3)), url('/assets/images/austin-distel-wawEfYdpkag-unsplash.jpg');
	background-position: 0 0, 50% 0;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	margin-left: -15px;
	margin-right: -15px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.left-nav-list-link {
	color: var(--midnight-blue-2);
	text-transform: capitalize;
	border-bottom: 1px solid rgba(51, 51, 51, 0.05);
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-family: Poppins, sans-serif;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
}

.left-nav-list-link:hover {
	background-color: var(--background-color);
	color: var(--tomato);
	text-decoration: none;
}

.left-nav-list-link.w--current {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 12px;
}

.content-container {
	width: 50%;
	max-width: none;
	background-color: #fff;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	margin-left: 0;
	margin-right: 0;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	display: block;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	padding-left: 0;
	padding-right: 0;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: Open Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.second-button {
	color: #0915f8;
	letter-spacing: 0.5px;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/blue-arrow-icon-01.svg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: 17px;
	margin-left: 1rem;
	padding-left: 0;
	padding-right: 40px;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.second-button:hover {
	background-position: 100%;
}

.intro-paragraph-2 {
	color: #333;
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.inside-pages-paragraph {
	color: #333;
	margin-bottom: 1rem;
	padding-bottom: 0;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
}

.inside-pages-paragraph.list-item {
	margin-bottom: 0;
}

.inside-pages-paragraph.underline {
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

.heading-18 {
	color: var(--midnight-blue-2);
	margin-bottom: 5px;
	font-family: Work Sans, sans-serif;
	font-weight: 700;
}

.heading-18.button-style {
	margin-bottom: 1rem;
}

.heading-10 {
	color: var(--midnight-blue-2);
	text-transform: capitalize;
	margin-bottom: 5px;
	font-family: Work Sans, sans-serif;
	font-weight: 700;
}

.left-nav-nested-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.inner-image-content {
	height: 100%;
	background-image: url('/assets/images/GettyImages-1180123651.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
}

.inner-image-content.recent-blog-post {
	width: 100%;
	height: 100%;
	background-image: linear-gradient(to bottom, var(--black-3), var(--black-3)), url('/assets/images/cover-image-2.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, repeat;
	background-size: auto, cover;
	align-items: flex-end;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.paragraph-12 {
	color: #333;
	font-family: Work Sans, sans-serif;
	font-size: 1.2rem;
	font-weight: 400;
	line-height: 1.5rem;
}

.inside-content-beckground {
	max-width: 1300px;
	border-top: 5px solid var(--light-steel-blue);
	background-color: #fff;
	margin-top: 60px;
	margin-left: auto;
	margin-right: auto;
	padding: 0 0 4rem;
	display: block;
	position: relative;
}

.main-button {
	color: #333;
	letter-spacing: 1px;
	background-color: #fff;
	border-radius: 5px;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
	font-weight: 500;
	transition: background-color 0.35s;
}

.main-button:hover {
	color: #fff;
	background-color: #0915f8;
}

.main-button.inside-page {
	border: 0.15px solid rgba(51, 51, 51, 0.25);
	margin-right: 20px;
}

.breadcrumb-list-item {
	float: left;
	margin-top: 0.25rem;
	margin-bottom: 0.25rem;
	margin-right: 0.5rem;
}

.left-nav-nested-list {
	background-color: var(--background-color);
	border-top: 2px #498fa3;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.left-nav-list {
	box-shadow: none;
	margin-top: 0;
	margin-bottom: 1.5rem;
	padding-left: 0;
}

.breadcrumb-divider {
	float: right;
	margin-left: 0.5rem;
}

.main-content-wrapper {
	width: 95%;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.text-block-21 {
	color: var(--midnight-blue-2);
	text-transform: capitalize;
	font-family: Work Sans, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1rem;
}

.horiz-divider-2 {
	height: 1px;
	color: #003c43;
	background-color: rgba(91, 91, 91, 0.15);
	margin-top: 1rem;
	margin-bottom: 1rem;
}

.h5-header-link {
	color: var(--midnight-blue);
	text-transform: capitalize;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Work Sans, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link:hover {
	color: var(--light-steel-blue);
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.left-nav-list-item {
	box-shadow: none;
	margin-bottom: 0;
}

.left-nav-list-content-wrap-2 {
	height: auto;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	margin-top: -1.5rem;
	margin-right: 1.5rem;
	padding-bottom: 1.5rem;
	display: block;
	overflow: hidden;
}

.div-block-140 {
	width: 75%;
	height: 375px;
	position: relative;
	overflow: hidden;
}

.div-block-140.inside-page {
	width: 100%;
	height: 250px;
}

.inside-content-beckground-2 {
	max-width: 1300px;
	background-color: #fff;
	border-top: 1px solid #000;
	margin-top: -90px;
	margin-left: auto;
	margin-right: auto;
	padding: 3rem 15px 4rem;
	display: block;
	position: relative;
}

.inside-page-header-content-wrap-3 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	padding-bottom: 0.5rem;
}

.h6-header-link-2 {
	color: #0915f8;
	text-transform: uppercase;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 12px;
	font-weight: 400;
	line-height: 18px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h6-header-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.h2-header-link-2 {
	color: #0915f8;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 2rem;
	font-weight: 400;
	line-height: 2.25rem;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h2-header-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.paragraph-16 {
	font-family: Montserrat, sans-serif;
}

.section-wrap-2 {
	overflow: hidden;
}

.h3-header-link-2 {
	color: #0915f8;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 24px;
	font-weight: 400;
	line-height: 30px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h3-header-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.heading-19 {
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.h4-header-link-2 {
	color: #0915f8;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h4-header-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-width: 2px;
	border-bottom-color: rgba(0, 0, 0, 0);
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.breadcrumb-list-2 {
	text-transform: uppercase;
	margin: 0 0.125rem 1rem;
	padding-left: 0;
	font-size: 0.6875rem;
	line-height: 1em;
}

.block-quote-3 {
	color: #5b5b5b;
	background-color: rgba(0, 0, 0, 0);
	border-left-color: #0915f8;
	margin-top: 10px;
	margin-bottom: 20px;
	font-family: Montserrat, sans-serif;
	font-size: 1.3rem;
	font-weight: 500;
	line-height: 1.75rem;
}

.right-sidebar-column.right-sidebar {
	padding-left: 10px;
	padding-right: 10px;
}

.sidebar-section-title-2 {
	color: var(--midnight-blue-2);
	margin-top: 0;
	margin-bottom: 0.25rem;
	font-family: Work Sans, sans-serif;
	font-weight: 700;
	line-height: 20px;
}

.inside-page-background-image-2 {
	height: 250px;
	background-color: rgba(0, 0, 0, 0);
	background-image: linear-gradient(rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url('/assets/images/dan-gold-opIZa6gWsFs-unsplash.jpg');
	background-position: 0 0, 50% 60%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	margin-left: -15px;
	margin-right: -15px;
	padding-top: 0;
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.left-nav-list-link-2 {
	color: #333;
	text-transform: capitalize;
	background-image: none;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: auto;
	border-bottom-style: none;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	text-decoration: none;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
	display: block;
}

.left-nav-list-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 12px;
	text-decoration: none;
	transform: translate(5px);
}

.left-nav-list-link-2.w--current {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0%;
	background-repeat: no-repeat;
	background-size: 12px;
}

.sidebar-section-wrap {
	border-top: 1px rgba(0, 0, 0, 0.15);
	margin-bottom: 2.25rem;
	margin-left: 0.5rem;
	font-size: 0.875rem;
	line-height: 1.35rem;
	position: relative;
}

.second-button-2 {
	color: #0915f8;
	letter-spacing: 0.5px;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/blue-arrow-icon-01.svg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: 17px;
	padding-left: 0;
	padding-right: 40px;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
	transition: all 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.second-button-2:hover {
	background-position: 100%;
}

.intro-paragraph-3 {
	color: #333;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.5rem;
}

.sidebar-list-item {
	color: #0915f8;
	margin-bottom: 0.5rem;
	padding-left: 0;
	font-family: Montserrat, sans-serif;
	text-decoration: none;
}

.sidebar-list {
	margin-top: 0;
	margin-bottom: 0.75rem;
	padding-left: 0;
}

.paragraph-14 {
	color: #333;
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-14.list-item {
	margin-bottom: 0;
}

.heading-20 {
	color: #333;
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.heading-21 {
	color: #333;
	text-transform: uppercase;
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.main-content-column-nested-2 {
	padding-left: 0;
	padding-right: 0;
}

.div-block-138 {
	height: 100%;
	background-image: url('/assets/images/GettyImages-1180123651.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
}

.div-block-138.recent-blog-post {
	width: 100%;
	height: 100%;
	background-image: url('/assets/images/tim-van-der-kuip-CPs2X8JYmS8-unsplash.jpg');
	background-position: 50%;
	background-repeat: repeat;
	background-size: cover;
}

.left-nav-nested-list-2 {
	border-top: 2px #498fa3;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 0;
}

.text-block-22 {
	color: #0915f8;
	text-transform: capitalize;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 500;
}

.h5-header-link-2 {
	color: #0915f8;
	text-transform: uppercase;
	border-bottom: 1.5px solid rgba(91, 91, 91, 0.15);
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	text-decoration: none;
	transition: all 0.2s;
	display: inline-block;
}

.h5-header-link-2:hover {
	color: #0915f8;
	background-color: rgba(0, 0, 0, 0);
	border-bottom-color: rgba(0, 0, 0, 0);
}

.link-7 {
	color: var(--black);
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Work Sans, sans-serif;
	font-weight: 400;
	text-decoration: none;
}

.link-7:hover {
	color: var(--light-steel-blue);
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.slider-three {
	background-position: 0 0, 0 0;
	background-size: auto, cover;
}

.div-block-141 {
	width: 100%;
}

.content-block-2 {
	width: 30%;
	background-image: url('/assets/images/cover-image-4.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 2.5rem 2rem;
	text-decoration: none;
	transition: all 0.35s;
	display: block;
}

.content-block-2:hover {
	background-image: url('/assets/images/cover-image-4.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-block-2._2 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block-2._2:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.content-block-2._3 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block-2._3:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.content-block-3 {
	width: 30%;
	background-image: url('/assets/images/cover-image_1.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 2.5rem 2rem;
	text-decoration: none;
	transition: all 0.35s;
	display: block;
}

.content-block-3:hover {
	background-image: url('/assets/images/cover-image_1.jpg');
	background-position: 90%;
	background-repeat: no-repeat;
	background-size: cover;
}

.content-block-3._2 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block-3._2:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image-4.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.content-block-3._3 {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 62%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-size: auto, auto 100%;
}

.content-block-3._3:hover {
	background-image: linear-gradient(rgba(0, 0, 0, 0.62) 20%, rgba(255, 255, 255, 0)), url('/assets/images/cover-image_1.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, auto 104%;
}

.icon-6,
.icon-7 {
	margin-top: auto;
	margin-bottom: auto;
}

.project-block-one {
	width: 30%;
	border: 15px solid var(--beige-dark);
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.77) 22%, rgba(255, 255, 255, 0)), url('/assets/images/content-box-2.jpg');
	background-position: 0 0, 100%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	align-items: flex-end;
	margin-right: 10px;
	padding: 2rem;
	transition: all 0.35s;
	display: flex;
}

.project-block-two {
	width: 30%;
	border: 15px solid var(--beige-dark);
	cursor: pointer;
	background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 22%, rgba(0, 0, 0, 0)), url('/assets/images/content-box-1.jpg');
	background-position: 0 0, 50%;
	background-size: auto, cover;
	align-items: flex-end;
	margin-right: 10px;
	padding: 2rem;
	display: flex;
}

.project-block-three {
	width: 30%;
	border: 15px solid var(--beige-dark);
	cursor: pointer;
	background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 22%, rgba(255, 255, 255, 0)), url('/assets/images/content-box-2.jpg');
	background-position: 0 0, 100%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	align-items: flex-end;
	margin-right: 10px;
	padding: 2rem;
	display: flex;
}

.inner-content {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
}

.inner-content.projects {
	align-items: flex-start;
}

.nav-bar-invisible-scroll-block {
	z-index: auto;
	width: 100%;
	height: 110px;
	background-color: var(--white);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.dropdown-toggle-3 {
	padding: 8px 0;
	transition: all 0.4s;
}

.dropdown-toggle-3:hover {
	transform: translate(0, -8px);
}

.form-4 {
	color: #fff;
}

.text-block-20 {
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 300;
}

.main-nav-link {
	color: #000;
	cursor: pointer;
	padding: 0.5rem 1.25rem;
	font-size: 0.9rem;
	font-weight: 500;
	line-height: 1.2rem;
	text-decoration: none;
	transition: all 0.15s;
	display: block;
	position: relative;
}

.main-nav-link.w--current {
	color: #f9b604;
	background-color: rgba(3, 52, 102, 0.6);
}

.main-nav-link.mobile-duplicate {
	display: none;
}

.form-block-3 {
	width: 90%;
	color: #fff;
	border: 0.1px solid rgba(51, 51, 51, 0.25);
	border-radius: 5px;
	margin-bottom: 0;
	margin-left: 20px;
	padding-left: 0;
}

.search-icon-2 {
	margin-top: 5px;
	margin-left: 40px;
	margin-right: 30px;
	display: inline-block;
}

.search-icon-2.mobile {
	margin-top: 5px;
	margin-left: 0;
	margin-right: 0;
	display: block;
}

.nav-menu-3 {
	align-items: center;
	margin-bottom: -6px;
	margin-left: auto;
	margin-right: 0;
	display: flex;
}

.dropdown-list-4 {
	background-color: #fff;
}

.dropdown-list-4.w--open {
	background-color: #fff;
	border-radius: 5px;
	padding-right: 0;
	box-shadow: 2px 2px 3px rgba(51, 51, 51, 0.15);
}

.div-block-131 {
	width: 30px;
	height: 30px;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.nav-link-5 {
	float: right;
	color: #002f55;
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	padding-top: 10px;
	padding-bottom: 5px;
	font-size: 16px;
}

.nav-link-5:hover {
	border-bottom: 5px #000;
}

.nav-link-5.mobileonly {
	display: none;
}

.nav-link-4 {
	color: #4d4c4c;
	text-transform: none;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0;
	margin-left: 60px;
	margin-right: 0;
	padding: 8px 0;
	font-family: Montserrat, sans-serif;
	font-size: 0.9rem;
	font-weight: 600;
	transition: all 0.4s;
}

.nav-link-4:hover {
	color: #4d4c4c;
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 3px #233e99;
	border-radius: 0;
}

.nav-link-4.about {
	color: #fff;
	margin-left: 0;
	padding-right: 0;
	font-weight: 300;
}

.nav-link-4.about:hover {
	transform: translate(0, -8px);
}

.logo-3 {
	float: none;
	border-bottom-style: none;
	flex: none;
	order: -1;
	align-self: center;
	align-items: center;
	margin: 0 0;
	font-size: 1.625rem;
	font-weight: 600;
	line-height: 1.25rem;
	display: block;
}

.logo-3:hover {
	color: #fff;
}

.text-field-3 {
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	border-bottom-color: rgba(255, 255, 255, 0.25);
	margin-bottom: 0;
	padding-left: 20px;
	font-family: Montserrat, sans-serif;
	font-size: 0.75rem;
}

.icon-9 {
	display: none;
}

.search-bar-exit {
	width: 30px;
	height: 30px;
	cursor: pointer;
	margin-left: 25px;
	margin-right: 10px;
	padding-right: 0;
}

.two-part-mobile-nav-wrapper {
	display: none;
}

.search-content {
	z-index: 5;
	width: 30vw;
	height: 80px;
	background-color: #fff;
	border-top: 1px solid rgba(91, 91, 91, 0.15);
	border-radius: 5px;
	justify-content: space-around;
	align-items: center;
	margin: 100px 35px 0 0;
	padding: 0 0%;
	display: flex;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
	box-shadow: 4px 4px 15px -2px rgba(0, 0, 0, 0.1);
}

.search-button-icon {
	width: 10px;
	height: 30px;
	background-color: rgba(0, 0, 0, 0);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
	border: 1px #233e99;
	border-radius: 20px;
	margin-top: 0;
	position: static;
}

.search-button-icon:hover {
	background-color: rgba(255, 255, 255, 0.1);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.search-button-icon:active {
	background-color: rgba(0, 0, 0, 0);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 25px;
}

.navigation {
	z-index: 100;
	width: 100%;
	height: 125px;
	max-width: none;
	background-color: rgba(0, 0, 0, 0);
	justify-content: flex-start;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	padding: 0.5rem 3%;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.menu-icon {
	z-index: 1000;
	width: 50px;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(255, 255, 255, 0.45);
	border-radius: 100px;
	justify-content: flex-end;
	margin: 20px 20px 10px 10px;
	padding: 5px;
	display: none;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.menu-icon:hover {
	background-color: #d8a72b;
	border-style: solid;
	border-color: rgba(0, 0, 0, 0);
}

.cart {
	width: auto;
	height: auto;
	margin-top: 5px;
	margin-left: 0;
	margin-right: 15px;
	padding-top: 0;
	padding-bottom: 0;
}

.dropdown-2 {
	z-index: 100;
}

.icon-wrapper-3 {
	display: flex;
}

.account-button-icon {
	width: 10px;
	height: 30px;
	background-color: rgba(0, 0, 0, 0);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 19px;
	margin-top: 5px;
}

.account-button-icon:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
}

.nav-dropdown-link {
	padding: 8px 20px;
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 500;
}

.nav-dropdown-link:hover {
	color: #0915f8;
}

.cart-button-icon {
	width: 10px;
	height: 30px;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/cart-icon-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 22px;
	padding-top: 9px;
	padding-bottom: 9px;
}

.cart-button-icon:hover {
	background-color: rgba(255, 255, 255, 0.1);
	border-radius: 20px;
}

.nav-holder {
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-right: 40px;
	display: flex;
}

.account {
	width: auto;
	height: auto;
	opacity: 1;
	cursor: pointer;
	margin-left: 0;
	margin-right: 15px;
	transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	display: inline-block;
}

.account:hover {
	opacity: 1;
}

.slider-inner-content {
	width: 80%;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 100px;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 12%;
	display: flex;
}

.utility-page-wrap {
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	justify-content: center;
	align-items: center;
	display: flex;
}

.utility-page-content {
	width: 260px;
	text-align: center;
	flex-direction: column;
	display: flex;
}

.utility-page-form {
	flex-direction: column;
	align-items: stretch;
	display: flex;
}

.right-block {
	z-index: 2;
	width: 70%;
	height: 90%;
	background-color: rgba(118, 56, 17, 0.8);
	background-image: none;
	background-size: auto;
	border-radius: 0 50px 50px 0;
	margin: auto 35px auto auto;
	padding-left: 0;
	display: flex;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.div-block-149 {
	width: 50%;
	border-right: 0 solid #fff;
	flex-direction: column;
	flex: 0 auto;
	justify-content: center;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 0;
	display: flex;
}

.left-block {
	width: 50%;
	height: 100%;
	background-color: rgba(103, 35, 0, 0.8);
	border-radius: 50px 0 0 50px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-left: 35px;
	padding-left: 0;
	padding-right: 0;
	display: flex;
	position: absolute;
}

.text-block-23 {
	opacity: 0.54;
	color: #fff;
	text-transform: uppercase;
	margin-top: 20px;
	font-size: 17px;
}

.text-block-24 {
	border-top: 1px solid var(--beige-2);
	border-bottom: 1px solid var(--beige-2);
	color: #fff;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 2vh;
	font-weight: 700;
}

.background-container {
	z-index: 1;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.slide-nav-2 {
	display: none;
}

.slider-3 {
	z-index: 2;
	width: 100%;
	height: 100%;
	max-width: 992px;
	background-color: rgba(83, 83, 83, 0);
	border: 1px #000;
}

.section-container {
	width: 100%;
	height: 80vh;
	background-color: var(--white);
	background-image: none;
	background-size: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	display: flex;
	overflow: hidden;
}

.mask {
	width: 100%;
	overflow: visible;
}

.right-arrow-3 {
	width: 70px;
	height: 70px;
	background-color: #fff;
	border: 1px #000;
	border-radius: 100%;
}

.div-block-150 {
	align-items: center;
	margin-top: 20px;
	display: flex;
}

.left-arrow-3 {
	width: 70px;
	height: 70px;
	background-color: #fff;
	border-radius: 100%;
}

.image-4 {
	width: auto;
	height: 523px;
	margin-left: 100px;
	position: absolute;
	top: 11%;
	bottom: 0%;
	left: -33%;
	right: auto;
}

.button-4 {
	text-align: center;
	background-color: #301919;
	border-radius: 0;
	margin-right: 40px;
	padding: 19px 39px;
	font-size: 16px;
	font-weight: 700;
	box-shadow: 0 0 20px 6px rgba(70, 44, 44, 0.57);
}

.div-block-151 {
	align-items: center;
	margin-top: 20px;
	display: flex;
}

.image-container {
	width: 46%;
	opacity: 0.3;
	position: absolute;
	left: 7px;
}

.item-right {
	z-index: 2;
	width: 70%;
	height: 90%;
	background-color: rgba(255, 92, 92, 0);
	border-radius: 50px;
	flex-direction: column;
	justify-content: center;
	margin: auto 35px auto auto;
	padding-top: 10px;
	padding-left: 140px;
	padding-right: 40px;
	display: flex;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.slide {
	border: 0 #000;
	font-size: 1vh;
}

.wrapper {
	width: 100%;
	height: 80%;
	max-height: 750px;
	max-width: 992px;
}

.div-block-152 {
	display: flex;
}

.heading {
	border-bottom: 1px solid var(--beige-2);
	color: #fff;
	text-transform: capitalize;
	padding-bottom: 20px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 3vh;
}

.icon {
	color: #000;
	font-size: 34px;
}

.content {
	width: 100%;
	height: 100%;
	position: relative;
}

.text-block-6 {
	color: #fff;
	font-family: Libre Baskerville, sans-serif;
	font-size: 5vh;
	font-weight: 400;
}

.image-3 {
	width: 2vh;
	height: auto;
	filter: invert();
	margin-right: 10px;
	font-family: Libre Baskerville, sans-serif;
}

.client-logo {
	width: 170px;
	height: 90px;
	position: relative;
	top: -15px;
}

.nav-bar-dropdown-copy {
	color: var(--dark-slate-grey);
	letter-spacing: 1px;
	text-transform: uppercase;
	align-items: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding: 0;
	font-family: Montserrat, sans-serif;
	font-size: 11px;
	font-weight: 700;
	transition: background-color 0.3s;
	display: none;
}

.nav-bar-dropdown-copy:hover {
	border-bottom: 5px none var(--dodger-blue);
}

.top-header-links {
	width: auto;
	color: var(--white);
	letter-spacing: 0;
	text-transform: capitalize;
	justify-content: center;
	align-items: flex-end;
	margin-left: 0;
	margin-right: 0;
	padding: 1rem;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 300;
	text-decoration: none;
	transition: background-color 0.3s;
	display: flex;
	position: static;
}

.top-header-links:hover {
	width: auto;
	border-bottom: 3px none var(--dodger-blue);
	color: var(--white);
	background-color: rgba(0, 0, 0, 0);
	text-decoration: underline;
}

.top-header-links.last {
	padding-right: 2rem;
}

.top-header-links.first {
	padding-top: 1.3rem;
	padding-bottom: 1.3rem;
	transition-property: background-color;
	transition-duration: 0.3s;
}

.top-header-links.first:hover {
	background-color: var(--light-steel-blue);
}

.careers-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	border-radius: 0;
	align-self: auto;
	align-items: center;
	margin-left: 0;
	margin-right: 0.5rem;
	padding-right: 1rem;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.careers-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.main-button-2 {
	justify-content: flex-end;
	align-items: center;
	margin-top: 6px;
	margin-bottom: 6px;
	display: flex;
}

.location-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 0;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.location-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.wrapper-2 {
	width: 100%;
	justify-content: flex-end;
	align-items: center;
	margin-top: 15px;
	display: flex;
}

.contact-us {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 0;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.contact-us:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.login-button-main {
	height: auto;
	outline-offset: -2px;
	color: #fff;
	background-color: #007050;
	background-image: url('/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 100px;
	outline: 2px solid #007050;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main:hover {
	color: #fff;
	background-color: #0f5d4b;
	outline: 3px solid rgba(0, 0, 0, 0);
}

.search-input-2 {
	height: 38px;
	border-radius: 20px;
	margin-bottom: 0;
	margin-right: -38px;
}

.link-text-2 {
	opacity: 0.75;
	color: #fff;
	letter-spacing: 0;
	text-transform: uppercase;
	border-bottom: 1px rgba(255, 255, 255, 0.35);
	padding-bottom: 5px;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 0.95rem;
	text-decoration: none;
	transition: opacity 0.3s;
}

.link-text-2:hover {
	opacity: 1;
	color: #fff;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.top-nav {
	height: auto;
	justify-content: space-between;
	align-items: center;
	padding: 20px 100px;
	display: flex;
}

.search-button {
	width: 38px;
	height: 38px;
	background-color: #4f95d0;
	background-image: url('/assets/images/search_wht.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-radius: 20px;
	margin-left: 0;
	margin-right: 0;
	transition: all 0.45s;
}

.search-button:hover {
	background-color: #2f6ca0;
}

.div-block-21 {
	z-index: 100;
	width: 100%;
	height: auto;
	background-color: #1a1a1a;
	justify-content: flex-end;
	padding: 0 5%;
	display: block;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.search-2 {
	width: 38px;
	height: 38px;
	opacity: 1;
	align-items: center;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.search-2.hidden {
	display: none;
}

.div-block-153 {
	z-index: 100;
	width: 100%;
	height: 50px;
	background-color: #420002;
	justify-content: flex-end;
	padding: 0 1% 0 5%;
	display: block;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.uui-navbar02_dropdown-content-right-2 {
	max-width: 22rem;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	flex: 1;
	grid-template-rows: max-content auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 1.25rem;
	display: none;
	position: relative;
}

.uui-navbar02_container-2 {
	width: 100%;
	height: 100%;
	max-width: none;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.search-bar-2 {
	z-index: 15;
	width: 60%;
	height: 69%;
	background-color: #fff;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	justify-content: space-between;
	align-items: center;
	padding-left: 1rem;
	padding-right: 1rem;
	display: none;
	position: absolute;
	top: 95px;
	bottom: auto;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-navbar02_item-right {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	font-weight: 400;
	display: flex;
}

.dropdown-link-3 {
	grid-column-gap: 0rem;
	grid-row-gap: 0px;
	color: #1a1a1a;
	border-radius: 0.5rem;
	grid-template-rows: auto;
	grid-template-columns: max-content 1fr;
	grid-auto-columns: 1fr;
	justify-content: flex-start;
	align-items: center;
	padding-top: 0.35rem;
	padding-bottom: 0.35rem;
	padding-left: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.dropdown-link-3:hover {
	background-color: rgba(0, 0, 0, 0);
}

.uui-button-link {
	grid-column-gap: 0.5rem;
	color: #6941c6;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link:hover {
	color: #53389e;
}

.uui-button-link.is-button-xsmall {
	font-size: 0.875rem;
}

.search-bar-wrap-2 {
	height: 100%;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 0;
	padding-right: 15px;
	display: flex;
}

.uui-navbar02_dropdown-content-left-2 {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	flex: 1;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	padding: 0.5rem 2rem 0 0;
	display: grid;
}

.uui-navbar02_dropdown-content-wrapper {
	z-index: 1;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
	position: relative;
}

.uui-navbar02_dropdown-content-2 {
	width: 100%;
	display: flex;
}

.uui-navbar02_item-heading-2 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: uppercase;
	margin-bottom: 0.25rem;
	font-weight: 400;
}

.uui-navbar02_item-heading-2:hover {
	color: #998868;
}

.uui-navbar02_menu-left-2 {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: space-around;
	margin-top: 10px;
	margin-right: 16px;
	display: flex;
}

.uui-navbar02_dropdown-content-button-wrapper {
	letter-spacing: normal;
	margin-top: 0.75rem;
	font-size: 0.875rem;
}

.login-button-main-mobile {
	height: auto;
	color: #fff;
	background-color: #007050;
	background-image: url('/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main-mobile:hover {
	background-color: #1a1a1a;
}

.uui-navbar02_dropdown-right-overlay-absolute {
	min-width: 100vw;
	background-color: #f9fafb;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: auto;
}

.uui-logo_component-5 {
	width: 100%;
	height: 60px;
	flex-wrap: nowrap;
	display: flex;
}

.search {
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.uui-navbar02_menu-dropdown {
	margin-bottom: 8px;
	position: static;
}

.uui-navbar02_dropdown-link-list {
	grid-column-gap: 0px;
	grid-row-gap: 0rem;
	flex-direction: column;
	grid-template-rows: max-content;
	grid-template-columns: 1fr;
	grid-auto-rows: max-content;
	grid-auto-columns: 1fr;
	display: flex;
}

.brand-3 {
	padding-left: 0;
}

.uui-navbar02_dropdown-list-2 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-2.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: #2d4578;
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: 5.6rem;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.uui-navbar02_blog-item {
	border-radius: 0.5rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: max-content;
	grid-auto-columns: 1fr;
	padding: 0.75rem;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_blog-item:hover {
	background-color: #fff;
}

.uui-navbar02_blog-image-wrapper {
	width: 100%;
	border-radius: 0.375rem;
	margin-bottom: 1.5rem;
	padding-top: 55%;
	position: relative;
	overflow: hidden;
}

.text-block-12 {
	color: #fff;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.text-block-12:hover {
	color: #998868;
}

.brand-logo {
	width: auto;
	height: auto;
	flex: none;
}

.uui-navbar02_blog-content {
	flex-direction: column;
	justify-content: flex-start;
	font-weight: 400;
	display: flex;
}

.nav-link-6 {
	color: #fff;
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
}

.nav-link-6:hover {
	color: #998868;
}

.nav-link-6.w--current {
	color: #000;
}

.uui-dropdown-icon {
	width: 1.25rem;
	height: 1.25rem;
	color: #fff;
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.search-icon-3 {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon-3:hover {
	background-color: #998868;
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
}

.uui-button-link-gray-2 {
	grid-column-gap: 0.5rem;
	color: #475467;
	white-space: nowrap;
	background-color: rgba(0, 0, 0, 0);
	justify-content: flex-start;
	align-items: center;
	padding: 0;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	line-height: 1.5;
	text-decoration: none;
	transition: all 0.3s;
	display: flex;
}

.uui-button-link-gray-2:hover {
	color: #344054;
}

.uui-button-link-gray-2.is-button-xsmall {
	font-size: 0.875rem;
}

.uui-navbar02_heading-2 {
	color: #1a1a1a;
	letter-spacing: normal;
	text-transform: capitalize;
	cursor: pointer;
	margin-top: 0;
	margin-bottom: 0.25rem;
	padding-left: 0.75rem;
	display: none;
}

.uui-navbar02_blog-image {
	width: 100%;
	height: 100%;
	object-fit: cover;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.uui-navbar02_dropdown-toggle-2 {
	color: #475467;
	align-items: center;
	padding: 1.75rem 2.5rem 1.75rem 1rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-2:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-2.w--open {
	height: 100%;
	padding-bottom: 1.75rem;
}

.mega-menu {
	z-index: 100;
	width: 100%;
	min-height: 5rem;
	background-color: #000;
	justify-content: center;
	align-items: center;
	padding-left: 5%;
	padding-right: 5%;
	display: none;
	position: fixed;
	top: 49px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-navbar02_menu-2 {
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	position: static;
}

.uui-text-size-small {
	color: #475467;
	letter-spacing: normal;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 0.875rem;
	line-height: 1.5;
}

.uui-navbar02_dropdown-blog-item-wrapper {
	flex-direction: column;
	grid-template-rows: auto auto auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-items: flex-start;
}

.div-block-119 {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.uui-button-row {
	grid-column-gap: 0.75rem;
	grid-row-gap: 0.75rem;
	flex-wrap: wrap;
	align-items: center;
	display: flex;
}

.nav-link-enquire {
	color: #fff;
	text-transform: uppercase;
	background-color: #998868;
	border: 1px solid #998868;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1rem;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link-enquire:hover {
	color: #998868;
	background-color: #fff;
}

.nav-link-enquire.w--current {
	color: #000;
}

.search-input-3 {
	width: 95%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.uui-navbar02_menu-button-3 {
	padding: 0;
}

.line-gorizon {
	width: 100%;
	height: 0.0625rem;
	background-color: rgba(36, 42, 46, 0.2);
}

.line-gorizon.mod--categories {
	display: none;
}

.lottie.mod--categories {
	width: 1rem;
	height: 1rem;
	flex: none;
	margin-top: -0.625rem;
	position: relative;
}

.overflow-hidden {
	z-index: 2;
	position: relative;
	overflow: hidden;
	transform: translate(0);
}

.overflow-hidden.mod--categories {
	width: 100%;
}

.link {
	padding-right: 20px;
}

.link.mod--categories {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	justify-content: center;
	align-items: center;
	font-size: 1.375rem;
	font-weight: 500;
	display: flex;
}

.btn__text {
	padding-top: 0.2em;
}

.btn__text.mod--absolute {
	position: absolute;
	top: -2em;
	left: 0;
	right: 0;
}

.categories__links {
	width: 100%;
	justify-content: space-between;
	align-items: center;
	display: flex;
	position: relative;
	overflow: hidden;
}

.overflow-anim {
	position: relative;
	top: 0;
	left: 0;
	transform: translate(0);
}

.overflow-anim.mod--btn-text {
	transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), top 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.content-2 {
	z-index: 2;
	width: 100%;
	max-width: 87rem;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.content-2.mod--categories {
	height: 5.3125rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5rem;
	display: flex;
}

.img.mod--service {
	z-index: -1;
	width: 100%;
	height: 100%;
	display: none;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: auto;
	right: 0%;
}

.img.mod--arrow-service {
	z-index: 2;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.img.mod--founders {
	z-index: 2;
	width: 4rem;
	transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
	display: block;
	position: relative;
	transform: translate(0, 0.2em) scale(1.1);
}

.img.mod--about-02 {
	z-index: 2;
	width: 17.25rem;
	display: block;
	position: absolute;
	bottom: 0;
	left: -16.625rem;
}

.img.mod--jar {
	z-index: 2;
	width: 100%;
	display: block;
	position: relative;
}

.img.mod--jar.mod--jar-cap {
	z-index: 2;
	transition: all 0.3s;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.img.mod--about-01 {
	width: 100%;
	height: 120%;
	object-fit: cover;
	display: block;
	position: absolute;
	top: -20%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.block.mod--service {
	width: 100%;
	height: 100%;
	padding: 2.5rem;
	transition: none;
	display: block;
	position: relative;
	overflow: hidden;
	transform: translate(0);
}

.block.mod--service.mod--1 {
	background-color: rgba(0, 0, 0, 0);
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/content-box-2.jpg');
	background-position: 0 0, 0 0;
	background-size: auto, cover;
}

.block.mod--service.mod--2 {
	background-color: #a7d2de;
}

.block.mod--service.mod--3 {
	background-color: #cde9dd;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
	display: flex;
}

.columns-2 {
	justify-content: space-between;
	display: flex;
}

.columns-2.mod--service {
	min-height: 17.25rem;
	grid-column-gap: 1.5rem;
}

.columns-2.mod--founders {
	grid-column-gap: 5.25rem;
	grid-row-gap: 5.25rem;
}

.columns-2.mod--about.mod--1 {
	margin-bottom: 7.5rem;
}

.columns-2.mod--about.mod--2 {
	grid-column-gap: 15.5rem;
	grid-row-gap: 15.5rem;
}

.service__heading {
	text-transform: uppercase;
	font-size: 2.125rem;
	font-weight: 600;
	line-height: 1.2;
}

.service__heading.anim-scroll-up {
	color: var(--white);
	font-family: Poppins, sans-serif;
}

.service__heading2 {
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.2;
}

.service__corner {
	width: 2.5rem;
	height: 2.5rem;
	background-color: rgba(0, 0, 0, 0);
	position: absolute;
	top: -0.0625rem;
	bottom: auto;
	left: auto;
	right: -0.0625rem;
	transform: translate(0);
	box-shadow: 6px -6px 0.5rem #fff;
}

.service__corner-elem {
	width: 0;
	height: 0;
	border-bottom: 2.5rem solid #672300;
	border-right: 2.5rem solid rgba(255, 255, 255, 0);
}

.service__corner-elem.mod--2 {
	border-bottom-color: #9ac1cc;
}

.service__corner-elem.mod--white {
	border-width: 2.5rem 0 0 2.5rem;
	border-top-style: solid;
	border-top-color: #fff;
	border-bottom-color: var(--light-brown);
	border-left-style: solid;
	border-left-color: rgba(255, 255, 255, 0);
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.service__corner-elem.mod--3 {
	border-bottom-color: #9bb0a7;
}

.col {
	flex: 1;
	position: relative;
}

.col.col--1.mod--founders {
	flex: 0 auto;
	align-items: flex-start;
	display: flex;
}

.col.col--1.mod--about {
	max-width: 28rem;
	padding-top: 0.625rem;
}

.col.col--2.mod--founders {
	max-width: 15.5rem;
}

.service__overheading {
	color: #d68b6d;
	letter-spacing: -0.03em;
	text-transform: uppercase;
	font-weight: 500;
}

.section-2 {
	background-color: rgba(0, 0, 0, 0);
	margin-top: 40px;
	padding: 0 1.5rem 40px;
}

.content-3 {
	z-index: 2;
	width: 100%;
	max-width: 87rem;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.content-3.mod--categories {
	height: 5.3125rem;
	max-width: none;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-bottom: 0;
	display: flex;
}

.service__link {
	opacity: 0.8;
	text-decoration: underline;
	display: inline-block;
}

.link-8,
.link-8-copy {
	padding-left: 20px;
	padding-right: 20px;
}

.links-under-slider {
	color: var(--dark-brown);
	padding-left: 20px;
	padding-right: 20px;
	font-family: Poppins, sans-serif;
	font-size: 18px;
	font-weight: 500;
	text-decoration: none;
}

.links-under-slider:hover {
	color: var(--light-brown);
	text-decoration: underline;
}

.founders__name-span {
	display: inline-block;
	position: relative;
}

.founders__name-span.mod--2 {
	transform: rotate(4deg);
}

.founders__name-span.mod--2.mod--piter {
	transform: rotate(2deg);
}

.founders__name-span.mod--4 {
	transform: translate(0, -0.0625rem) rotate(-8deg);
}

.founders__name-span.mod--1 {
	transform: translate(0, -0.0625rem) rotate(8deg);
}

.founders__name-span.mod--3 {
	transform: rotate(-4deg);
}

.about__jar {
	z-index: 2;
	width: 13.125rem;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: 8.875rem;
	right: -6.5625rem;
	overflow: hidden;
}

.overflow-hidden-2 {
	z-index: 2;
	object-fit: fill;
	position: relative;
	overflow: hidden;
	transform: translate(0);
}

.overflow-hidden-2.mod--founders-img {
	width: 100%;
	background-color: #eceded;
	border: 0.25rem solid #fff;
	border-radius: 50%;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	transition: background-color 0.5s;
	display: flex;
}

.overflow-hidden-2.mod--founders-img.mod--1:hover {
	background-color: #cde9dd;
}

.overflow-hidden-2.mod--founders-img.mod--2:hover {
	background-color: #fbd1bd;
}

.text-wrap.mod--about-desc {
	max-width: 25.25rem;
	margin-bottom: 4.875rem;
}

.video.mod--flower {
	z-index: 2;
	width: 100%;
	height: 37rem;
	filter: brightness();
	position: relative;
}

.heading-large {
	padding-bottom: 0.1rem;
	font-size: 4.125rem;
	line-height: 1.2;
}

.about__jar-bg {
	z-index: 0;
	background-color: #f8f8f8;
	border-radius: 50%;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.img-wrap {
	position: relative;
	overflow: hidden;
	transform: translate(0);
}

.img-wrap.mod--about-01 {
	height: 31.25rem;
	margin-bottom: 7.75rem;
}

.img-wrap.mod--founders {
	margin-left: -0.25rem;
	margin-right: -0.75rem;
	transition: background-color 0.3s;
	overflow: visible;
}

.text-size-small {
	font-size: 0.875rem;
}

.about__flower {
	width: 100%;
	max-width: 28rem;
	margin-left: auto;
	position: relative;
}

.section-3 {
	padding: 4.6875rem 1.5rem;
}

.content-4 {
	z-index: 2;
	width: 100%;
	max-width: 87rem;
	margin-left: auto;
	margin-right: auto;
	position: relative;
}

.founders__name {
	z-index: -1;
	text-align: center;
	letter-spacing: 0.05em;
	font-size: 0.875rem;
	line-height: 1;
	transition: all 0.3s;
	position: absolute;
	top: auto;
	bottom: -0.9rem;
	left: 0%;
	right: 0%;
	transform: translate(0, -2em) rotate(-30deg);
}

.founders__name.mod--2 {
	color: #d9aa93;
}

.founders__name.mod--1 {
	color: #9bb0a7;
}

.text-style-allcaps {
	text-transform: uppercase;
}

.body {
	background-image: url('/assets/images/bg-1-min.jpg');
	background-position: 50%;
	background-repeat: repeat-y;
	background-size: cover;
}

.section-4 {
	justify-content: center;
	align-items: center;
	margin-top: 0;
	margin-left: 5%;
	margin-right: 5%;
	display: flex;
	position: static;
	top: 75px;
}

.uui-navbar02_item-heading-3 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-weight: 400;
}

.uui-navbar02_item-heading-3:hover {
	color: #de9808;
}

.search-icon-4 {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 8px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon-4:hover {
	background-color: #048060;
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 8px;
}

.uui-dropdown-icon-2 {
	width: 1.25rem;
	height: 1.25rem;
	color: var(--beige-2);
	justify-content: center;
	align-items: center;
	margin-right: 1rem;
	display: flex;
	position: absolute;
	top: auto;
	bottom: auto;
	left: auto;
	right: 0%;
}

.uui-dropdown-icon-2:hover {
	color: var(--beige-2);
}

.brand-4 {
	padding-left: 0;
	display: flex;
}

.search-3 {
	background-color: #374957;
	border-radius: 8px;
	justify-content: flex-end;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: flex;
}

.text-block-34 {
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 30px;
	font-weight: 800;
}

.uui-navbar02_menu-3 {
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	position: static;
}

.mega-menu-2 {
	z-index: 100;
	width: 100%;
	min-height: 5rem;
	background-color: #000;
	justify-content: center;
	align-items: center;
	padding-left: 2%;
	padding-right: 2%;
	display: flex;
	position: fixed;
	top: 47px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.bold-text-9 {
	color: #0a0442;
}

.nav-link-7 {
	color: var(--beige-dark);
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Libre Baskerville, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	text-align: center;
}

.nav-link-7:hover {
	color: var(--beige-2);
	text-decoration: underline;
}

.text-block-35 {
	color: var(--beige-2);
	text-transform: uppercase;
	font-family: Aleo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
}

.text-block-35:hover {
	color: var(--beige-2);
}

.uui-navbar02_dropdown-list-3 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-3.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: #000;
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: 5rem;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.nav-link-enquire-2 {
	color: #fff;
	text-transform: uppercase;
	background-color: #00e5e0;
	border: 0 solid #998868;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 20px;
	padding: 1rem;
	font-size: 18px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-enquire-2:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #0a0442;
	background-color: #fff;
}

.nav-link-enquire-2.w--current {
	color: #000;
}

.uui-navbar02_menu-left-3 {
	grid-column-gap: 32px;
	grid-row-gap: 32px;
	justify-content: flex-end;
	margin-top: 0;
	margin-right: 16px;
	display: flex;
}

.div-block-154 {
	width: 25px;
	height: 25px;
	cursor: pointer;
	background-image: url('/assets/images/exit-icon.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
}

.uui-navbar02_dropdown-toggle-3 {
	color: #475467;
	align-items: center;
	padding: 1.75rem 2.5rem 1.75rem 0.5rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-size: 1rem;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-3:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-3.w--open {
	height: 100%;
	padding-bottom: 1.75rem;
}

.uui-logo_component-6 {
	width: 100%;
	height: 60px;
	flex-wrap: nowrap;
	align-items: center;
	display: flex;
}

.search-input-4 {
	width: 95%;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #000;
	margin-bottom: 0;
	padding: 1.8rem 3.1rem 1.8rem 1rem;
	font-size: 0.85rem;
	font-weight: 300;
	position: relative;
}

.image-15 {
	height: 80px;
}

.section-slider {
	background-image: linear-gradient(to bottom, transparent 70%, black), linear-gradient(to bottom, var(--maroon), var(--maroon));
	justify-content: center;
	align-items: center;
	margin: 140px auto auto;
	display: flex;
}

.slider-4 {
	width: 100%;
	height: 600px;
	border: 18px solid var(--beige-dark);
	box-shadow: none;
	background-color: rgba(0, 0, 0, 0);
}

.slide-4 {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.slide-5 {
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
}

.div-block-155 {
	width: 100%;
	justify-content: center;
	align-items: center;
	margin: 60px 5%;
	display: flex;
}

.button-5 {
	color: #166b3e;
	text-transform: uppercase;
	background-color: rgba(0, 0, 0, 0);
	padding: 0;
	font-weight: 600;
	transition: all 0.45s;
}

.button-5:hover {
	color: #0a0442;
	-webkit-text-stroke-color: #160042;
	padding-right: 0;
}

.icon-small {
	height: 2rem;
}

.padding-section-medium {
	padding-top: 6rem;
	padding-bottom: 6rem;
}

.div-block-6 {
	width: auto;
	height: 45px;
	align-items: center;
	margin-bottom: 0;
	display: flex;
	overflow: hidden;
}

.features_grid {
	grid-column-gap: 2.5rem;
	grid-row-gap: 2rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.div-block-156 {
	height: 55%;
	border-radius: 5px;
	overflow: hidden;
}

.arrow-2 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.arrow-2:hover {
	width: auto;
	height: auto;
}

.news-section {
	color: #374957;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 60px 10%;
	display: flex;
}

.features_item {
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	background-color: #fff;
	border-radius: 1.25rem;
	flex-direction: column;
	grid-template-rows: auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	align-content: start;
	align-items: start;
	padding: 2rem;
	display: grid;
}

.div-block-157 {
	width: 100%;
	justify-content: space-between;
	display: flex;
}

.div-block-158 {
	width: auto;
	height: auto;
	position: static;
	overflow: hidden;
}

.div-block-158:hover {
	width: auto;
	height: auto;
}

.container-large {
	width: 100%;
	max-width: 80rem;
	margin-left: auto;
	margin-right: auto;
}

.features_components {
	grid-column-gap: 4rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.image-16 {
	width: 120%;
	max-width: none;
	border-radius: 5px;
}

.image-16.img-zoom {
	transition: all 0.45s;
}

.image-16.img-zoom:hover {
	transform: scale(1.1);
}

.text-block-36 {
	color: #374957;
}

.div-block-159 {
	width: 30%;
	height: auto;
	background-color: var(--beige-dark);
	border-radius: 0;
	flex-direction: column;
	align-items: flex-start;
	padding: 15px;
	display: flex;
}

.features_header {
	grid-column-gap: 3rem;
	grid-row-gap: 4rem;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.section_features {
	background-color: #edf7ff;
	display: none;
}

.heading-22 {
	color: #0a0442;
	text-transform: uppercase;
	margin-top: 10px;
	font-size: 17px;
	font-weight: 600;
	line-height: 35px;
}

.padding-global {
	padding-left: 2.5rem;
	padding-right: 2.5rem;
}

.grey-arrow-3 {
	width: 0;
	margin-left: 10px;
}

.text-block-6-copy {
	color: #fff;
	font-family: Libre Baskerville, sans-serif;
	font-size: 3vh;
	font-weight: 400;
}

.project-block-four {
	width: 30%;
	border: 15px solid var(--beige-dark);
	cursor: pointer;
	background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 22%, rgba(255, 255, 255, 0)), url('/assets/images/content-box-1.jpg');
	background-position: 0 0, 100%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	align-items: flex-end;
	margin-right: 10px;
	padding: 2rem;
	display: flex;
}

.section-5 {
	background-color: rgba(0, 0, 0, 0);
	justify-content: center;
	padding: 20px;
	display: none;
}

.d-20-btn-wrapper {
	width: 35%;
	height: 100px;
	perspective: 2000px;
	cursor: pointer;
	position: relative;
}

.d-20-btn-one {
	background-color: var(--floral-white);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-origin: 50% 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.d-20-btn-text {
	z-index: 10;
	color: #fff;
	letter-spacing: 2px;
	font-size: 1.5rem;
	text-decoration: none;
	position: relative;
}

.d-20-btn-two {
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-origin: 50% 0;
	background-color: rgba(0, 0, 0, 0);
	border: 5px solid #672300;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.d-20-btn-wrapper-2,
.d-20-btn-wrapper-3 {
	width: 35%;
	height: 100px;
	perspective: 2000px;
	cursor: pointer;
	position: relative;
}

.d-20-btn-2 {
	background-color: var(--floral-white);
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	transform-origin: 50% 100%;
	justify-content: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

.link-text-3 {
	z-index: 99;
	color: #131313;
	justify-content: center;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 1.75rem;
	font-weight: 400;
	line-height: 1.35;
	text-decoration: none;
	display: flex;
	position: relative;
	transform: perspective(725px);
}

.link-text-3:hover {
	text-transform: none;
	align-self: center;
	padding-top: 0;
	padding-bottom: 0;
	transform: perspective(725px);
}

.link-09_underline {
	width: 100%;
	height: 1px;
	background-color: #131313;
	position: absolute;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
}

.arrow_image {
	width: auto;
	height: auto;
	object-fit: contain;
	background-color: rgba(0, 0, 0, 0);
	border: 1px solid rgba(0, 0, 0, 0);
}

.link-09 {
	grid-column-gap: 1rem;
	background-color: var(--beige-dark);
	color: #131313;
	justify-content: flex-start;
	align-self: center;
	align-items: baseline;
	padding: 10px 20px;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.arrow-wrapper {
	width: auto;
	height: 100%;
	max-height: 100%;
	flex: none;
	justify-content: center;
	align-self: center;
	align-items: center;
	display: flex;
	position: relative;
	top: 2px;
}

.arrow-wrapper.is-before {
	height: auto;
	max-height: 100%;
	flex-flow: row;
	flex: none;
	align-self: center;
	align-items: center;
	display: flex;
	position: absolute;
	top: auto;
	bottom: 20%;
	left: -1.5rem;
	right: auto;
}

.div-block-160 {
	display: none;
}

.button-base {
	width: 50%;
	height: 5em;
	background-color: var(--blue-client);
	color: #fff;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	padding-right: 10px;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
	text-decoration: none;
	display: flex;
	position: relative;
	overflow: hidden;
}

.button-base__overlap {
	width: 0%;
	background-color: #fff;
	line-height: 0;
	position: absolute;
	top: 0%;
	bottom: 0%;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.button-base__overlap.overlap--move-from-bottom {
	width: 100%;
	height: 100%;
	top: auto;
	bottom: 0%;
	left: 0%;
	right: 0%;
	transform: translate(0%, 100%);
}

.button-base__button-text {
	font-size: 1.5em;
	position: relative;
}

.button-copy {
	z-index: 5;
	background-color: var(--black-4);
	color: var(--light-steel-blue);
	letter-spacing: 0.5px;
	text-transform: capitalize;
	cursor: pointer;
	background-image: url('/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button-copy:hover {
	background-color: var(--light-steel-blue);
	opacity: 1;
	color: var(--white);
}

.button-copy.two {
	background-color: var(--white);
	color: var(--dodger-blue);
	display: none;
	top: 180px;
}

.button-copy.training {
	background-color: var(--aquamarine);
	position: static;
	top: 80%;
}

.button-copy.training:hover {
	background-color: var(--medium-aquamarine);
}

.button-copy.training-session {
	background-color: var(--royal-blue-2);
	position: relative;
	top: auto;
}

.button-copy.secondary {
	border-bottom: 1px none var(--midnight-blue-2);
	background-color: var(--beige-dark);
	color: var(--light-brown);
	margin-right: 20px;
	padding-left: 20px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	display: none;
}

.button-copy.secondary:hover {
	border-bottom: 1px none var(--white);
	color: var(--blue-client);
	background-position: 93%;
}

.button-copy.secondary.inside-pages {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 1rem;
}

.button-2b {
	z-index: 5;
	background-color: var(--black-4);
	color: var(--light-steel-blue);
	letter-spacing: 0.5px;
	text-transform: capitalize;
	cursor: pointer;
	background-image: url('/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button-2b:hover {
	background-color: var(--light-steel-blue);
	opacity: 1;
	color: var(--white);
}

.button-2b.two {
	background-color: var(--white);
	color: var(--dodger-blue);
	display: none;
	top: 180px;
}

.button-2b.training {
	background-color: var(--aquamarine);
	position: static;
	top: 80%;
}

.button-2b.training:hover {
	background-color: var(--medium-aquamarine);
}

.button-2b.training-session {
	background-color: var(--royal-blue-2);
	position: relative;
	top: auto;
}

.button-2b.secondary {
	border-bottom: 1px none var(--midnight-blue-2);
	background-color: var(--beige-dark);
	color: var(--light-brown);
	margin-right: 20px;
	padding-left: 20px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	display: none;
}

.button-2b.secondary:hover {
	border-bottom: 1px none var(--white);
	color: var(--blue-client);
	background-position: 93%;
}

.button-2b.secondary.inside-pages {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 1rem;
}

.button-3b {
	z-index: 5;
	background-color: var(--black-4);
	color: var(--light-steel-blue);
	letter-spacing: 0.5px;
	text-transform: capitalize;
	cursor: pointer;
	background-image: url('/assets/images/white-arrow-icon-01.svg');
	background-position: 87%;
	background-repeat: no-repeat;
	background-size: 20px;
	border-radius: 0;
	padding: 0.75rem 3.75rem 0.75rem 1.25rem;
	font-family: Work Sans, sans-serif;
	font-size: 0.85rem;
	font-weight: 500;
	transition: all 0.4s, background-color 0.4s;
	position: static;
	top: auto;
}

.button-3b:hover {
	background-color: var(--light-steel-blue);
	opacity: 1;
	color: var(--white);
}

.button-3b.two {
	background-color: var(--white);
	color: var(--dodger-blue);
	display: none;
	top: 180px;
}

.button-3b.training {
	background-color: var(--aquamarine);
	position: static;
	top: 80%;
}

.button-3b.training:hover {
	background-color: var(--medium-aquamarine);
}

.button-3b.training-session {
	background-color: var(--royal-blue-2);
	position: relative;
	top: auto;
}

.button-3b.secondary {
	border-bottom: 1px none var(--midnight-blue-2);
	background-color: var(--beige-dark);
	color: var(--light-brown);
	padding-left: 20px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	display: none;
}

.button-3b.secondary:hover {
	border-bottom: 1px none var(--white);
	color: var(--blue-client);
	background-position: 93%;
}

.button-3b.secondary.inside-pages {
	background-color: rgba(0, 0, 0, 0);
	margin-left: 1rem;
}

.contact-link-copy {
	color: #fff;
	font-family: Work Sans, sans-serif;
	font-size: 1rem;
	line-height: 1.25rem;
	text-decoration: none;
	display: none;
}

.contact-link-copy:hover {
	color: var(--tomato);
}

.pricing-button-wrap {
	flex-direction: column;
	align-items: center;
	display: flex;
}

.content-wrap-left {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-right: 40px;
	display: flex;
}

.image-content-section {
	position: relative;
}

.pricing-div-01 {
	width: 100%;
	max-width: 1100px;
	grid-column-gap: 0px;
	grid-row-gap: 20px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.image-in-div {
	z-index: 2;
	width: auto;
	margin-top: 0;
	margin-right: 0;
	position: relative;
}

.location-title {
	opacity: 1;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.heading-3 {
	color: #672300;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 24px;
	font-weight: 700;
	line-height: 36px;
	display: flex;
}

.legal-info {
	opacity: 1;
	margin-top: 0;
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.footer-section-01 {
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 0;
	display: flex;
}

.pricing-title-block {
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 30px;
	display: flex;
}

.footer-link {
	opacity: 0.8;
	color: #000;
	margin-bottom: 15px;
	font-size: 16px;
	text-decoration: none;
	transition: all 0.2s;
	display: flex;
}

.footer-link:hover {
	opacity: 1;
	color: #3b2ae5;
}

.content-wrap-left-03 {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-right: 40px;
	display: flex;
}

.logo-txt {
	color: #000;
	margin-top: 3px;
	line-height: 23px;
}

.pricing-card-01 {
	background-color: #fff;
	border: 1px solid #dbdbdb;
	border-top: 5px solid #3b2ae5;
	padding: 40px 40px 25px;
}

.pricing-card-01._1 {
	border-right-style: none;
}

.pricing-card-01._3 {
	border-left-style: none;
}

.nav-inner {
	width: 100%;
	max-width: 1100px;
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
}

.clients-title {
	color: #3b2ae5;
	text-transform: none;
	margin-bottom: 25px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
}

.tabs-menu-01 {
	border-right: 1px solid #672300;
	flex-direction: column;
	justify-content: center;
	padding-top: 40px;
	padding-bottom: 40px;
	display: flex;
}

.pricing-title-01 {
	margin-bottom: 60px;
}

.text-field-01 {
	height: 48px;
	border: 1px solid #dbdbdb;
	margin-bottom: 0;
	margin-right: 0;
	padding-top: 13px;
	padding-bottom: 13px;
	padding-left: 15px;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	line-height: 22px;
}

.text-field-01:active {
	color: #3b2ae5;
	border-color: #dbdbdb;
}

.grid-sec-1 {
	flex-direction: column;
	align-items: flex-start;
	padding-right: 80px;
	display: flex;
}

.clone-wrap {
	font-family: Aleo, sans-serif;
}

.content-grid-03 {
	width: 100%;
	max-width: 1100px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
}

.right-arrow-4 {
	height: 15px;
	margin-top: 2px;
	margin-left: 7px;
}

.inner-hero-content-04 {
	max-width: 650px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #dbdbdb;
	flex-direction: column;
	align-items: flex-start;
	padding: 0;
	display: flex;
}

.globe {
	width: auto;
	height: 80px;
	max-width: none;
	margin-right: 10px;
}

.content-section-03 {
	width: 100%;
	background-color: rgba(239, 226, 218, 0.27);
	background-image: url('/assets/images/Battle-Map-Background-Campaign-Miniatures-new-min.jpg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
	border-bottom: 1px #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
}

.content-left-inner-03 {
	border: 5px #000;
	border-left: 5px solid #672300;
	flex-direction: column;
	align-items: flex-start;
	padding-left: 40px;
	display: flex;
}

.grid-sec-2 {
	flex-direction: column;
	align-items: flex-start;
	display: flex;
}

.section-title {
	color: var(--maroon);
	letter-spacing: 1px;
	-webkit-text-stroke-color: var(--white-smoke);
	text-transform: capitalize;
	font-family: Libre Baskerville, sans-serif;
	font-size: 24px;
	font-weight: 700;
	line-height: 30px;
	text-decoration: none;
}

.section-title.footer {
	margin-bottom: 20px;
}

.feature-list-item {
	align-items: center;
	margin-bottom: 10px;
	display: flex;
}

.clients-section-01 {
	width: 100%;
	background-color: #420002;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 40px;
	padding-bottom: 50px;
	display: flex;
}

.card-01-grid {
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr;
	margin-top: 30px;
}

.in-tabs-image {
	z-index: 5;
	min-height: 350px;
	background-position: 50%;
	background-size: cover;
	position: relative;
}

.secondary-button {
	color: #000;
	align-items: center;
	margin-left: 25px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-decoration: none;
	transition: all 0.2s;
	display: flex;
}

.secondary-button:hover {
	opacity: 0.8;
	transform: translate(5px);
}

.secondary-button.no-left-margin {
	margin-top: 10px;
	margin-left: 0;
	display: flex;
}

.secondary-button.in-pricing {
	margin-top: 20px;
	margin-left: 0;
}

.secondary-button.in-pricing:hover {
	transform: none;
}

.secondary-button.invert {
	filter: invert();
}

.button-wrapper {
	align-items: center;
	margin-top: 10px;
	display: flex;
}

.content-card-03 {
	width: 270px;
	height: 270px;
	background-color: rgba(223, 216, 188, 0.97);
	border: 10px solid #c5bd9e;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 40px;
	transition: all 0.2s;
	display: flex;
}

.content-card-03:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.services-wrap-footer {
	margin-top: 0;
}

.cards-section-01 {
	width: 100%;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
}

.blue {
	color: #3b2ae5;
}

.newsletter-form-01 {
	align-items: stretch;
	display: flex;
}

.tab-link-01 {
	background-color: var(--maroon);
	opacity: 0.75;
	white-space: nowrap;
	border-right: 5px solid #f7f8f8;
	padding: 20px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.tab-link-01:hover {
	opacity: 1;
}

.tab-link-01.w--current {
	background-color: var(--maroon);
	opacity: 1;
	color: var(--beige-2);
	border-right-color: #672300;
}

.location-wrap {
	margin-top: 20px;
}

.plan-price-wrap {
	margin-bottom: 25px;
}

.brand-link {
	align-items: center;
	font-size: 23px;
	font-weight: 700;
	display: flex;
}

.brand-link.in-footer {
	margin-bottom: 15px;
}

.cards-01-inner-wrap {
	width: 100%;
	max-width: 1100px;
}

.hero-content-01 {
	width: 100%;
	height: 100%;
	max-width: 1100px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	display: flex;
}

.clients-inner-01 {
	width: 100%;
	max-width: 1100px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-rows: minmax(0, 1fr);
	grid-auto-columns: 1fr;
	justify-content: space-between;
	align-items: center;
	justify-items: center;
	display: grid;
}

.paragraph-17 {
	opacity: 0.75;
	font-size: 16px;
	line-height: 22px;
	display: none;
}

.paragraph-17.black {
	opacity: 1;
}

.paragraph-17.no-bottom-pad {
	margin-bottom: 0;
}

.checkbox {
	width: 22px;
	height: 22px;
	background-color: #3b2ae5;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
	padding: 6px;
	display: flex;
}

.features-wrap {
	margin-bottom: 60px;
}

.pricing-section-01 {
	width: 100%;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
}

.footer-01-div {
	width: 100%;
	max-width: 1100px;
	grid-column-gap: 20px;
	grid-row-gap: 20px;
	grid-template-rows: auto;
	grid-template-columns: 2fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.icon-div {
	width: 65px;
	height: 65px;
	background-color: #3b2ae5;
	margin-bottom: 20px;
	padding: 13px;
}

.icon-div.smaller {
	width: auto;
	height: auto;
	mix-blend-mode: multiply;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	align-self: center;
	align-items: flex-start;
	margin-bottom: 0;
	padding: 0;
	display: flex;
}

.icon-div.smaller.icons-position {
	align-self: flex-start;
}

.heading-1 {
	text-transform: capitalize;
	margin-top: 0;
	font-size: 45px;
	font-weight: 600;
	line-height: 55px;
}

.button-6 {
	border: 0px solid var(--blue-client);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
	border-left-style: none;
	border-left-width: 0;
}

.button-6.pricing {
	width: 100%;
	text-align: center;
}

.button-6.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6.nav:hover {
	opacity: 0.8;
}

.button-6.categories {
	border-width: 3px;
	border-color: var(--olive-2);
	margin-top: 40px;
}

.content-section-01 {
	width: 100%;
	background-color: #e6e2d0;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: none;
}

.card-01-button {
	width: 100%;
	text-align: center;
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	padding: 13px 22px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.card-01-button:hover {
	opacity: 0.8;
}

.tabs-01 {
	width: 100%;
	max-width: 1100px;
	display: flex;
}

.form-section-inner-02 {
	border: 0px solid var(--beige);
	background-color: var(--beige-dark);
	color: rgba(0, 0, 0, 0);
	text-align: left;
	flex-direction: row;
	align-items: center;
	margin-top: 0;
	padding: 12px;
	display: flex;
}

.client-logo-2 {
	max-width: 175px;
	opacity: 0.55;
	transition: all 0.2s;
}

.client-logo-2:hover {
	opacity: 1;
}

.card-01 {
	width: 100%;
	flex-direction: column;
	align-items: center;
	display: flex;
}

.content-section-inner-01 {
	width: 100%;
	max-width: 1100px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: grid;
}

.nav-wrapper-01 {
	background-color: var(--dark-brown);
	border-bottom: 1px solid #dbdbdb;
	justify-content: center;
	display: none;
	position: fixed;
	top: 50px;
	bottom: auto;
	left: 0%;
	right: 0%;
}

.heading-4 {
	margin-top: 0;
	font-weight: 600;
	text-transform: uppercase;
}

.nexsletter-form-block-01 {
	width: 75%;
	margin-top: 0;
	margin-bottom: 0;
}

.hero-section-04 {
	width: 100%;
	background-image: url('/assets/images/slant.png'), linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('/assets/images/people-in-office.jpg');
	background-position: 50% 100%, 0 0, 50%;
	background-repeat: no-repeat, repeat, repeat;
	background-size: 100%, auto, cover;
	flex-direction: column;
	align-items: center;
	padding-top: 140px;
	padding-bottom: 195px;
	display: flex;
}

.hero-section-04.no-slant {
	background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url('/assets/images/people-in-office.jpg');
	background-position: 0 0, 50%;
	background-repeat: repeat, repeat;
	background-size: auto, cover;
	padding-top: 140px;
	padding-bottom: 140px;
}

.form-section-02 {
	width: 100%;
	flex-direction: column;
	align-items: center;
	display: flex;
}

.tabs-content-01 {
	padding: 40px;
}

.heading-2 {
	color: var(--dark-slate-grey);
	margin-top: 0;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.card-content-01 {
	width: 90%;
	background-color: #fff;
	border: 1px solid #dbdbdb;
	margin-top: -90px;
	padding: 0;
}

.nav-link-8 {
	color: #000;
	text-transform: none;
	border: 1px #000;
	padding-top: 20px;
	padding-bottom: 20px;
	font-size: 16px;
	font-weight: 700;
	transition: all 0.2s;
}

.nav-link-8:hover {
	color: #3b2ae5;
	border-style: none;
	border-color: #000;
	border-radius: 0;
}

.nav-link-8.w--current {
	color: #3b2ae5;
}

.card-image {
	width: 100%;
	height: 225px;
	background-image: url('/assets/images/man-on-computer.jpg');
	background-position: 50%;
	background-size: cover;
	border: 1px #dbdbdb;
	flex-direction: column;
	align-items: center;
	display: flex;
}

.border-effect {
	z-index: 1;
	background-color: #fff;
	border: 5px solid #3b2ae5;
	position: absolute;
	top: -20px;
	bottom: 15px;
	left: 15px;
	right: -20px;
}

.border-effect.no-fill {
	border-color: var(--blue-client);
	background-color: rgba(0, 0, 0, 0);
}

.inner-card-content-01 {
	padding: 20px;
}

.big-image-form-02 {
	width: 100%;
	height: auto;
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.05) 42%, black), linear-gradient(to bottom, var(--maroon), var(--maroon));
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 40px;
	display: flex;
}

.tabs-section-01 {
	width: 100%;
	background-image: linear-gradient(rgba(245, 232, 215, 0.5), rgba(245, 232, 215, 0.5)), url('/assets/images/maroon_bg-2.svg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: flex;
}

.heading-4 {
	color: #fff;
	text-transform: uppercase;
	align-items: flex-start;
	margin-top: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	display: flex;
}

.section-6 {
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	padding-bottom: 0;
	display: flex;
}

.section-6.is-red {
	color: #fff;
}

.section-6.is-red.footer-links {
	background-color: var(--dark-brown);
	color: var(--light-brown);
	background-image: none;
	background-position: 0 0;
	font-family: Aleo, sans-serif;
}

.container-3 {
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	width: 100%;
	max-width: 1000px;
}

.grid-3 {
	margin-left: auto;
	margin-right: auto;
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.footer-links {
	grid-row-gap: 5px;
	object-fit: cover;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	display: flex;
}

.paragraph-18 {
	color: #fff;
	font-family: Poppins, sans-serif;
	font-weight: 400;
}

.links-2 {
	color: #fff;
	cursor: pointer;
	padding-left: 20px;
	padding-right: 20px;
	text-decoration: none;
}

.links-2.on-footer {
	padding-left: 0;
	font-family: Poppins, sans-serif;
	border-bottom: 1px solid transparent;
}

.heading-2-copy {
	margin-top: 0;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	position: static;
}

.secondary-button-about {
	border: 3px solid var(--blue-client);
	color: #000;
	align-items: center;
	margin-left: 25px;
	padding: 13px 22px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-decoration: none;
	transition: all 0.2s;
	display: none;
}

.secondary-button-about:hover {
	opacity: 0.8;
	transform: translate(5px);
}

.secondary-button-about.no-left-margin {
	margin-top: 0;
	margin-left: 0;
}

.secondary-button-about.in-pricing {
	margin-top: 20px;
	margin-left: 0;
}

.secondary-button-about.in-pricing:hover {
	transform: none;
}

.secondary-button-about.invert {
	filter: invert();
}

.primary {
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	margin-left: 20px;
	margin-right: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
	box-shadow: 0 0 7px #2b150b;
}

.primary:hover {
	border-color: var(--olive-2);
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.primary:focus,
.primary:focus-visible {
	border: 3px solid #776f4e !important;
}

.primary.pricing {
	width: 100%;
	text-align: center;
}

.primary.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.primary.nav:hover {
	opacity: 0.8;
}

.primary.inside {
	box-shadow: none;
	margin-left: 0;
}

.button-6-copy {
	border: 3px solid var(--blue-client);
	background-color: var(--blue-client);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-copy:hover {
	opacity: 0.8;
}

.button-6-copy.pricing {
	width: 100%;
	text-align: center;
}

.button-6-copy.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-copy.nav:hover {
	opacity: 0.8;
}

.secondary {
	border: 0px solid var(--blue-client);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
}

.nexsletter-form-block-01 .secondary,
.button-wrapper .secondary {
	border: 0px solid var(--blue-client) !important;
	background-color: var(--slate-grey) !important;
}

.secondary:hover,
.button-wrapper .secondary:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.nexsletter-form-block-01 .secondary:hover {
	background-color: var(--dark-slate-blue-2) !important;
}

.secondary.pricing {
	width: 100%;
	text-align: center;
}

.secondary.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.secondary.nav:hover {
	opacity: 0.8;
}

.secondary.style {
	background-color: var(--dark-slate-blue-2);
	border-width: 3px;
	border-color: #485875;
	margin-left: 20px;
	margin-right: 20px;
	font-size: 14px;
}

.secondary.cancel {
	border-width: 3px;
	border-color: var(--maroon);
	background-color: var(--maroon);
	color: var(--white);
	margin-left: 20px;
	text-decoration: none;
}

.secondary.cancel:hover {
	border-color: var(--slate-grey);
	background-color: var(--slate-grey);
}

.section-title-copy {
	color: #672300;
	letter-spacing: 1px;
	-webkit-text-stroke-color: var(--white-smoke);
	text-transform: capitalize;
	margin-bottom: 10px;
	font-family: Aleo, sans-serif;
	font-size: 55px;
	font-weight: 600;
	line-height: 52px;
	text-decoration: none;
}

.section-title-copy.footer {
	margin-bottom: 20px;
}

.text-block-37,
.text-block-38,
.text-block-39 {
	color: var(--white-smoke);
	font-family: Libre Baskerville, sans-serif;
	font-weight: 700;
}

.section-title-tap {
	color: #672300;
	letter-spacing: 1px;
	-webkit-text-stroke-color: var(--white-smoke);
	text-transform: none;
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 48px;
	font-weight: 600;
	line-height: 52px;
	text-decoration: none;
}

.section-title-tap.footer {
	margin-bottom: 20px;
}

.in-tabs-image-3 {
	z-index: 5;
	min-height: 350px;
	background-image: url('/assets/images/ProductHeaderClashofEmpiresBanner_8C9B8EB15192F.jpg');
	background-position: 50%;
	background-size: cover;
	position: relative;
}

.in-tabs-image-2 {
	z-index: 5;
	min-height: 350px;
	background-position: 30%;
	background-size: cover;
	position: relative;
}

.section-title-about {
	color: #672300;
	letter-spacing: 0;
	-webkit-text-stroke-color: var(--white-smoke);
	text-transform: capitalize;
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 28px;
	font-weight: 700;
	line-height: 22px;
	text-decoration: none;
}

.section-title-about.footer {
	margin-bottom: 20px;
}

.heading-2-copy {
	color: var(--white-smoke);
	margin-top: 10px;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
}

.heading-2-copy-copy {
	width: 90%;
	margin-top: 0;
	font-family: Poppins, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	display: flex;
	position: static;
}

.text-block-40 {
	font-family: Aleo, sans-serif;
}

.text-block-41 {
	font-family: Libre Baskerville, sans-serif;
	font-weight: 400;
}

.text-block-42,
.text-block-43,
.text-block-44,
.text-block-45,
.text-block-46,
.text-block-47 {
	font-family: Aleo, sans-serif;
}

.grip-logo-image {
	width: 9rem;
	min-width: 150px;
	transition: all 0.45s;
}

.column-2 {
	justify-content: flex-end;
	padding-right: 0;
	display: flex;
}

.footer-signoff-links {
	border-bottom: 2px solid var(--white);
	margin-right: 1rem;
	line-height: 1.25rem;
	display: inline-block;
}

.footer-signoff-links:hover {
	border-bottom-color: rgba(0, 0, 0, 0);
}

.signoff-div {
	background-color: var(--maroon);
	justify-content: center;
	padding-top: 1.5rem;
	padding-bottom: 2.5rem;
	display: flex;
}

.grip-logo-link {
	float: right;
	opacity: 0.5;
	transition: background-color 0.2s, color 0.2s, border-color 0.2s, opacity 0.2s;
	display: none;
}

.grip-logo-link:hover {
	opacity: 1;
}

.footer-signoff-link-wrap {
	display: inline-block;
}

.footer-signoff-link {
	color: #fff;
	letter-spacing: 1px;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	text-decoration: none;
	transition: color 0.3s;
	border-bottom: 1px solid transparent;
}

.footer-signoff-link:hover {
	color: #bebebe;
}

.web-development-by {
	color: #fff;
	letter-spacing: 0.045rem;
	font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
	font-size: 0.65rem;
	line-height: 0.75rem;
	position: absolute;
	top: 0%;
	bottom: auto;
	left: auto;
	right: 0%;
}

.column-3 {
	align-items: center;
	padding-left: 0;
	padding-right: 0;
	display: flex;
}

.footer-copyright {
	color: var(--white);
	margin-bottom: 0;
	margin-right: 1.5rem;
	font-family: Poppins, sans-serif;
	font-size: 0.9rem;
	display: inline-block;
	padding: 0px;
}

.footer-copyright h1 {
	display: inline;
	margin: 0px;
	padding: 0px;
	font-size: inherit;
	line-height: inherit;
	font-family: inherit;
	font-weight: inherit;
	color: inherit;
}

.sign-off-div-wrap {
	width: 85%;
	min-width: 991px;
	padding-left: 2rem;
	padding-right: 2rem;
}

.heading-23 {
	color: #333;
	text-align: center;
}

.property-slider-widget-2 {
	width: 95%;
	height: 100%;
	max-width: 1600px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 10px;
	padding: 25px 50px;
	font-family: Aleo, sans-serif;
}

.badge-new {
	color: #fff;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	background-color: #131313;
	border-radius: 6px;
	padding: 14px 15px;
	font-size: 16px;
	line-height: 18px;
	display: inline-block;
}

.badge-new.small {
	padding: 12px;
	font-size: 12px;
	line-height: 14px;
}

.badge-new.small.light.category {
	color: #fff;
	background-color: #870011;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
	padding-bottom: 7px;
	font-weight: 700;
	text-decoration: none;
	transition: background-color 0.3s, color 0.3s;
}

.badge-new.small.light.category:hover {
	color: #fff;
	background-color: #870011;
}

.btn-primary-2 {
	color: #fff;
	text-align: center;
	transform-style: preserve-3d;
	background-color: #3c4c95;
	border-radius: 8px;
	justify-content: center;
	align-items: center;
	margin-right: 0;
	padding: 10px 16px;
	font-weight: 700;
	line-height: 20px;
	text-decoration: none;
	transition: background-color 0.3s, transform 0.3s, color 0.3s;
	display: flex;
	left: auto;
	right: auto;
}

.btn-primary-2:hover {
	color: #fff;
	background-color: #24235e;
	transform: translate3d(0, -3px, 0.01px);
}

.btn-primary-2.buy {
	background-color: var(--blue-client);
	border-radius: 0;
	padding-top: 10px;
	padding-bottom: 10px;
}

.find-out-more-block {
	height: 100%;
	cursor: pointer;
	background-color: #870011;
	border-radius: 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 15px;
	transition: transform 0.3s;
	display: flex;
}

.find-out-more-block:hover {
	transform: translate(0, -5px);
}

.see-all-items {
	color: #fff;
	text-align: center;
}

.product-block {
	background-color: #fff;
	border-radius: 10px;
	flex-direction: column;
	align-items: center;
	padding: 15px;
	transition: transform 0.3s;
	display: flex;
}

.product-block:hover {
	transform: translate(0, -5px);
}

.product-block.is-grey {
	background-color: var(--beige);
	border-radius: 0;
}

.product-block.is-grey:hover {
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.text-span-2 {
	color: #747474;
	text-decoration: line-through;
}

.wishlist-save-icon-2 {
	width: 42px;
	height: 42px;
	max-height: 42px;
	max-width: 42px;
	min-height: 42px;
	min-width: 42px;
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 22px 22px;
	justify-content: center;
	align-items: center;
	margin-right: 0;
	transition: all 0.2s;
	display: flex;
}

.wishlist-save-icon-2:focus {
	color: #24235e;
}

.paragraph-19 {
	color: #333;
	text-align: center;
	font-family: Poppins, sans-serif;
}

.icon-10 {
	width: 45px;
	height: 45px;
	color: #676767;
	justify-content: center;
	align-items: center;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 600;
	transition: all 0.2s;
	display: flex;
}

.icon-10:hover {
	color: #333;
}

.event-img {
	width: 100%;
	height: 100%;
	min-height: 250px;
	object-fit: cover;
	background-color: rgba(0, 0, 0, 0);
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 10px;
	justify-content: flex-end;
	display: flex;
	position: relative;
	right: 0;
}

.event-img.img1 {
	background-color: #fff;
	background-position: 50%;
	background-size: contain;
	border-radius: 0;
	flex-direction: row;
	justify-content: space-between;
	align-self: stretch;
	align-items: flex-start;
}

.event-img.img1.without-label {
	border-radius: 0;
	justify-content: flex-end;
}

.event-img.img2 {
	background-position: 50% 100%;
	background-size: auto;
	justify-content: space-between;
	align-items: flex-start;
}

.event-img.img2.without-label {
	background-color: #fff;
	background-size: contain;
	border-radius: 0;
	justify-content: flex-end;
}

.property-spotlight-mask-2 {
	width: 100%;
	max-width: 100%;
	flex-wrap: nowrap;
	padding-top: 19px;
	padding-bottom: 19px;
	display: flex;
}

.badge-special-offer {
	color: #fff;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	background-color: #131313;
	border-radius: 6px;
	padding: 14px 15px;
	font-size: 16px;
	line-height: 18px;
	display: inline-block;
}

.badge-special-offer.small {
	padding: 12px;
	font-size: 12px;
	line-height: 14px;
}

.badge-special-offer.small.light.category {
	color: #333;
	background-color: #ffc400;
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 7px;
	padding-bottom: 7px;
	font-weight: 700;
	text-decoration: none;
	transition: background-color 0.3s, color 0.3s;
}

.badge-special-offer.small.light.category:hover {
	color: #333;
	background-color: #ffc400;
}

.property-slider-arrow-link-4 {
	width: 50px;
	height: 50px;
	color: #676767;
	background-color: rgba(96, 96, 96, 0);
	border-radius: 50px;
	justify-content: center;
	align-items: center;
	margin: auto 0 auto 4px;
	font-size: 1.25rem;
	transition: all 0.3s;
	display: flex;
}

.property-slider-arrow-link-4:hover {
	background-color: #fff;
}

.property-slider-arrow-link-4.property-arrow-right {
	margin-right: 4px;
}

.property-slider-arrow-link-4.property-arrow-right:hover {
	background-color: #fff;
}

.property-spotlight-slide-2 {
	width: 24%;
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	flex-shrink: 0;
	align-self: stretch;
	margin-left: 0.5%;
	margin-right: 0.5%;
}

.content-section-inner-01-copy {
	width: 100%;
	max-width: 1100px;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	grid-template-rows: auto;
	grid-template-columns: 1fr 1fr;
	grid-auto-columns: 1fr;
	display: none;
}

.content-card-03-a {
	width: 250px;
	height: 250px;
	border: 0px solid var(--maroon);
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 30px;
	transition: all 0.2s;
	display: flex;
}

.content-card-03-a:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.content-card-03-b {
	width: 270px;
	height: 270px;
	border: 0px solid var(--maroon);
	background-color: var(--white);
	background-image: url('/assets/images/small-box-bg.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 40px;
	transition: all 0.2s;
	display: flex;
}

.content-card-03-b:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.image-17 {
	height: 90px;
	mix-blend-mode: multiply;
	align-self: flex-start;
	margin-top: 10px;
	margin-bottom: 10px;
}

.image-18 {
	height: 90px;
	mix-blend-mode: multiply;
}

.content-card-03-c {
	width: 270px;
	height: 270px;
	border: 0px solid var(--maroon);
	background-color: var(--white);
	background-image: url('/assets/images/small-box-bg.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 40px;
	transition: all 0.2s;
	display: flex;
}

.content-card-03-c:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.content-card-03-d {
	width: 270px;
	height: 270px;
	border: 0px solid var(--maroon);
	background-color: var(--white);
	background-image: url('/assets/images/small-box-bg.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 40px;
	transition: all 0.2s;
	display: flex;
}

.content-card-03-d:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.subscribe-wrapper {
	background-color: var(--beige-2);
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-left: 20px;
	margin-right: 20px;
	padding: 16px 20px;
	display: flex;
}

.form-5 {
	align-items: center;
	display: flex;
}

.text-field-5 {
	height: 39px;
	margin-bottom: 0;
	margin-right: 10px;
}

.heading-24 {
	color: #420002;
	margin-top: 0;
	margin-bottom: 10px;
	margin-right: 10px;
	font-family: Aleo, sans-serif;
}

.form-block-4 {
	margin-bottom: 0;
}

.button-6-email {
	background-color: var(--blue-client);
	text-transform: none;
	border: 3px #8f8355;
	border-radius: 0;
	margin-right: 0;
	padding: 8px 22px 9px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-email:hover {
	opacity: 0.8;
}

.button-6-email.pricing {
	width: 100%;
	text-align: center;
}

.button-6-email.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-email.nav:hover {
	opacity: 0.8;
}

.link-text-4 {
	color: #678338;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 13px;
	font-weight: 600;
	transition: color 0.3s;
	display: inline-block;
}

.heading-15 {
	margin-top: 0;
	margin-bottom: 0;
	font-size: 24px;
}

.mg-bottom-24px {
	margin-bottom: 24px;
	display: none;
}

.line-rounded-icon.link-icon-right {
	margin-left: 6px;
	display: none;
}

.card-copy {
	background-color: #fff;
	border: 1px solid #eff0f6;
	border-radius: 24px;
	box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06);
}

.card-copy.content-left {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 48px 32px;
	display: flex;
}

.card-copy.content-left.link-card {
	color: #3c4a20;
	transform-style: preserve-3d;
	background-color: #f1ede0;
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 300px;
	border-radius: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: Poppins, sans-serif;
	text-decoration: none;
	transition: transform 0.3s, color 0.3s;
	display: none;
}

.card-copy.content-left.link-card:hover {
	color: #678338;
	transform: scale3d(0.94, 0.94, 1.01);
}

.card-copy.content-left.link-card {
	color: #3c4a20;
	transform-style: preserve-3d;
	background-color: #f1ede0;
	background-position: 100%;
	background-repeat: no-repeat;
	background-size: 300px;
	border-radius: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-family: Poppins, sans-serif;
	text-decoration: none;
	transition: transform 0.3s, color 0.3s;
}

.card-copy.content-left.link-card:hover {
	color: #678338;
	transform: scale3d(0.94, 0.94, 1.01);
}

.grid-3-columns {
	grid-column-gap: 28px;
	grid-row-gap: 28px;
	grid-template-rows: auto auto;
	grid-template-columns: 1fr 1fr 1fr;
	grid-auto-columns: 1fr;
	margin-bottom: 20px;
	display: grid;
}

.heading-17 {
	font-size: 24px;
}

.card {
	background-color: #fff;
	border: 1px solid #eff0f6;
	border-radius: 24px;
	box-shadow: 0 2px 7px rgba(20, 20, 43, 0.06);
}

.card.content-left {
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 48px 32px;
	display: flex;
}

.card.content-left.link-card {
	background-color: var(--beige-2);
	color: #3c4a20;
	transform-style: preserve-3d;
	background-image: url('/assets/images/bg-texture.jpg');
	background-position: 50%;
	background-size: cover;
	border-radius: 0;
	padding: 0;
	font-family: Poppins, sans-serif;
	text-decoration: none;
	transition: transform 0.3s, color 0.3s;
}

.card.content-left.link-card:hover {
	color: #678338;
	transform: scale3d(0.94, 0.94, 1.01);
}

.card.content-left.link-card.promo-4 {
	cursor: pointer;
	padding: 3px;
	box-shadow: 0 1px 5px 4px rgba(0, 0, 0, 0.41);
}

.card.content-left.link-card.promo-4.inside {
	box-shadow: none;
	transition-property: none;
}

.link-wrapper {
	color: #211f54;
	transition: none;
	display: inline-block;
}

.link-wrapper:hover,
.link-wrapper.color-primary {
	color: #4a3aff;
}

.link-wrapper.color-primary:hover {
	color: #211f54;
}

.display-2 {
	color: #211f54;
	font-size: 72px;
	font-weight: 700;
	line-height: 84px;
}

.display-2.color-accent-1 {
	color: #4a3aff;
}

.display-2.color-accent-1.mg-bottom-16px {
	display: none;
}

.social-icon-box {
	width: 30px;
	height: 30px;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.social-icon-box.insta {
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/facebook-new.svg');
	background-position: 0 0;
	background-repeat: no-repeat;
	background-size: auto;
	border-radius: 9px;
}

.social-icon-box.insta:hover {
	transform: translate(0, -5px);
}

.social-icon-box.fb {
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/insta-new3.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.social-icon-box.fb:hover {
	transform: translate(0, -5px);
}

.social-icon-box.utube {
	width: 40px;
	height: 40px;
	cursor: pointer;
	background-image: url('/assets/images/youtube-new.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	margin-left: 10px;
	margin-right: 10px;
}

.social-icon-box.utube:hover {
	transform: translate(0, -5px);
}

.wrapper-3 {
	width: 100%;
	justify-content: flex-end;
	display: flex;
}

.search-4 {
	width: 38px;
	height: 38px;
	opacity: 1;
	align-items: center;
	margin: 10px 20px;
	display: flex;
	overflow: hidden;
}

.search-4.hidden {
	display: none;
}

.div-block-161 {
	z-index: 3000;
	width: 100%;
	height: 50px;
	background-color: var(--maroon);
	justify-content: flex-end;
	padding: 0 2%;
	display: flex;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.link-text-5 {
	opacity: 1;
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	border-bottom: 0 rgba(0, 0, 0, 0);
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 0.95rem;
	text-decoration: none;
	transition: opacity 0.3s;
}

.link-text-5:hover {
	opacity: 1;
	color: var(--beige);
	border-bottom: 0 rgba(0, 0, 0, 0);
}

.search-icon-5 {
	width: 35px;
	height: 35px;
	min-height: auto;
	background-color: rgba(0, 0, 0, 0);
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border: 0.25px rgba(0, 0, 0, 0);
	border-radius: 5px;
	padding: 0 0.25rem;
	transition: all 0.3s, background-color 0.3s;
}

.search-icon-5:hover {
	background-color: var(--maroon);
	background-image: url('/assets/images/search-white-01.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 15px;
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
}

.nav-items-container {
	width: 100%;
	height: 100%;
	max-width: none;
	justify-content: space-between;
	align-items: center;
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.login-button-main-mobile-2 {
	height: auto;
	color: #fff;
	background-color: #007050;
	background-image: url('/assets/images/lock.svg');
	background-position: 9%;
	background-repeat: no-repeat;
	background-size: 20px;
	border: 2px #84d5f7;
	border-radius: 100px;
	align-items: center;
	padding: 9px 25px 9px 40px;
	font-family: Ubuntu, Helvetica, sans-serif;
	font-weight: 600;
	line-height: 20px;
	transition: all 0.45s;
	display: none;
}

.login-button-main-mobile-2:hover {
	background-color: #1a1a1a;
}

.desktop-logo {
	width: 220px;
	min-width: 110px;
}

.desktop-logo.out {
	display: none;
}

.text-block-48 {
	color: var(--beige);
	text-transform: uppercase;
	font-family: Libre Baskerville, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
}

.text-block-48:hover {
	color: var(--beige);
	text-decoration: underline;
}

.nav-link-copy {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-copy:hover {
	color: #00e5e0;
}

.nav-link-copy.w--current {
	color: #000;
}

.uui-navbar02_menu-button-4 {
	padding: 0;
}

.mega-desktop {
	z-index: 3000;
	width: 100%;
	border-bottom: 0px solid var(--beige);
	background-color: var(--dark-brown);
	justify-content: center;
	align-items: center;
	padding-left: 2%;
	padding-right: 2%;
	display: flex;
	position: fixed;
	top: 50px;
	overflow: visible;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.uui-navbar02_item-heading-4 {
	color: #fff;
	letter-spacing: 1px;
	-webkit-text-stroke-color: #fff;
	text-transform: capitalize;
	margin-bottom: 0.25rem;
	font-family: Aleo, sans-serif;
	font-weight: 400;
}

.uui-navbar02_item-heading-4:hover {
	color: var(--beige-2);
	font-family: Aleo, sans-serif;
	text-decoration: underline;
}

.uui-navbar02_menu-4 {
	flex: 1;
	justify-content: flex-end;
	align-items: center;
	display: flex;
	position: static;
}

.nav-link-9 {
	color: var(--beige);
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link-9:hover {
	color: var(--beige);
	-webkit-text-stroke-color: var(--beige);
	text-decoration: underline;
}

.nav-link-9.w--current {
	color: #000;
}

.uui-navbar02_menu-left-4 {
	grid-column-gap: 4px;
	grid-row-gap: 32px;
	justify-content: flex-end;
	margin-top: 0;
	margin-right: 0;
	display: flex;
}

.nav-link-copy-2 {
	color: #fff;
	text-transform: capitalize;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Exo, sans-serif;
	font-size: 15px;
	font-weight: 400;
	line-height: 24px;
	transition: all 0.3s;
}

.nav-link-copy-2:hover {
	color: #00e5e0;
}

.nav-link-copy-2.w--current {
	color: #000;
}

.uui-navbar02_dropdown-list-4 {
	box-shadow: 0 12px 16px -4px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(16, 24, 40, 0.03);
}

.uui-navbar02_dropdown-list-4.w--open {
	width: auto;
	max-width: none;
	min-width: auto;
	background-color: var(--dark-brown);
	padding-left: 0%;
	padding-right: 0%;
	position: absolute;
	top: 5rem;
	bottom: auto;
	left: auto;
	right: auto;
	overflow: hidden;
}

.uui-dropdown-icon-3 {
	width: 1.25rem;
	height: 1.25rem;
	color: var(--beige);
	flex: 1;
	justify-content: space-between;
	align-items: stretch;
	margin-right: 1rem;
	display: flex;
	position: absolute;
	top: 29px;
	bottom: auto;
	left: auto;
	right: -5px;
}

.uui-navbar02_dropdown-toggle-4 {
	color: #475467;
	align-items: center;
	padding: 1.75rem 2.5rem 1.75rem 0.5rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Oxygen, Fira Sans, Droid Sans, sans-serif;
	font-weight: 600;
	transition: all 0.3s;
	display: flex;
}

.uui-navbar02_dropdown-toggle-4:hover {
	color: #344054;
}

.uui-navbar02_dropdown-toggle-4.w--open {
	height: 100%;
	padding-bottom: 1.75rem;
}

.search-5 {
	justify-content: flex-end;
	align-items: center;
	padding-top: 0;
	padding-bottom: 0;
	padding-left: 0;
	display: none;
}

.uui-navbar02_menu-dropdown-2 {
	align-items: center;
	display: none;
	position: static;
}

.div-block-162 {
	width: 100%;
	max-width: 1100px;
	flex-direction: row;
	padding-top: 5px;
	padding-bottom: 10px;
	display: flex;
}

.menu-container {
	width: 100%;
	max-width: none;
	padding-top: 5px;
	padding-bottom: 5px;
	display: flex;
}

.div-block-153-old {
	z-index: 100;
	width: 100%;
	height: 50px;
	background-color: #420002;
	justify-content: flex-end;
	padding: 0 1% 0 5%;
	display: none;
	position: fixed;
	top: 0%;
	bottom: auto;
	left: 0%;
	right: 0%;
	overflow: hidden;
}

.button-6-slider-midle {
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	margin-left: 20px;
	margin-right: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
	box-shadow: 0 0 7px #2b150b;
}

.button-6-slider-midle:hover {
	background-color: var(--dark-slate-blue-2);
	box-shadow: none;
	opacity: 0.8;
}

.button-6-slider-midle.pricing {
	width: 100%;
	text-align: center;
}

.button-6-slider-midle.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-slider-midle.nav:hover {
	opacity: 0.8;
}

.button-6-copy {
	border: 3px solid var(--blue-client);
	background-color: var(--blue-client);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-copy:hover {
	opacity: 0.8;
}

.button-6-copy.pricing {
	width: 100%;
	text-align: center;
}

.button-6-copy.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-copy.nav:hover {
	opacity: 0.8;
}

.button-6-copy {
	border: 3px solid var(--blue-client);
	background-color: var(--blue-client);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Aleo, sans-serif;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-copy:hover {
	opacity: 0.8;
}

.button-6-copy.pricing {
	width: 100%;
	text-align: center;
}

.button-6-copy.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-copy.nav:hover {
	opacity: 0.8;
}

.heading-4-copy {
	color: #fff;
	text-transform: uppercase;
	align-items: flex-start;
	margin-top: -5px;
	margin-bottom: 0;
	padding-bottom: 0;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	display: flex;
}

.nav-dropdown-container-2-copy {
	width: auto;
	justify-content: center;
	display: none;
}

.nav-dropdown-container-2-copy.w--open {
	width: auto;
	max-width: 1500px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	flex: 1;
	order: 1;
	padding-left: 0;
	font-family: Exo, sans-serif;
	display: block;
	top: -16%;
	left: 190px;
}

.nav-sub-sub {
	width: auto;
	border-right: 1px rgba(128, 128, 128, 0.25);
	flex-flow: wrap;
	flex: 0 auto;
	margin-left: 0;
	margin-right: 0;
	display: block;
	position: relative;
}

.nav-dropdown-wrapper-sub-sub {
	width: 100%;
	color: rgba(167, 167, 167, 0.5);
	border-bottom: 4px rgba(0, 0, 0, 0);
	order: -1;
	justify-content: space-between;
	align-items: center;
	padding: 0;
	transition: all 0.45s;
	display: flex;
	position: relative;
}

.nav-dropdown-wrapper-sub-sub:hover {
	color: rgba(167, 167, 167, 0.8);
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 4px #799165;
}

.nav-dropdown-wrapper-sub-sub.w--open {
	color: #fff;
}

.nav-dropdown-container-wrap-2-copy {
	width: auto;
	height: auto;
	background-color: #2c4a4d;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	font-family: Exo, sans-serif;
	display: flex;
}

.mobile-menu-arrow-4 {
	display: none;
}

.nav-links-2 {
	width: 100%;
	color: #fff;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	border: 0 solid rgba(0, 0, 0, 0);
	border-radius: 0;
	margin-bottom: 0.35rem;
	margin-left: 0;
	margin-right: 0;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	font-family: Aleo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	display: block;
}

.nav-links-2:hover {
	color: #fff;
	background-color: rgba(63, 62, 62, 0.3);
	border-style: solid;
	border-color: #fff;
	text-decoration: underline;
}

.nav-links-2.w--current {
	color: #fff;
}

.nav-dropdown-links-copy {
	width: 200px;
	height: auto;
	float: left;
	background-color: var(--dark-brown);
	border: 0 solid #3c4a20;
	flex-direction: column;
	align-items: stretch;
	margin-left: -10px;
	padding: 0.5rem;
	display: flex;
}

.icon-6-copy {
	z-index: 3000;
	color: #fff;
	margin-right: 10px;
	top: -5px;
	transform: rotate(-90deg);
}

.nav-dropdown-container-2 {
	width: auto;
	justify-content: center;
	display: none;
}

.nav-dropdown-container-2.w--open {
	width: auto;
	max-width: 1500px;
	color: #fff;
	background-color: rgba(0, 0, 0, 0);
	flex: 1;
	order: 1;
	padding-left: 0;
	font-family: Exo, sans-serif;
	display: block;
	top: 100%;
	left: 0;
}

.nav-2 {
	border-right: 1px rgba(128, 128, 128, 0.25);
	flex-flow: row;
	flex: 0 auto;
	align-items: center;
	margin-left: 0;
	margin-right: 0;
	display: flex;
	position: relative;
}

.nav-dropdown-wrapper {
	width: auto;
	color: rgba(167, 167, 167, 0.5);
	border-bottom: 4px rgba(0, 0, 0, 0);
	order: -1;
	justify-content: center;
	align-items: center;
	padding: 1.75rem 2.5rem 1.75rem 0.05rem;
	transition: all 0.45s;
	display: flex;
	position: relative;
}

.nav-dropdown-wrapper:hover {
	color: rgba(167, 167, 167, 0.8);
	background-color: rgba(0, 0, 0, 0);
	border-bottom: 4px #799165;
}

.nav-dropdown-wrapper.w--open {
	color: #fff;
}

.icon-11 {
	z-index: 3000;
	color: #3c4a20;
	margin-right: 5px;
	top: 14px;
}

.nav-dropdown-links {
	width: 200px;
	height: auto;
	float: left;
	background-color: var(--dark-brown);
	border: 0 solid #3c4a20;
	flex-direction: column;
	align-items: stretch;
	padding: 0.5rem;
	display: flex;
}

.nav-dropdown-container-wrap-2 {
	width: auto;
	height: auto;
	background-color: #2c4a4d;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	font-family: Exo, sans-serif;
	display: flex;
}

.nav-dropdown-link-4 {
	width: auto;
	box-shadow: none;
	color: #3c4a20;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	border: 2px #3c4a20;
	margin-bottom: 0;
	margin-left: 0;
	margin-right: 24px;
	padding: 1.25rem 5px 0.25rem;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 500;
	line-height: 1.25rem;
	text-decoration: none;
	transition: all 0.3s;
}

.nav-dropdown-link-4:hover {
	color: #2c4a4d;
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
	box-shadow: 0 5px 0 -3px #2c4a4d;
}

.nav-dropdown-link-4.w--current {
	background-color: rgba(39, 127, 139, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0);
	border-left: 1px solid rgba(0, 0, 0, 0);
	border-right: 1px solid rgba(0, 0, 0, 0);
}

.secondary-button-copy {
	color: #000;
	align-items: center;
	margin-left: 25px;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	text-decoration: none;
	transition: all 0.2s;
	display: none;
}

.secondary-button-copy:hover {
	opacity: 0.8;
	transform: translate(5px);
}

.secondary-button-copy.no-left-margin {
	margin-top: 10px;
	margin-left: 0;
}

.secondary-button-copy.in-pricing {
	margin-top: 20px;
	margin-left: 0;
}

.secondary-button-copy.in-pricing:hover {
	transform: none;
}

.secondary-button-copy.invert {
	filter: invert();
}

.secondary-nav-list-2 {
	justify-content: flex-end;
	margin-top: 0;
	margin-bottom: 0;
	padding-left: 0;
	font-size: 1rem;
	line-height: 1.25rem;
	display: flex;
}

.secondary-nav-list-item-2 {
	float: left;
	letter-spacing: 0.01rem;
	margin-bottom: 0;
	margin-left: 0.75rem;
	margin-right: 0.75rem;
}

.secondary-nav-list-item-2.first {
	border-left-style: none;
	margin-left: 0;
}

.secondary-nav-list-link-2 {
	float: left;
	color: rgba(0, 0, 0, 0.75);
	letter-spacing: 0.01rem;
	text-transform: uppercase;
	border-bottom-style: none;
	align-items: center;
	font-size: 0.75rem;
	font-weight: 600;
	display: flex;
	position: relative;
}

.secondary-nav-list-link-2:hover {
	color: #64802c;
	transform: translate(0, -3px);
}

.secondary-nav-icon-2 {
	width: 1.625rem;
	height: 1.625rem;
	float: left;
	background-color: var(--maroon);
	margin-right: 0.375rem;
	padding: 6px;
}

.secondary-nav-text-2 {
	float: left;
	align-items: center;
	display: flex;
}

.secondary-nav-text-2.indicator-text {
	min-width: 2em;
	box-shadow: none;
	text-align: center;
	letter-spacing: 0;
	background-color: #fff;
	border-radius: 20px;
	justify-content: center;
	align-self: center;
	align-items: center;
	margin-left: 0.25rem;
	margin-right: 0.25rem;
	padding: 4px;
	font-size: 0.875em;
	line-height: 1.5em;
	display: none;
	position: relative;
}

.header-search-wrap-2 {
	margin-bottom: 0;
	margin-left: 1.25rem;
}

.header-search-form-2 {
	background-color: #fff;
	display: flex;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.15);
}

.form-field-2 {
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
	position: relative;
}

.form-field-2.header-search-field {
	width: 120px;
	height: 2.25rem;
	color: #3e3f3d;
	background-color: #fff;
	border: 0 rgba(0, 0, 0, 0.15);
	border-radius: 0;
	margin-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 0.75rem;
	transition: all 0.35s;
}

.form-field-2.header-search-field:focus {
	width: 160px;
	background-color: #fff;
	border: 0 rgba(0, 0, 0, 0);
}

.form-field-2.header-search-field::-ms-input-placeholder {
	color: #797676;
}

.form-field-2.header-search-field::placeholder {
	color: #797676;
}

.button-7 {
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #163a59;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-right: 1.25rem;
	padding: 0.65rem 1.5rem;
	font-weight: 500;
	line-height: 1.375rem;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-7:hover {
	color: #fff;
	background-color: #3e3f3d;
}

.button-7.header-search-button {
	width: 2.5rem;
	opacity: 0.65;
	color: rgba(0, 0, 0, 0);
	text-transform: uppercase;
	background-color: #fff;
	background-image: url('/assets/images/search_blk.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 1em 1em;
	border-radius: 0;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	padding-top: 0;
	padding-bottom: 0;
	font-size: 0.875rem;
	line-height: 1em;
}

.button-7.header-search-button:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.log-in-link {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 0;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
}

.log-in-link:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.cart-new {
	height: auto;
	background-color: rgba(0, 0, 0, 0);
	border: 1px #fff;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0;
	align-items: center;
	margin-right: 1rem;
	padding-right: 0;
	text-decoration: none;
	transition: background-color 0.4s;
	display: flex;
	opacity: 1;
	color: #fff;
	letter-spacing: 0;
	text-transform: capitalize;
	border-bottom: 0 rgba(0, 0, 0, 0);
	padding-bottom: 0;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 0.95rem;
	text-decoration: none;
	transition: opacity 0.3s;
}

.cart-new:hover {
	background-color: rgba(0, 0, 0, 0);
	border-style: none;
	border-color: #000;
}

.cart-new.last {
	margin-right: 0;
}

.header-search-wrap-2-mobile {
	margin-bottom: 0;
	margin-left: 1.25rem;
	display: none;
}

.nav-link-9-mobile {
	color: var(--beige);
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Aleo, sans-serif;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
	display: none;
}

.nav-link-9-mobile:hover {
	color: var(--beige);
	-webkit-text-stroke-color: var(--beige);
	text-decoration: underline;
}

.nav-link-9-mobile.w--current {
	color: #000;
}

.nav-link-9-copy {
	color: var(--beige);
	text-transform: uppercase;
	align-items: center;
	padding: 1.75rem 0.5rem;
	font-family: Aleo, sans-serif;
	font-size: 15px;
	font-weight: 500;
	line-height: 24px;
	transition: all 0.3s;
	display: flex;
}

.nav-link-9-copy:hover {
	color: var(--beige);
	-webkit-text-stroke-color: var(--beige);
	text-decoration: underline;
}

.nav-link-9-copy.w--current {
	color: #000;
}

.button-6-slider-new {
	width: auto;
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey);
	color: var(--white);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-slider-new:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.button-6-slider-new.pricing {
	width: 100%;
	text-align: center;
}

.button-6-slider-new.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-slider-new.nav:hover {
	opacity: 0.8;
}

.button-6-slider-new.brands-button {
	margin-top: 20px;
}

.content-left-inner-03-copy {
	height: 300px;
	background-image: url('/assets/images/big-box-bg.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	border: 5px #000;
	border-left: 0 solid #672300;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding-left: 40px;
	display: flex;
}

.tabs-section-01-copy {
	width: 100%;
	background-image: linear-gradient(rgba(245, 232, 215, 0.5), rgba(245, 232, 215, 0.5)), url('/assets/images/maroon_bg-2.svg');
	background-position: 0 0, 50%;
	background-repeat: repeat, no-repeat;
	background-size: auto, cover;
	border-bottom: 1px solid #dbdbdb;
	flex-direction: column;
	align-items: center;
	padding-top: 100px;
	padding-bottom: 100px;
	display: none;
}

.promo-borders {
	border: 3px solid var(--border-color);
	width: 100%;
	height: 100%;
	padding: 2px;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.content-grid-03 .promo-borders {
	border: 3px solid var(--border-color);
}

.big-image-form-02.cbtext .promo-borders {
	border: 3px solid var(--border-color);
}

.promo-borders.inside {
	border-color: #672300;
}

.promo-borders-in {
	width: auto;
	height: auto;
	border: 2px solid var(--border-color);
	margin: 1px;
	padding-right: 0;
}

.promo-borders-in.inside {
	border-color: #672300;
}

.promo-box {
	background-image: url('/assets/images/bg-texture.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 3px;
	display: none;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.promo-box-long {
	background-image: url('/assets/images/bg-texture.jpg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: 3px;
	box-shadow: 0 1px 5px 4px rgba(0, 0, 0, 0.55);
}

.content-card-area {
	width: auto;
	height: auto;
	border: 0px solid var(--maroon);
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 10px;
	transition: all 0.2s;
}

.content-card-area:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.brands {
	width: 300px;
	height: 100px;
	border: 0px solid var(--maroon);
	background-color: rgba(0, 0, 0, 0);
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 20px 20px 20px 30px;
	transition: all 0.2s;
	display: flex;
}

.brands:hover {
	box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.07);
}

.heading-4-copy-copy {
	color: #fff;
	text-transform: uppercase;
	align-items: flex-start;
	margin-top: 10px;
	margin-bottom: 0;
	padding-bottom: 0;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	display: flex;
}

.footer-grip-link-2 {
	width: 133px;
	height: 29px;
	min-width: 0;
	cursor: pointer;
	background-image: url('/assets/images/globalreach_dark.svg');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 130px 29px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	text-decoration: none;
	transition: all 0.2s;
}

.footer-grip-link-2.white-logo {
	width: 140px;
	background-image: url('/assets/images/globalreach_white.svg');
	background-size: 150px 29px;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 0;
	display: flex;
}

.left-nav-nested-list-item-2 {
	margin-bottom: 1px;
	box-shadow: 0 1px #678338;
}

.heading-29 {
	color: #3c4a20;
}

.main-content-wrapper-2 {
	padding-top: 0.6rem;
	overflow: hidden;
}

.main-content-wrapper-2.no-sidebars {
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
}

.form-success-2 {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

.heading-5 {
	color: #047dbf;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 50px;
	font-weight: 400;
}

.heading-5.heading-5-inside-pages {
	color: #678338;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 700;
}

.paragraph-20 {
	font-size: 1rem;
}

.form-wrap-2 {
	margin-bottom: 0;
	font-size: 1rem;
	font-weight: 400;
}

.header-link-2 {
	color: #333;
	border: 1px #000;
}

.header-link-2:hover {
	color: #1a1a1a;
	border-bottom-color: rgba(0, 0, 0, 0);
	text-decoration: none;
	transform: translate(46px);
}

.heading-3 {
	color: var(--maroon-2);
	justify-content: flex-start;
	align-items: stretch;
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 23px;
	line-height: 29px;
	display: block;
}

.mainbutton {
	color: #fff;
	background-color: #3c4a20;
	border: 1px solid #3c4a20;
	border-radius: 6px;
	padding: 0.75rem 2rem;
	font-size: 1rem;
	font-weight: 500;
}

.mainbutton:hover {
	color: #678338;
	background-color: #fff;
}

.header-link {
	color: #721926;
	border-bottom: 1px solid #797676;
}

.header-link:hover {
	color: #797676;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.header-link.h2-link {
	color: #6b8339;
	border-bottom-width: 0;
}

.header-link.h2-link:hover {
	color: #3c4a20;
}

.header-link.h6-link {
	color: #313131;
	border-bottom-width: 0;
}

.header-link.h6-link:hover {
	color: #3c4a20;
}

.header-link.h1-link {
	color: #6b8339;
	border-bottom-width: 0;
}

.header-link.h1-link:hover {
	color: #3c4a20;
	-webkit-text-stroke-color: #3c4a20;
}

.header-link.h4-link {
	color: #6b8339;
	border-bottom-width: 0;
}

.header-link.h4-link:hover {
	color: #3c4a20;
}

.header-link.h3-link {
	color: #6b8339;
	border-bottom-width: 0;
}

.header-link.h3-link:hover {
	color: #3c4a20;
}

.header-link.h5-link {
	color: #6b8339;
	border-bottom-width: 0;
}

.header-link.h5-link:hover {
	color: #3c4a20;
}

.inside-content-column {
	width: 100%;
	margin-right: 20px;
}

.left-nav-list-2 {
	margin-top: 0;
	margin-bottom: 1.5em;
	padding-left: 0;
	box-shadow: 0 1px #3c4a20;
}

.left-nav-nested-list-2-copy {
	background-color: rgba(0, 0, 0, 0);
	border-top: 1px solid #672300;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.heading-1 {
	width: auto;
	color: var(--maroon-2);
	text-align: left;
	text-transform: capitalize;
	font-family: Libre Baskerville, sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
}

.form-error-2 {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

.button-copy-2 {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #721926;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: none;
}

.button-copy-2:hover {
	color: #fff;
	background-color: #313131;
}

.heading-30 {
	color: #678338;
	font-family: Poppins, sans-serif;
	font-weight: 600;
}

.left-col-item-wrap {
	border-top: 3px #d5a021;
	position: relative;
}

.paragraph-21 {
	color: #39383a;
	padding-left: 5px;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.heading-31 {
	font-family: Poppins, sans-serif;
}

.text-link-2 {
	color: #313131;
	border-bottom: 1px solid #797676;
	line-height: 1rem;
	transition-duration: 0.2s;
}

.text-link-2:hover {
	color: #721926;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-2.w--current {
	color: #28313b;
	font-weight: 700;
}

.text-link-2.breadcrumb-link {
	border-bottom-width: 0;
	border-bottom-color: rgba(0, 0, 0, 0);
	display: inline-block;
}

.text-link-2.breadcrumb-link:hover {
	color: #2a4187;
	border-bottom-width: 0;
	border-bottom-color: #c5d7df;
}

.left-nav-nested-list-3 {
	background-color: rgba(103, 35, 0, 0.1);
	border-top: 1px solid #672300;
	margin-top: 0;
	margin-bottom: 0;
	padding: 0 0 0 0.75em;
}

.breadcrumb-list-3 {
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	margin: 0 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 1rem;
}

.left-content-column {
	width: 25%;
	height: 100.002%;
	float: left;
	background-color: #fef8e0;
	flex: none;
	margin-left: 2%;
	padding: 20px 50px 20px 25px;
	position: relative;
	right: 0;
}

.left-content-column.show {
	width: auto;
	color: #fff;
	background-color: rgba(103, 35, 0, 0.1);
	border-top: 3px solid #672300;
	border-radius: 0;
	margin-top: 0;
	margin-left: 0%;
	margin-right: 20px;
	padding-top: 10px;
	padding-left: 16px;
	padding-right: 16px;
	display: none;
}

.paragraph-15 {
	font-size: 1rem;
}

.left-nav-list-link-3 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.65rem 1rem 0.65rem 0.5rem;
	font-family: Poppins, sans-serif;
	line-height: 1.3rem;
	text-decoration: none;
	transition: background-color 0.2s, color 0.2s, padding 0.2s;
	display: block;
}

.left-nav-list-link-3:hover {
	color: #672300;
	background-image: none;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-list-link-3.w--current:hover {
	color: #721926;
}

.inside-page-content-wrap-no-sidebars-2 {
	margin-left: 0%;
	margin-right: 0%;
	width: 100%;
}

.left-nav-list-item-2 {
	margin-bottom: 1px;
	font-size: 1rem;
	box-shadow: 0 -1px #672300;
}

.secondarybutton {
	color: #3c4a20;
	background-color: #fff;
	border: 1px solid #3c4a20;
	border-radius: 6px;
	margin-left: 20px;
	padding: 0.75rem 2rem;
	font-size: 1rem;
	font-weight: 500;
}

.secondarybutton:hover {
	color: #fff;
	background-color: #3c4a20;
}

.container-6 {
	max-width: 1500px;
	margin-left: auto;
	margin-right: auto;
	padding: 1vw 4vw 2vw;
	position: relative;
}

.text-link-3 {
	color: #313131;
	border-bottom: 0 solid #797676;
	line-height: 1rem;
	transition-property: none;
}

.text-link-3:hover {
	color: #3c4a20;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-3.w--current {
	color: #28313b;
	font-weight: 700;
}

.list {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.block-quote-4 {
	background-color: rgba(226, 235, 240, 0.1);
	border-left-color: #678338;
}

.left-nav-wrapper-2 {
	height: auto;
	border-top: 3px solid #4e1f1d;
	border-right: 1px #a2b1c4;
	margin-right: 20px;
	padding-top: 0;
	padding-bottom: 1vw;
	display: block;
}

.divider-2 {
	height: 1px;
	clear: both;
	background-color: #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

.content-wrapper-2 {
	align-items: flex-start;
	display: flex;
	width: 100%;
}

.paragraph-22 {
	font-size: 1rem;
}

.button-8 {
	min-width: 100px;
	color: #fff;
	text-align: center;
	letter-spacing: 0.05rem;
	text-transform: uppercase;
	background-color: #485875;
	border-radius: 0;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	margin-right: 1rem;
	padding: 7px 1.15rem 8px;
	font-size: 1rem;
	font-weight: 600;
	text-decoration: none;
	transition: all 0.3s;
	display: inline-block;
}

.button-8:hover {
	color: #fff;
	background-color: #67011f;
}

.button-8.secondary {
	background-color: #67011f;
	border-radius: 0;
}

.button-8.secondary:hover {
	background-color: #5c6e91;
}

.button-8.tertiary-copy {
	min-width: 0;
	color: #313131;
	background-color: #fff;
	border: 1px solid #797676;
	padding: 0.25rem 0.75rem;
	font-size: 0.9rem;
	display: none;
}

.button-8.tertiary-copy:hover {
	background-color: #c9c6c4;
}

.button-8.secondary-copy {
	background-color: #797676;
	display: none;
}

.button-8.secondary-copy:hover {
	background-color: #313131;
}

.inside-container-wraper {
	margin-bottom: 2rem;
	display: flex;
}

.left-nav-nested-list-link-2 {
	color: #313131;
	background-image: none;
	background-repeat: repeat;
	background-size: auto;
	padding: 0.75rem 1rem 0.6875rem 0.5rem;
	font-family: Poppins, sans-serif;
	font-size: 0.9rem;
	font-weight: 400;
	line-height: 1.2rem;
	text-decoration: none;
	transition: padding 0.2s, background-color 0.2s, color 0.2s;
	display: block;
}

.left-nav-nested-list-link-2:hover {
	color: #672300;
	background-image: url('/assets/images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-col-2 {
	width: 20%;
	height: 100%;
	min-width: 220px;
	background-color: rgba(0, 0, 0, 0);
	flex: none;
}

.intro-text-style-2 {
	color: #374957;
	margin-bottom: 0.75rem;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
}

.paragraph-9 {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 24px;
}

.image-37 {
	width: 100%;
	margin-bottom: 20px;
}

.heading-32 {
	color: #678338;
	margin-top: 10px;
	font-family: Poppins, sans-serif;
}

.heading-32:hover {
	color: #6b8339;
}

.inside-page-header-content-wrap-2 {
	width: 100%;
	border-bottom: 1px #d5e1e6;
	margin-top: 10px;
	margin-bottom: 1em;
	padding-bottom: 0;
	position: relative;
}

.left-nav-nested-list-item-3 {
	margin-bottom: 1px;
	box-shadow: 0 1px #672300;
}

.heading-33 {
	color: #672300;
	font-family: Libre Baskerville, sans-serif;
}

.main-content-wrapper-3 {
	padding-top: 0.6rem;
	overflow: hidden;
}

.main-content-wrapper-3.no-sidebars {
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
}

.heading-34 {
	color: #047dbf;
	letter-spacing: 0;
	text-transform: uppercase;
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 50px;
	font-weight: 400;
}

.heading-34.heading-5-inside-pages {
	color: var(--maroon-2);
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
}

.paragraph-23 {
	font-size: 1rem;
}

.heading-35 {
	color: #672300;
	font-family: Libre Baskerville, sans-serif;
}

.header-link-3 {
	color: #721926;
	border-bottom: 1px solid #797676;
}

.header-link-3:hover {
	color: #797676;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.header-link-3.h2-link {
	color: #672300;
	border-bottom-width: 0;
}

.header-link-3.h2-link:hover {
	color: #000;
}

.header-link-3.h6-link {
	color: #672300;
	border-bottom-width: 0;
}

.header-link-3.h6-link:hover {
	color: #000;
}

.header-link-3.h1-link {
	color: #672300;
	border-bottom-width: 0;
}

.header-link-3.h1-link:hover {
	color: #000;
	-webkit-text-stroke-color: #3c4a20;
}

.header-link-3.h4-link {
	color: #672300;
	border-bottom-width: 0;
}

.header-link-3.h4-link:hover {
	color: #000;
}

.header-link-3.h3-link {
	color: #672300;
	border-bottom-width: 0;
	flex-direction: column;
	flex: 0 auto;
	align-self: baseline;
	display: inline;
}

.header-link-3.h3-link:hover {
	color: #000;
}

.header-link-3.h5-link {
	color: #672300;
	border-bottom-width: 0;
}

.header-link-3.h5-link:hover {
	color: #000;
}

.left-nav-list-3 {
	margin-top: 0;
	margin-bottom: 1.5em;
	padding-left: 0;
	box-shadow: 0 1px #672300;
}

.heading-36 {
	width: auto;
	color: #672300;
	text-align: left;
	text-transform: capitalize;
	font-family: Libre Baskerville, sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
}

.heading-37 {
	color: var(--maroon-2);
	font-family: Libre Baskerville, sans-serif;
	font-weight: 600;
}

.paragraph-24 {
	color: #39383a;
	padding-left: 5px;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.heading-38 {
	color: var(--maroon-2);
	font-family: Poppins, sans-serif;
}

.breadcrumb-list-4 {
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	margin: 0 0.125rem 1rem;
	padding-top: 0.25em;
	padding-left: 0;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 1rem;
}

.paragraph-25 {
	font-size: 1rem;
}

.container-7 {
	max-width: 1200px;
	background-color: #fff;
	margin-left: auto;
	margin-right: auto;
	padding: 1vw 2vw 2vw;
	position: relative;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.container-7-outer {
	padding-top: 200px;
}

.text-link-4 {
	color: #313131;
	border-bottom: 0 solid #797676;
	line-height: 1rem;
	transition-property: none;
}

.text-link-4:hover {
	color: #672300;
	-webkit-text-stroke-color: #672300;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.text-link-4.w--current {
	color: #28313b;
	font-weight: 700;
}

.list-4 {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.block-quote-5 {
	background-color: rgba(226, 235, 240, 0.1);
	border-left-width: 0;
	border-left-color: #672300;
}

.block-quote-5.not {
	display: none;
}

.paragraph-26 {
	font-size: 1rem;
}

.paragraph-27 {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 24px;
}

.image-38 {
	width: 100%;
	height: auto;
	max-width: none;
	margin-bottom: 20px;
}

.heading-39 {
	color: var(--maroon-2);
	margin-top: 0;
	font-family: Libre Baskerville, sans-serif;
}

.button-main {
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey);
	box-shadow: none;
	text-transform: none;
	border-radius: 0;
	margin-left: 20px;
	margin-right: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
}

.button-main:hover {
	border-color: var(--olive-2);
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.button-main.pricing {
	width: 100%;
	text-align: center;
}

.button-main.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-main.nav:hover {
	opacity: 0.8;
}

.button-secondary {
	border: 0px solid var(--blue-client);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
}

.button-secondary:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.button-secondary.pricing {
	width: 100%;
	text-align: center;
}

.button-secondary.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-secondary.nav:hover {
	opacity: 0.8;
}

.button-secondary.style {
	border-width: 3px;
	border-color: var(--slate-grey);
	margin-left: 20px;
	font-size: 14px;
}

.div-block-163 {
	width: 20%;
	min-width: 220px;
	background-color: rgba(0, 0, 0, 0);
}

.right-col-link {
	color: #313131;
	border-bottom: 0 solid #797676;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4em;
	transition-property: none;
}

.right-col-link:hover {
	color: #672300;
	border-bottom-style: solid;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.right-col-link.w--current {
	color: #28313b;
	font-weight: 700;
}

.header-link-4 {
	color: #721926;
	border-bottom: 1px solid #797676;
}

.header-link-4:hover {
	color: #797676;
	border-bottom-color: rgba(0, 0, 0, 0);
}

.right-col-item-wrap {
	border-top: 1px solid #672300;
	margin-bottom: 1.5rem;
	margin-left: 0;
	padding-top: 0.75rem;
	padding-left: 0;
	padding-right: 0;
	position: relative;
}

.right-col-item-wrap.last {
	margin-bottom: 1rem;
}

.heading-40 {
	color: #1d428a;
	font-family: Poppins, sans-serif;
}

.heading-40.right-column {
	color: #672300;
	margin-top: 0;
	padding-left: 5px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 26px;
	line-height: 30px;
}

.sidebar-list-2 {
	font-family: Poppins, sans-serif;
}

.div-block-139 {
	background-color: rgba(103, 35, 0, 0.1);
	padding-bottom: 15px;
	padding-left: 0;
	padding-right: 0;
	display: none;
}

.paragraph-28 {
	color: #39383a;
	padding-left: 5px;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.heading-41 {
	color: #672300;
	margin-top: 0;
	padding-left: 5px;
	font-family: Libre Baskerville, sans-serif;
}

.sidebar-list-item-2 {
	margin-bottom: 0.75em;
	padding-left: 5px;
}

.heading-42 {
	color: #672300;
	margin-top: 0;
	padding-left: 5px;
	font-family: Libre Baskerville, sans-serif;
	font-weight: 600;
}

.spark-brand-logo {
	z-index: 2;
	max-width: 100%;
	padding-left: 0;
}

.image-40 {
	display: none;
}

.body-2 {
	background-color: #491615;
}

.body-3,
.body-4,
.body-5 {
	background-color: var(--maroon);
}

.promo-box-inside {
	background-color: rgba(103, 35, 0, 0.1);
	margin-right: 20px;
	padding: 3px;
}

.promo-box-inside.right-box {
	margin-right: 0;
}

.div-block-164 {
	background-color: var(--maroon);
	background-image: linear-gradient(rgba(255, 255, 255, 0), #000);
}

.block-quote-box {
	background-color: rgba(103, 35, 0, 0.1);
	margin-right: 20px;
	padding: 3px;
}

.block-quote-box.right-box {
	margin-bottom: 10px;
	margin-right: 0;
}

.button-tertiary {
	border: 0px solid var(--blue-client);
	background-color: var(--slate-grey);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 16px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
}

.button-tertiary:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.button-tertiary.pricing {
	width: 100%;
	text-align: center;
}

.button-tertiary.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-tertiary.nav:hover {
	opacity: 0.8;
}

.button-tertiary.style {
	border-width: 3px;
	border-color: var(--slate-grey);
	color: var(--dark-slate-grey);
	background-color: rgba(92, 110, 145, 0);
	margin-left: 0;
	font-size: 14px;
}

.button-tertiary.style:hover {
	border-color: var(--olive-2);
}

.button-6-slider-new-copy {
	width: auto;
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey);
	color: var(--white);
	text-transform: none;
	border-radius: 0;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	transition: all 0.2s;
}

.button-6-slider-new-copy:hover {
	background-color: var(--dark-slate-blue-2);
	opacity: 1;
}

.button-6-slider-new-copy.pricing {
	width: 100%;
	text-align: center;
}

.button-6-slider-new-copy.nav {
	text-transform: none;
	background-color: #3b2ae5;
	border-radius: 0;
	font-size: 16px;
	transition: all 0.2s;
}

.button-6-slider-new-copy.nav:hover {
	opacity: 0.8;
}

.button-6-slider-new-copy.brands-button {
	margin-top: 20px;
}

.cart-icon {
	width: 35px;
	height: 32px;
	opacity: 1;
	cursor: pointer;
	background-image: url('/assets/images/cart-item-01-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 35px;
	margin-left: 60px;
	transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	display: inline-block;
}

.cart-icon:hover {
	opacity: 1;
}

.cart-icon.mobile {
	display: none;
}

.account-2 {
	width: 32px;
	height: 32px;
	opacity: 1;
	cursor: pointer;
	background-image: url('/assets/images/create-account-01.png');
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 30px;
	margin-left: 40px;
	transition: opacity 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	display: inline-block;
}

.account-2:hover {
	opacity: 1;
}

.account-2.mobile {
	display: none;
}

@media screen and (min-width: 1440px) {
	.search-icon-4:hover,
	.search-icon-5:hover {
		background-color: #00e5e0;
	}

	.header-link.h1-link:hover,
	.header-link-3.h1-link:hover,
	.right-col-link:hover {
		color: #3c4a20;
	}
}

@media screen and (min-width: 1920px) {
	.event-img.img1:hover {
		background-repeat: no-repeat;
		background-size: cover;
	}
}

@media screen and (max-width: 1135px) {
	.nav-link-7 {
		font-size: 13px;
		padding-left: 0.2rem;
		padding-right: 0.2rem;
	}
}

@media screen and (max-width: 991px) {
	.desktop-logo {
		min-width: auto;
	}

	.navbar {
		width: auto;
		height: 110px;
		background-color: var(--white);
		flex-direction: column;
		justify-content: flex-end;
		align-items: stretch;
		margin-top: -1px;
		display: flex;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
	}

	.nav-link {
		color: var(--midnight-blue-2);
		border-top: 1px #000;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		justify-content: flex-start;
	}

	.nav-link:hover {
		background-color: var(--background-color);
		color: var(--tomato);
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
	}

	.nav-link.last {
		color: var(--midnight-blue-2);
	}

	.nav-link.last:hover {
		color: var(--tomato);
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.nav-link.first {
		border-top-style: solid;
		border-top-color: rgba(0, 0, 0, 0.1);
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.nav-link.first:hover {
		background-color: var(--background-color);
	}

	.slider {
		height: 50vh;
	}

	.nav-menu {
		background-color: var(--white);
		margin-top: 0;
	}

	.brand {
		display: none;
	}

	.home-content-boxes {
		height: auto;
		flex-flow: column;
		align-items: stretch;
		display: flex;
		position: static;
	}

	.div-block {
		width: 33.3%;
		margin-left: 0;
		padding-left: 32px;
		padding-right: 32px;
		position: relative;
		left: 0%;
	}

	.div-block-2 {
		width: 33.3%;
		padding-left: 32px;
		padding-right: 32px;
		left: 0%;
	}

	.div-block-2.two {
		width: 66.7%;
		padding-left: 32px;
		padding-right: 32px;
		display: flex;
	}

	.div-block-3 {
		width: 33.3%;
		padding-left: 32px;
		padding-right: 32px;
		left: 0%;
	}

	.slide-heading {
		max-width: 500px;
		font-size: 2.5rem;
		line-height: 3.15rem;
	}

	.slide-inner-content {
		padding-left: 5rem;
		padding-right: 5rem;
		top: 0%;
		left: 0%;
	}

	.text-block.hero-image {
		margin-bottom: 10px;
		font-size: 1rem;
	}

	.button.training-session {
		position: static;
	}

	.div-block-5 {
		margin-top: 0;
	}

	.content-headings {
		text-align: center;
		text-indent: 0;
		margin-top: 0;
		margin-bottom: 0.5rem;
		line-height: 2.25rem;
	}

	.content-headings.quote {
		justify-content: center;
		display: block;
	}

	.content-headings.blue {
		text-indent: 0;
	}

	.content-headings.white {
		line-height: 2rem;
	}

	.div-block-9 {
		width: 100%;
		justify-content: space-around;
	}

	.div-block-10 {
		width: 100%;
		flex-direction: row;
		justify-content: space-around;
	}

	.home-content-section.reviews {
		height: auto;
		display: block;
	}

	.section-content {
		width: 100%;
		margin-left: 0;
		padding: 3rem 3px;
		left: 0%;
	}

	.div-block-12 {
		width: 50%;
		margin-left: 0;
		left: 0%;
	}

	.div-block-13 {
		left: 0%;
	}

	.paragraph {
		width: auto;
		max-width: 650px;
	}

	.paragraph.white {
		font-size: 0.9rem;
		line-height: 1rem;
	}

	.div-block-15 {
		left: 0%;
	}

	.div-block-16 {
		justify-content: space-between;
		align-items: stretch;
		margin-left: 0;
	}

	.review-block {
		width: 33.3%;
		height: auto;
		position: static;
		left: 0%;
	}

	.review-block.two,
	.review-block.three {
		position: static;
		left: 0%;
	}

	.rights-container-block {
		padding: 1rem 4rem 2rem;
		left: 5%;
	}

	.footer-column-rights {
		width: 75%;
		align-items: center;
		padding-left: 0;
	}

	.footer-text {
		margin-bottom: 0;
	}

	.grip-link {
		width: 25%;
		align-items: center;
		padding-right: 0;
	}

	.footer-section {
		margin-left: 0;
	}

	.div-block-37 {
		left: 0%;
	}

	.footer-heading {
		margin-top: 0;
	}

	.footer-right-col-wrap {
		width: 50%;
		align-items: flex-start;
		margin-top: 1.5rem;
		display: flex;
	}

	.container-block.footer-container {
		flex-direction: column;
		padding: 2rem 4rem 1rem;
		left: 5%;
	}

	.social-media-link-block {
		transition-property: all;
		transition-duration: 0.3s;
	}

	.social-media-link-block:hover {
		border-radius: 0%;
	}

	.social-media-link-block.first-social-link {
		margin-left: 0;
	}

	.footer-nav-wrapper {
		width: 100%;
		border-bottom: 1px none var(--nav-hover-white);
		flex-wrap: wrap;
		justify-content: space-between;
		padding-bottom: 1rem;
	}

	.footer-nav-item {
		margin-bottom: 0.35rem;
	}

	.footer-col-wrap {
		width: 48%;
		min-width: 0;
		margin-top: 0.5rem;
		margin-right: 0;
		padding-bottom: 1rem;
	}

	.footer-col-wrap.footer-col-4 {
		width: 48%;
		margin-top: 2rem;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-col-wrap.quick-links {
		width: 48%;
		margin-top: 1rem;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-col-wrap.support {
		width: 48%;
		margin-top: 2rem;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.footer-col-wrap.connect {
		width: 48%;
		margin-top: 2rem;
	}

	.social-outer-wrap {
		width: 100%;
	}

	.multicol-wrap {
		width: 48%;
		border-top: 1px rgba(255, 255, 255, 0.4);
		margin-top: 1rem;
	}

	.google-translate-outer-wrap {
		width: 50%;
	}

	.div-block-42 {
		display: flex;
		position: static;
		left: 0%;
	}

	.div-block-42.reviews {
		margin-top: 80px;
	}

	.div-block-44 {
		width: 85%;
		margin-left: 0;
		left: 0%;
	}

	.icon-block {
		margin-left: 40px;
		margin-right: 40px;
	}

	.div-block-45 {
		left: 0%;
	}

	.menu-button {
		width: auto;
		height: auto;
		justify-content: space-between;
		align-items: center;
		margin-top: 0;
		padding: 25px 30px;
		display: flex;
		position: relative;
		top: 0;
		left: 0;
		right: 0%;
	}

	.menu-button.w--open {
		color: #196ab5;
		background-color: rgba(0, 0, 0, 0);
		display: block;
		position: relative;
		top: 7px;
	}

	.icon-3 {
		width: 20%;
		font-size: 42px;
	}

	.div-block-46 {
		width: 20%;
		background-image: url('/assets/images/logo.png');
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 125px;
		justify-content: flex-start;
		align-items: center;
		margin-right: 0;
		padding-bottom: 0;
		padding-left: 0;
		padding-right: 0;
		display: block;
		position: relative;
		left: auto;
		right: 65%;
	}

	.div-block-47 {
		justify-content: space-between;
		display: flex;
	}

	.secondary-nav-text.indicator-text {
		background-color: #fff;
		top: 55%;
		bottom: auto;
		left: 55%;
		right: auto;
	}

	.container-4 {
		max-width: 100%;
		padding-left: 20px;
		padding-right: 20px;
	}

	.container-4.nav-container-wrap {
		height: 75px;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.container-4.header-bar-container {
		padding-left: 0;
		padding-right: 0;
	}

	.two-part-drop-list-back-arrow {
		min-width: 0.625em;
		opacity: 0.6;
		color: rgba(0, 0, 0, 0);
		text-align: center;
		background-position: 0;
		background-repeat: no-repeat;
		background-size: 11px 12px;
		margin-right: 0.3125em;
		display: inline-block;
	}

	.nav-menu-wrap {
		background-color: var(--dodger-blue);
		text-align: left;
		padding-top: 0;
		padding-bottom: 100vh;
		display: block;
	}

	.secondary-nav-list {
		height: 3rem;
		max-height: 3rem;
		max-width: none;
		flex-grow: 1;
		margin-top: 0;
		margin-bottom: 0;
	}

	.logo {
		width: 180px;
		height: 100%;
		flex-shrink: 0;
		order: -1;
		margin: 0 0;
		padding-top: 0;
	}

	.menu-button-2 {
		width: 4.5rem;
		height: 4.5rem;
		float: left;
		box-shadow: none;
		color: #000;
		text-align: center;
		text-transform: uppercase;
		background-color: rgba(0, 0, 0, 0);
		background-position: 45%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		order: 1;
		justify-content: space-between;
		align-items: center;
		padding: 0 1.5rem;
		font-size: 1.5rem;
		font-weight: 600;
		line-height: 4.5rem;
		display: block;
	}

	.menu-button-2.w--open {
		color: #3128b1;
		background-color: rgba(0, 0, 0, 0);
	}

	.two-part-button-toggle {
		width: 10%;
		float: right;
		background-color: var(--midnight-blue-2);
		color: rgba(0, 0, 0, 0);
		text-align: center;
		cursor: pointer;
		background-position: 50%;
		background-repeat: no-repeat;
		background-size: 1em 1em;
		margin-bottom: 0;
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: 11px;
		line-height: 20px;
	}

	.two-part-button-toggle:hover {
		background-color: var(--light-steel-blue);
	}

	.nav-link-2 {
		z-index: 140;
		width: 100%;
		color: #fff;
		text-align: left;
		text-transform: capitalize;
		margin-bottom: 1px;
		margin-right: 0;
		padding: 1em 1rem 1em 1.5rem;
		font-family: Work Sans, sans-serif;
		font-size: 1rem;
		font-weight: 500;
		line-height: 1.15rem;
		text-decoration: none;
		transition: box-shadow 0.2s, padding 0.2s, background-color 0.2s, color 0.2s, font-size 0.2s;
		display: block;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-2:hover {
		background-color: var(--nav-hover-white);
		box-shadow: none;
		color: var(--aquamarine);
		padding-left: 1.75rem;
		padding-right: 0.75rem;
	}

	.nav-link-2.w--current {
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
		box-shadow: inset 5px 0 #3128b1, 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-2.w--current:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
	}

	.nav-link-2.mobile-list-back {
		background-color: var(--nav-hover-white);
		box-shadow: none;
		color: var(--aquamarine);
		letter-spacing: 0;
		text-transform: capitalize;
		cursor: pointer;
		padding-left: 0.5625rem;
		font-size: 1rem;
		line-height: 1.15rem;
	}

	.nav-link-2.mobile-list-back:hover {
		color: #fff;
		box-shadow: 0 1px rgba(255, 255, 255, 0.25);
	}

	.nav-link-2.two-part-mobile {
		width: 90%;
		float: left;
		box-shadow: none;
		color: var(--midnight-blue-2);
		letter-spacing: 0;
		text-transform: capitalize;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		padding: 1rem 2rem;
		font-family: Work Sans, sans-serif;
		font-size: 1rem;
		font-weight: 500;
		line-height: 20px;
		text-decoration: none;
		transition-property: none;
	}

	.nav-link-2.two-part-mobile:hover {
		width: 90%;
		background-color: var(--background-color);
		box-shadow: none;
		color: var(--tomato);
		border-bottom-color: rgba(51, 51, 51, 0.03);
		flex-grow: 0;
		padding-left: 2rem;
		font-style: normal;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.secondary-nav-list-item {
		border-right: 1px rgba(0, 0, 0, 0.6);
		flex-grow: 1;
		justify-content: center;
		align-self: stretch;
		padding: 0;
		display: flex;
	}

	.header-menu-wrap {
		width: auto;
	}

	.nav-drop-toggle-link {
		text-align: left;
		padding-left: 1rem;
		display: block;
	}

	.two-part-button-wrap {
		width: 100%;
		max-width: none;
		float: none;
		border-bottom: 1px none var(--nav-hover-white);
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
		position: static;
	}

	.two-part-button-wrap:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.mobile-bar-wrap {
		height: 50px;
		flex-grow: 1;
		justify-content: flex-end;
		align-items: center;
		display: none;
	}

	.logo-image {
		width: 150px;
		display: block;
		transform: translate(0, -70px);
	}

	.secondary-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
		margin-bottom: 0;
	}

	.secondary-nav-icon.account {
		width: 1.25rem;
		height: 1.25rem;
	}

	.menu-button-icon {
		width: 100%;
		float: left;
		flex: 100%;
		align-self: center;
		font-size: 2.25rem;
		line-height: 4.5rem;
		display: none;
	}

	.secondary-nav-list-link {
		color: #000;
		flex-grow: 1;
		justify-content: center;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-left: 1rem;
		padding-right: 1rem;
		display: flex;
	}

	.secondary-nav-list-link:hover {
		color: #000;
		background-color: rgba(0, 0, 0, 0);
	}

	.secondary-nav-list-link.account {
		display: flex;
	}

	.two-part-button-list {
		z-index: 150;
		width: 100%;
		background-color: var(--midnight-blue);
		margin-left: 100%;
		padding-bottom: 100vh;
		display: block;
		position: absolute;
		top: 0;
		left: 0;
	}

	.nav-drop-toggle {
		display: block;
	}

	.menu-button-text {
		float: left;
		letter-spacing: 0.02em;
		font-size: 1rem;
		font-weight: 800;
		line-height: 4.5rem;
		display: none;
	}

	.nav-bar-link {
		color: var(--white);
		border-bottom: 1px solid rgba(255, 255, 255, 0.25);
		justify-content: flex-start;
		padding-left: 1.5rem;
		display: flex;
	}

	.nav-bar-link:hover {
		background-color: var(--royal-blue-2);
		color: var(--white);
	}

	.header-bar-column {
		width: auto;
		flex: 0 auto;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.header-bar-column.header-bar-left-column {
		width: auto;
		flex: 0 auto;
		margin-right: auto;
		padding: 0;
		display: none;
	}

	.header-mobile-search-reveal {
		height: 3rem;
		min-height: 3rem;
		flex-grow: 1;
		overflow: hidden;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 3rem;
		flex: 1;
		font-size: 0.875em;
	}

	.header-bar-content-wrap {
		flex-flow: wrap;
	}

	.button-2.header-search-button {
		width: 3.5rem;
		height: 3rem;
		background-color: #000;
	}

	.header-bar-wrap {
		align-items: flex-start;
		display: flex;
	}

	.header-search-wrap {
		flex-grow: 1;
		margin-left: 0;
		padding: 1rem;
		box-shadow: inset 0 1px rgba(255, 255, 255, 0.15), inset 0 -2px #3128b1;
	}

	.header-bar-wrapper {
		border-bottom-color: rgba(51, 51, 51, 0.15);
		padding: 0 0 0 2rem;
	}

	.nav-bar-dropdown {
		padding-left: 0;
		display: none;
	}

	.text-block-2 {
		color: var(--white);
	}

	.content-heading.projects {
		margin-bottom: 20px;
		font-size: 2.25rem;
		text-decoration: none;
	}

	.paragraph-5.hero-image {
		margin-bottom: 20px;
	}

	.project-block-content {
		width: auto;
		height: auto;
		flex-wrap: nowrap;
		justify-content: space-between;
		padding: 2rem;
		display: flex;
	}

	.div-block-68.projects {
		align-items: center;
	}

	.links-a {
		padding-top: 40px;
	}

	.links.project-1,
	.links._2,
	.links._3 {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
		text-decoration: none;
	}

	.div-block-65 {
		width: 5px;
		height: 75px;
	}

	.div-block-65.projetcs {
		width: 8px;
		height: 50px;
	}

	.project-block {
		width: 33.3%;
		height: 400px;
		flex-direction: column;
		padding-left: 0;
		padding-right: 0;
		display: flex;
		left: 0%;
	}

	.project-block.black {
		padding-left: 0;
		padding-right: 0;
	}

	.project-block.two {
		width: 66.7%;
		padding-left: 32px;
		padding-right: 32px;
		display: flex;
	}

	.arrow-button.projects {
		padding-left: 20px;
	}

	.content-headings-2 {
		text-align: center;
		text-indent: 0;
	}

	.content-headings-2.quote {
		justify-content: center;
		display: block;
	}

	.content-block-two.solid-background {
		align-items: center;
		padding: 2rem;
	}

	.form-2 {
		width: 200px;
	}

	.content-block {
		width: 32%;
		padding: 2rem 1rem;
	}

	.content-block-inner-content {
		width: 100%;
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
		left: 0%;
	}

	.form-style {
		width: 400px;
	}

	.content-block-wrapper {
		padding: 2rem;
	}

	.search-icon {
		min-height: 2.8rem;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-bar {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: none;
		top: 0;
	}

	.search-input {
		padding-top: 1.55rem;
	}

	.search-input-exit.two {
		padding-left: 0;
		padding-right: 0;
	}

	.testimonial-column {
		width: 100%;
		padding: 2rem 1rem;
	}

	.header-contents-wrap {
		padding-left: 2rem;
		padding-right: 2rem;
		display: flex;
	}

	.menu-nav-button {
		height: auto;
		float: right;
		box-shadow: none;
		color: #fff;
		text-align: center;
		text-transform: uppercase;
		background-color: rgba(0, 0, 0, 0);
		flex-shrink: 0;
		order: 1;
		justify-content: flex-start;
		padding: 0 0;
		font-family: Oswald, sans-serif;
		font-weight: 400;
		line-height: 4rem;
		transition: all 0.3s;
		display: block;
		top: auto;
	}

	.menu-nav-button:hover {
		color: rgba(233, 236, 227, 0.5);
	}

	.menu-nav-button.w--open {
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
	}

	.menu-button-icon-2 {
		float: left;
		color: var(--black);
		align-self: center;
		font-size: 2rem;
		line-height: 2rem;
	}

	.header-container,
	.header-container.top {
		justify-content: space-between;
	}

	.brand-2 {
		height: 45%;
	}

	.image-8 {
		transform: translate(0, -62px);
	}

	.nav-link-box-3 {
		display: block;
	}

	.nav-link-box-1 {
		text-align: right;
		align-items: flex-end;
		display: block;
	}

	.cart-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.menu-button-4 {
		background-color: rgba(0, 0, 0, 0);
		margin-left: 0;
		margin-right: 40px;
	}

	.menu-button-4.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.top-header-wrapper {
		display: flex;
	}

	.icon-wrapper {
		border-left-style: none;
		padding-left: 0;
	}

	.nav-link-box-2 {
		flex-direction: row;
		display: block;
	}

	.dropdown {
		text-align: center;
	}

	.account-icon {
		width: 100px;
		transform: translate(0, -30px);
	}

	.hamburger-icon {
		color: #005290;
	}

	.dropdown-toggle-2 {
		padding-top: 0;
		padding-bottom: 0;
		display: none;
	}

	.nav-link-3 {
		width: 100%;
		text-align: left;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 20px;
	}

	.nav-link-3:hover {
		background-color: rgba(0, 0, 0, 0);
		border-left: 6px #fff;
	}

	.nav-link-box-5 {
		width: auto;
		display: block;
	}

	.account-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.mobile-dropdown {
		flex-direction: row;
		display: block;
	}

	.nav-menu-2 {
		height: auto;
		color: #fff;
		background-color: #005290;
		border: 1px #000;
		border-left: 1px solid rgba(255, 255, 255, 0.38);
		margin-top: 90px;
		margin-right: 0;
		top: 0%;
		bottom: 0%;
		left: auto;
		right: 0%;
	}

	.left-nav-list-content-wrap {
		height: 0;
		overflow: hidden;
	}

	.image-block {
		width: 65%;
	}

	.inside-page-header-wrap {
		margin-bottom: 1rem;
		padding-top: 0;
		padding-bottom: 1rem;
	}

	.main-content-column {
		padding-left: 0;
		padding-right: 0;
	}

	.inside-page-background-image {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.content-container,
	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0;
	}

	.inside-content-beckground {
		max-width: 100%;
		padding: 1.5rem 40px 2rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.left-nav-list-content-wrap-2 {
		height: 0;
		overflow: hidden;
	}

	.div-block-140 {
		width: 65%;
	}

	.inside-content-beckground-2 {
		max-width: 100%;
		padding: 1.5rem 40px 2rem;
	}

	.inside-page-header-content-wrap-3 {
		margin-bottom: 1rem;
	}

	.right-sidebar-column {
		border-top: 3px #48bdd7;
		margin-top: 0;
		padding-top: 0;
	}

	.right-sidebar-column.right-sidebar {
		border-top-style: none;
	}

	.inside-page-background-image-2 {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.content-block-2,
	.content-block-3 {
		width: 32%;
		padding: 2rem 1rem;
	}

	.icon-6,
	.icon-7 {
		margin-top: auto;
	}

	.nav-bar-invisible-scroll-block {
		height: 89px;
	}

	.mobile-arrow {
		color: #fff;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
		font-family: Montserrat, sans-serif;
		font-weight: 500;
	}

	.main-nav-link {
		text-align: left;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	}

	.main-nav-link.mobile-duplicate {
		width: 88%;
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
		border-bottom-style: none;
		justify-content: space-between;
		padding: 10px 20px;
		font-size: 1rem;
		font-weight: 300;
		display: block;
	}

	.main-nav-link.mobile-duplicate:hover {
		background-color: rgba(0, 0, 0, 0);
		background-image: none;
		transform: none;
	}

	.main-nav-link.mobile-duplicate.w--current {
		color: #f9b604;
		transform: scale(1);
		box-shadow: inset 6px 0 #f9b604;
	}

	.search-icon-2 {
		margin-top: 10px;
	}

	.search-icon-2.mobile {
		margin-top: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
	}

	.nav-menu-3 {
		width: 100%;
		background-color: #333;
		flex: 1;
		justify-content: flex-start;
		margin: 0;
		display: block;
		position: absolute;
		left: auto;
		right: auto;
	}

	.nav-link-5 {
		float: none;
		color: #fff;
		text-align: left;
		padding-bottom: 10px;
		padding-left: 30px;
		display: block;
	}

	.nav-link-5.mobileonly {
		color: #fff;
		background-color: rgba(0, 0, 0, 0);
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
		margin-left: 0;
		padding-bottom: 10px;
		padding-left: 20px;
		font-family: Montserrat, sans-serif;
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.25rem;
		display: block;
	}

	.nav-link-5.mobileonly:hover {
		color: #fff;
		background-color: rgba(22, 22, 22, 0.5);
	}

	.nav-link-5.mobileonly.account {
		display: none;
	}

	.icon-8 {
		color: #fff;
	}

	.logo-3 {
		flex-direction: column;
		flex-shrink: 0;
		order: -1;
		align-items: flex-start;
		margin: 0 20px;
		padding-left: 18px;
		display: flex;
	}

	.two-part-mobile-nav-wrapper {
		border-top: 1px #000;
		border-bottom: 1px solid rgba(77, 76, 76, 0.25);
		justify-content: space-between;
		display: flex;
	}

	.two-part-mobile-nav-wrapper.top {
		border-top-style: none;
		border-top-color: rgba(77, 76, 76, 0.25);
		border-bottom-color: rgba(77, 76, 76, 0.25);
		justify-content: space-between;
		font-family: Montserrat, sans-serif;
	}

	.two-part-mobile-nav-wrapper.top:hover {
		background-color: rgba(22, 22, 22, 0.5);
	}

	.search-content {
		width: 40vw;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		margin: 100px 40px 0 0%;
	}

	.search-button-icon {
		margin-top: 5px;
	}

	.navigation {
		justify-content: flex-end;
		align-items: center;
		margin-left: 0;
		padding-left: 0%;
		padding-right: 0%;
		display: flex;
	}

	.mobile-menu-arrow {
		width: 12%;
		color: #000;
		border-left: 1px solid rgba(77, 76, 76, 0.25);
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.mobile-menu-arrow:hover {
		background-color: rgba(255, 255, 255, 0.1);
	}

	.icon-wrapper-3 {
		justify-content: flex-end;
		margin-left: auto;
		display: flex;
		position: static;
		left: auto;
		right: 90px;
	}

	.nav-holder {
		display: none;
	}

	.account {
		margin-left: 0;
	}

	.menu-button-5 {
		color: #4d4c4c;
		margin-left: 0;
		margin-right: 20px;
		font-size: 2rem;
	}

	.menu-button-5.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.slider-inner-content {
		margin-top: 40px;
	}

	.right-block {
		width: 100%;
		margin-right: auto;
		display: flex;
		top: 33%;
	}

	.div-block-149 {
		width: auto;
		border: 1px #000;
		align-items: center;
	}

	.left-block {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		position: relative;
	}

	.background-container {
		justify-content: center;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		top: -26%;
	}

	.slider-3 {
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.section-container {
		height: 140vh;
	}

	.mask {
		overflow: visible;
	}

	.div-block-150 {
		text-align: center;
		font-size: 1vh;
	}

	.image-4 {
		top: -59%;
		left: auto;
	}

	.image-container {
		width: 50%;
		position: absolute;
		bottom: 756.047px;
		left: auto;
		transform: none;
	}

	.item-right {
		width: 80%;
		height: auto;
		flex-direction: column;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 10px;
		padding-left: 20px;
		padding-right: 20px;
		display: flex;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.div-block-152 {
		flex-direction: column;
		align-items: center;
	}

	.heading {
		text-align: center;
		font-size: 3vh;
		line-height: 1;
	}

	.text-block-6 {
		font-size: 3vh;
		line-height: 1;
	}

	.nav-bar-dropdown-copy {
		padding-left: 0;
		display: none;
	}

	.top-header-links {
		color: var(--midnight-blue-2);
		border-top: 1px #000;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		justify-content: flex-start;
	}

	.top-header-links:hover {
		background-color: var(--background-color);
		color: var(--tomato);
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
	}

	.top-header-links.last {
		color: var(--midnight-blue-2);
	}

	.top-header-links.last:hover {
		color: var(--tomato);
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.top-header-links.first {
		border-top-style: solid;
		border-top-color: rgba(0, 0, 0, 0.1);
		border-bottom-color: rgba(51, 51, 51, 0.05);
	}

	.top-header-links.first:hover {
		background-color: var(--background-color);
	}

	.careers-link {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.main-button-2 {
		display: none;
	}

	.wrapper-2 {
		justify-content: center;
	}

	.contact-us {
		transition: background-color 0.4s;
	}

	.login-button-main {
		background-image: url('/assets/images/lock.svg');
		background-repeat: no-repeat;
		background-size: 20px;
		margin-right: 0;
		display: none;
	}

	.top-nav {
		padding-left: 40px;
		padding-right: 61px;
		position: relative;
	}

	.div-block-21,
	.div-block-153 {
		z-index: 115;
		height: 40px;
		background-color: #1a1a1a;
		flex-direction: row;
		padding-left: 5%;
		padding-right: 5%;
		display: none;
		position: fixed;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: 50rem;
		margin-bottom: 1rem;
		display: flex;
	}

	.uui-navbar02_container-2 {
		justify-content: flex-end;
	}

	.menu-icon_line-middle {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		justify-content: center;
		align-items: center;
		margin-top: 6px;
		margin-bottom: 6px;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.search-bar-2 {
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		display: block;
	}

	.dropdown-link-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.dropdown-link-3:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.uui-navbar02_dropdown-content-left-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.menu-icon_line-top {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.uui-navbar02_dropdown-content-2 {
		flex-direction: column;
	}

	.menu-icon_line-middle-inner-3 {
		width: 4px;
		height: 0;
		padding-bottom: 0;
		padding-right: 0;
	}

	.uui-navbar02_menu-left-2 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.uui-navbar02_dropdown-content-button-wrapper {
		margin-bottom: 0;
		margin-left: 0;
	}

	.login-button-main-mobile {
		background-position: 20%;
		margin-right: 10px;
		font-size: 14px;
		font-weight: 500;
		display: none;
	}

	.uui-navbar02_dropdown-right-overlay-absolute {
		min-width: 100%;
		border-radius: 0.5rem;
	}

	.uui-navbar02_menu-dropdown {
		width: 100%;
		position: relative;
	}

	.brand-3 {
		margin-right: auto;
	}

	.uui-navbar02_dropdown-list-2 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-2.w--open {
		min-width: auto;
		box-shadow: none;
		border-style: none;
		align-items: flex-start;
		margin-bottom: 1.5rem;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.menu-icon_line-bottom-3 {
		width: 24px;
		height: 2px;
		background-color: #fff;
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.menu-icon_component-2 {
		width: 48px;
		height: 48px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 10px;
		margin-right: -0.5rem;
		padding-bottom: 0;
		padding-right: 0;
		display: flex;
	}

	.uui-navbar02_blog-content {
		max-width: none;
	}

	.nav-link-6 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-6:hover {
		color: #101828;
	}

	.uui-dropdown-icon {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-icon-3 {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon-3:hover {
		background-size: 17px;
	}

	.uui-navbar02_dropdown-toggle-2 {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-2:hover {
		color: #101828;
	}

	.mega-menu {
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.uui-navbar02_menu-2 {
		height: 100vh;
		-webkit-text-fill-color: inherit;
		background-color: #fff;
		background-clip: border-box;
		border-top: 1px solid #f2f4f7;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.nav-link-enquire {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-enquire:hover {
		color: #101828;
	}

	.search-input-3 {
		padding-top: 1.55rem;
	}

	.uui-navbar02_menu-button-3 {
		display: flex;
	}

	.uui-navbar02_menu-button-3.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.content-3.mod--categories {
		height: auto;
		max-width: none;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		flex-direction: row;
		grid-template-rows: auto auto;
		grid-template-columns: 0.75fr 1fr 0.75fr;
		grid-auto-columns: 1fr;
		margin-left: 0;
		margin-right: 0;
		display: grid;
	}

	.search-icon-4 {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon-4:hover {
		background-size: 17px;
	}

	.uui-dropdown-icon-2 {
		color: #667085;
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.brand-4 {
		margin-right: auto;
	}

	.uui-navbar02_menu-3 {
		height: 100vh;
		-webkit-text-fill-color: inherit;
		background-color: #fff;
		background-clip: border-box;
		border-top: 1px solid #f2f4f7;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.mega-menu-2 {
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.nav-link-7 {
		width: 100%;
		color: var(--beige-2);
		padding: 0.75rem 0;
		font-size: 15px;
	}

	.nav-link-7:hover {
		color: var(--beige-2);
	}

	.uui-navbar02_dropdown-list-3 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-3.w--open {
		min-width: auto;
		box-shadow: none;
		border-style: none;
		align-items: flex-start;
		margin-bottom: 1.5rem;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.nav-link-enquire-2 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-enquire-2:hover {
		color: #101828;
	}

	.uui-navbar02_menu-left-3 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-3 {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-size: 1.125rem;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-3:hover {
		color: #101828;
	}

	.search-input-4 {
		padding-top: 1.55rem;
	}

	.section-slider {
		margin-top: 83px;
	}

	.slider-4 {
		height: 330px;
	}

	.div-block-155 {
		margin-top: 40px;
		margin-bottom: 40px;
	}

	.padding-section-medium {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.div-block-156 {
		height: auto;
	}

	.news-section {
		padding-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
	}

	.heading-22 {
		color: #374957;
		font-size: 18px;
	}

	.text-block-6-copy {
		font-size: 3vh;
		line-height: 1;
	}

	.link-text-3 {
		width: 100%;
		flex: none;
	}

	.button-copy.training-session,
	.button-2b.training-session,
	.button-3b.training-session {
		position: static;
	}

	.content-wrap-left {
		padding-left: 0;
		padding-right: 0;
	}

	.footer-section-01 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.content-wrap-left-03 {
		margin-top: 30px;
		padding-left: 0;
		padding-right: 0;
	}

	.nav-inner {
		padding: 10px 20px;
	}

	.text-field-01 {
		width: 100%;
	}

	.content-grid-03 {
		width: auto;
		max-width: none;
		grid-column-gap: 0px;
		grid-row-gap: 50px;
		grid-template-columns: 1fr 1fr;
		justify-items: center;
	}

	.content-section-03 {
		background-position: 71%;
		padding: 40px 20px;
	}

	.content-left-inner-03 {
		margin-bottom: 20px;
		margin-left: 80px;
	}

	.section-title {
		margin-right: 20px;
	}

	.clients-section-01 {
		margin-left: auto;
		margin-right: auto;
		padding: 20px;
	}

	.cards-section-01 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.menu-button-6 {
		padding: 12px;
	}

	.menu-button-6.w--open {
		background-color: #fff;
	}

	.newsletter-form-01 {
		width: 100%;
		justify-content: flex-end;
	}

	.hero-content-01 {
		align-items: center;
		padding-left: 20px;
		padding-right: 20px;
	}

	.clients-inner-01 {
		grid-template-columns: 1fr 1fr;
	}

	.pricing-section-01 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.button-6.nav {
		display: none;
	}

	.content-section-01 {
		padding: 20px;
	}

	.form-section-inner-02 {
		width: 100%;
		justify-content: center;
	}

	.content-section-inner-01 {
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
	}

	.nav-wrapper-01 {
		top: 0;
	}

	.nav-menu-4 {
		background-color: #fff;
		box-shadow: 0 14px 13px -3px rgba(0, 0, 0, 0.11);
	}

	.nexsletter-form-block-01 {
		width: 55%;
		justify-content: flex-end;
		margin-left: 0;
		display: flex;
	}

	.hamburger {
		width: 40px;
		border: 1px solid var(--white-smoke);
		background-color: var(--white-smoke);
		padding-top: 3px;
	}

	.nav-link-8 {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.border-effect {
		display: none;
	}

	.big-image-form-02 {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.tabs-section-01 {
		background-position: 0%;
	}

	.primary {
		margin-left: 0;
		margin-right: 0;
		font-size: 14px;
	}

	.primary.nav,
	.button-6-copy.nav,
	.secondary.nav {
		display: none;
	}

	.column-2 {
		flex-direction: column;
		align-items: flex-end;
		padding-right: 0;
	}

	.footer-signoff-links {
		margin-left: 0;
		margin-right: 0.75rem;
	}

	.signoff-div {
		padding-bottom: 0.25rem;
	}

	.grip-logo-link {
		float: right;
		margin-bottom: 1rem;
		margin-left: 0;
		margin-right: 0;
	}

	.footer-signoff-link-wrap {
		float: left;
		margin-bottom: 1.5rem;
		display: block;
	}

	.column-3 {
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.footer-copyright {
		float: left;
		margin-bottom: 0.25rem;
		margin-right: 0;
	}

	.sign-off-div-wrap {
		width: 100%;
		min-width: auto;
	}

	.property-slider-widget-2 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.btn-primary-2 {
		padding-top: 16px;
		padding-bottom: 16px;
	}

	.icon-10 {
		color: #8b8b8b;
		font-size: 2.15rem;
	}

	.property-spotlight-mask-2 {
		width: 100%;
		flex-wrap: nowrap;
	}

	.property-slider-arrow-link-4 {
		width: 60px;
		height: 60px;
		box-shadow: none;
		border-width: 6px;
		justify-content: center;
		margin-left: 0;
		margin-right: 1.75rem;
		bottom: 0;
	}

	.property-slider-arrow-link-4:hover {
		box-shadow: none;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		box-shadow: none;
		margin-right: 0;
		top: 0%;
		bottom: 0%;
		left: auto;
		right: 0%;
	}

	.property-slider-arrow-link-4.property-arrow-right:hover {
		box-shadow: none;
	}

	.property-spotlight-slide-2 {
		width: 49%;
		background-image: none;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		font-size: 1.25rem;
	}

	.content-section-inner-01-copy {
		grid-template-rows: auto auto;
		grid-template-columns: 1fr;
	}

	.subscribe-wrapper {
		margin-left: 0;
	}

	.button-6-email.nav {
		display: none;
	}

	.grid-3-columns {
		grid-template-columns: 1fr 1fr;
	}

	.display-2 {
		font-size: 58px;
		line-height: 70px;
	}

	.wrapper-3 {
		justify-content: center;
	}

	.div-block-161 {
		z-index: 115;
		height: 40px;
		background-color: #1a1a1a;
		flex-direction: row;
		padding-left: 5%;
		padding-right: 5%;
		display: none;
		position: fixed;
	}

	.search-icon-5 {
		width: 40px;
		height: 40px;
		min-height: auto;
		background-size: 17px;
		padding-top: 0;
		padding-bottom: 0;
	}

	.search-icon-5:hover {
		background-size: 17px;
	}

	.nav-items-container {
		justify-content: flex-end;
	}

	.login-button-main-mobile-2 {
		background-position: 20%;
		margin-right: 10px;
		font-size: 14px;
		font-weight: 500;
		display: none;
	}

	.menu-icon_line-top-2 {
		width: 24px;
		height: 2px;
		background-color: var(--white);
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.text-block-48 {
		color: var(--beige-2);
		font-size: 15px;
	}

	.text-block-48:hover {
		color: var(--beige-2);
	}

	.nav-link-copy {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy:hover {
		color: #101828;
	}

	.menu-icon_line-bottom-4 {
		width: 24px;
		height: 2px;
		background-color: var(--white);
		border-radius: 1rem;
		padding-bottom: 0;
		padding-right: 0;
	}

	.uui-navbar02_menu-button-4 {
		display: flex;
	}

	.uui-navbar02_menu-button-4.w--open {
		background-color: rgba(0, 0, 0, 0);
	}

	.mega-desktop {
		color: #1d1d1b;
		display: block;
		top: 0;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.uui-navbar02_item-heading-4 {
		color: var(--beige-2);
		font-family: Aleo, sans-serif;
		font-size: 14px;
		line-height: 24px;
	}

	.uui-navbar02_item-heading-4:hover {
		color: var(--beige-2);
	}

	.uui-navbar02_menu-4 {
		height: 90vh;
		background-color: var(--dark-brown);
		-webkit-text-fill-color: inherit;
		background-clip: border-box;
		align-items: flex-start;
		margin-left: 0;
		padding: 1.5rem 2rem 5rem;
		position: absolute;
		overflow: auto;
	}

	.nav-link-9 {
		width: 100%;
		color: var(--beige-2);
		padding: 0.75rem 0;
		font-size: 15px;
	}

	.nav-link-9:hover {
		color: var(--beige-2);
	}

	.uui-navbar02_menu-left-4 {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		color: #101828;
		flex-direction: column;
		align-items: flex-start;
		display: flex;
	}

	.nav-link-copy-2 {
		width: 100%;
		color: #101828;
		padding: 0.75rem 0;
		font-size: 1.125rem;
	}

	.nav-link-copy-2:hover {
		color: #101828;
	}

	.uui-navbar02_dropdown-list-4 {
		position: relative;
		overflow: hidden;
	}

	.uui-navbar02_dropdown-list-4.w--open {
		min-width: auto;
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		border-style: none;
		align-items: flex-start;
		margin-bottom: 0;
		padding: 0 0 0;
		position: relative;
		top: 0;
		left: 0;
		right: 0;
	}

	.uui-dropdown-icon-3 {
		color: var(--beige-2);
		margin-top: 0;
		margin-bottom: 0;
		margin-right: 0;
		top: auto;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.uui-navbar02_dropdown-toggle-4 {
		color: #101828;
		align-items: center;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0;
		font-family: Exo, sans-serif;
		font-size: 1.125rem;
		line-height: 24px;
		display: flex;
	}

	.uui-navbar02_dropdown-toggle-4:hover {
		color: #101828;
	}

	.uui-navbar02_menu-dropdown-2 {
		width: 100%;
		background-color: rgba(0, 0, 0, 0);
		display: block;
		position: relative;
	}

	.div-block-153-old {
		z-index: 115;
		height: 40px;
		background-color: #1a1a1a;
		flex-direction: row;
		padding-left: 5%;
		padding-right: 5%;
		display: none;
		position: fixed;
	}

	.button-6-slider-midle {
		text-align: center;
		margin-left: 0;
		margin-right: 0;
		font-size: 14px;
	}

	.button-6-slider-midle.nav,
	.button-6-copy.nav,
	.button-6-copy.nav {
		display: none;
	}

	.nav-dropdown-container-2-copy.w--open {
		max-width: none;
		display: block;
		top: 0%;
		left: 0;
	}

	.mobile-arrow-4 {
		color: #5b5b5b;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.nav-sub-sub {
		width: 100%;
		height: auto;
		background-color: #fff;
		display: none;
		position: static;
	}

	.nav-dropdown-wrapper-sub-sub {
		width: 100%;
		background-color: var(--dark-brown);
		border-bottom-style: none;
		flex: 0 auto;
		display: flex;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.nav-dropdown-wrapper-sub-sub:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.nav-dropdown-wrapper-sub-sub.w--open {
		background-color: var(--dark-brown);
	}

	.nav-dropdown-container-wrap-2-copy {
		background-color: rgba(0, 0, 0, 0);
	}

	.mobile-menu-arrow-4 {
		width: 12%;
		height: 47px;
		background-color: var(--dark-brown);
		color: #000;
		border-bottom: 1px solid rgba(209, 184, 166, 0.15);
		border-left: 1px solid rgba(209, 184, 166, 0.15);
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.mobile-menu-arrow-4:hover,
	.nav-links-2 {
		background-color: rgba(0, 0, 0, 0);
	}

	.icon-6-copy {
		display: none;
	}

	.mobile-arrow-5 {
		color: #5b5b5b;
		margin-left: 1.25rem;
		margin-right: 1.25rem;
	}

	.nav-2 {
		width: 100%;
		height: auto;
		background-color: #fff;
		display: none;
		position: static;
	}

	.nav-dropdown-wrapper {
		width: 100%;
		border-bottom-style: none;
		flex: 0 auto;
		display: flex;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.nav-dropdown-wrapper:hover {
		border-bottom-style: none;
		box-shadow: inset -4px 0 rgba(0, 0, 0, 0);
	}

	.icon-11 {
		display: none;
	}

	.nav-dropdown-link-4 {
		width: 90%;
		height: 47px;
		float: left;
		color: #3f3e3e;
		text-align: left;
		border-style: none none solid;
		border-bottom-width: 1px;
		border-bottom-color: rgba(4, 4, 4, 0.05);
		margin-bottom: 0;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 1.5rem;
		font-weight: 500;
		display: block;
	}

	.nav-dropdown-link-4:hover {
		color: #3f3e3e;
		background-color: rgba(0, 0, 0, 0);
		border-style: none none solid;
		border-bottom-color: rgba(255, 255, 255, 0.15);
		box-shadow: inset 4px 0 #3f3e3e;
	}

	.secondary-nav-list-2 {
		order: 1;
		margin-top: 0;
		margin-bottom: 0;
	}

	.secondary-nav-list-item-2 {
		border-left-width: 1px;
		border-left-color: rgba(0, 0, 0, 0.25);
		flex-grow: 1;
		flex-basis: auto;
		align-self: stretch;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
	}

	.secondary-nav-list-link-2 {
		color: #fff;
		align-items: center;
		margin-left: 0;
		margin-right: 0;
		padding-top: 0.22rem;
		padding-left: 1rem;
		padding-right: 1.25rem;
		font-size: 1.125rem;
		font-weight: 400;
		display: flex;
	}

	.secondary-nav-list-link-2:hover {
		color: #fff;
		background-color: rgba(0, 0, 0, 0.25);
		transform: none;
	}

	.secondary-nav-list-link-2:active {
		background-color: rgba(0, 0, 0, 0);
	}

	.secondary-nav-list-link-2:focus {
		color: #fff;
	}

	.secondary-nav-icon-2 {
		width: 1.375rem;
		height: 1.375rem;
		background-color: rgba(0, 0, 0, 0);
		margin-top: -0.1875em;
		margin-right: 0.5rem;
		padding: 2px;
	}

	.header-search-wrap-2 {
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		margin-left: 0;
		padding: 0 0 0;
		transition: all 0.35s;
		display: none;
	}

	.header-search-form-2 {
		width: 60%;
		padding: 0;
		transition: all 0.35s;
		position: relative;
	}

	.form-field-2.header-search-field {
		width: 70%;
		height: 2.875rem;
		flex: 100%;
		padding-left: 1em;
		font-size: 0.8125rem;
	}

	.form-field-2.header-search-field:focus {
		width: 280px;
		min-width: 280px;
	}

	.button-7.header-search-button {
		width: 3rem;
		height: 2.875rem;
		opacity: 0.65;
		font-size: 1rem;
		position: absolute;
		right: 0;
	}

	.button-7.header-search-button:hover {
		opacity: 1;
	}

	.header-search-wrap-2-mobile {
		box-shadow: none;
		background-color: rgba(0, 0, 0, 0);
		margin-top: 20px;
		margin-left: 0;
		padding: 0 0 0;
		transition: all 0.35s;
		display: flex;
	}

	.nav-link-9-mobile {
		width: 100%;
		color: var(--beige-2);
		padding: 0.75rem 0;
		font-size: 15px;
		display: flex;
	}

	.nav-link-9-mobile:hover {
		color: var(--beige-2);
	}

	.nav-link-9-copy {
		width: 100%;
		color: var(--beige-2);
		padding: 0.75rem 0;
		font-size: 15px;
	}

	.nav-link-9-copy:hover {
		color: var(--beige-2);
	}

	.button-6-slider-new.nav {
		display: none;
	}

	.content-left-inner-03-copy {
		margin-bottom: 20px;
		margin-left: 80px;
	}

	.tabs-section-01-copy {
		background-position: 0%;
	}

	.main-content-wrapper-2 {
		width: 100%;
		padding-top: 0;
	}

	.heading-5 {
		font-size: 38px;
	}

	.heading-3 {
		color: var(--maroon-2);
	}

	.left-nav-list-2 {
		margin-bottom: 1.5em;
	}

	.heading-1 {
		color: var(--maroon-2);
		font-size: 38px;
	}

	.heading-30 {
		font-size: 20px;
	}

	.left-col-item-wrap {
		margin-left: 0;
	}

	.left-content-column {
		width: 100%;
		margin-top: 1.5rem;
		margin-left: 0%;
		display: block;
	}

	.left-content-column.show {
		margin-right: 0;
	}

	.inside-page-content-wrap-no-sidebars-2 {
		margin-left: 0%;
		margin-right: 0%;
	}

	.container-6 {
		max-width: none;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-nav-wrapper-2 {
		height: 0;
		overflow: hidden;
	}

	.content-wrapper-2 {
		flex-direction: column;
		position: relative;
	}

	.left-nav-col-2 {
		display: none;
	}

	.main-content-wrapper-3 {
		width: 100%;
		padding-top: 0;
	}

	.heading-34 {
		font-size: 38px;
	}

	.heading-34.heading-5-inside-pages {
		color: var(--maroon-2);
	}

	.left-nav-list-3 {
		margin-bottom: 1.5em;
	}

	.heading-36 {
		font-size: 38px;
		text-decoration: none;
	}

	.heading-37 {
		color: var(--maroon-2);
		font-size: 20px;
	}

	.heading-38 {
		color: var(--maroon-2);
	}

	.container-7 {
		max-width: none;
		padding-left: 20px;
		padding-right: 20px;
	}

	.container-7-outer {
		padding-top: 120px;
	}

	.heading-39 {
		color: var(--maroon-2);
	}

	.button-main {
		margin-left: 0;
		margin-right: 0;
		font-size: 14px;
	}

	.button-main.nav,
	.button-secondary.nav {
		display: none;
	}

	.right-col-item-wrap {
		margin-left: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.heading-42 {
		font-size: 20px;
	}

	.spark-brand-logo {
		padding-bottom: 10px;
	}

	.image-40,
	.button-tertiary.nav,
	.button-6-slider-new-copy.nav {
		display: none;
	}

	.cart-icon {
		margin-left: 275px;
		margin-right: 0;
	}

	.cart-icon.mobile {
		background-image: url('/assets/images/shopping-cart.svg');
		background-size: 30px;
		justify-content: flex-end;
		align-items: center;
		margin-top: 10px;
		margin-left: 20px;
		margin-right: 10px;
		display: block;
	}

	.account-2.mobile {
		background-image: url('/assets/images/user_1.svg');
		background-size: 25px;
		justify-content: flex-end;
		align-items: center;
		margin-top: 10px;
		margin-left: 0;
		display: block;
	}
}

@media screen and (max-width: 767px) {
	.home-content-boxes {
		height: auto;
		flex-wrap: wrap;
	}

	.div-block {
		height: 250px;
		flex-wrap: nowrap;
	}

	.div-block-2,
	.div-block-3 {
		height: 250px;
	}

	.slide-heading {
		width: 80%;
		justify-content: center;
		align-items: center;
		font-size: 2rem;
		line-height: 2.2rem;
		display: block;
	}

	.slide-inner-content {
		padding: 2rem 4rem;
	}

	.text-block.hero-image {
		width: 80%;
		margin-bottom: 32px;
		display: none;
	}

	.content-headings {
		margin-top: 0;
		margin-bottom: 0.5rem;
	}

	.div-block-9 {
		height: 250px;
		flex-wrap: nowrap;
	}

	.div-block-10 {
		height: 250px;
	}

	.home-content-section.trainer {
		height: auto;
		position: static;
	}

	.home-content-section.reviews {
		height: auto;
		flex-wrap: wrap;
		display: flex;
	}

	.home-content-section.banner.image {
		height: auto;
		overflow: hidden;
	}

	.section-content {
		width: 100%;
		height: auto;
		padding: 2rem;
	}

	.section-content.contact-call-out {
		padding-top: 4rem;
		padding-bottom: 4rem;
	}

	.div-block-12 {
		height: 550px;
	}

	.div-block-16 {
		flex-flow: wrap;
		align-content: stretch;
		justify-content: space-between;
		align-items: stretch;
		display: flex;
	}

	.review-block {
		height: auto;
		padding-left: 20px;
		padding-right: 20px;
		position: static;
	}

	.review-block.two,
	.review-block.three {
		height: auto;
		position: static;
	}

	.rights-container-block {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.footer-column-rights {
		width: 70%;
	}

	.grip-link {
		width: 30%;
	}

	.div-block-37 {
		display: none;
	}

	.footer-nav-list-link {
		margin-top: 0.25rem;
		margin-left: 0;
	}

	.footer-nav-list-link:hover {
		transform: none;
	}

	.footer-right-col-wrap {
		width: 100%;
		border-top: 1px solid rgba(255, 255, 255, 0.4);
		flex-direction: column;
	}

	.container-block.footer-container {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.social-media-link-block {
		margin-right: 0.75rem;
	}

	.social-media-link-block.twitter,
	.social-media-link-block.youtube {
		border-left-style: solid;
	}

	.social-media-link-block.linkedin {
		border-left-style: solid;
		margin-right: 0;
	}

	.footer-nav-wrapper {
		width: 100%;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.footer-nav-item {
		margin-top: 0;
	}

	.footer-col-wrap {
		width: 60%;
		min-width: 200px;
		padding-right: 0;
	}

	.footer-col-wrap.footer-col-4 {
		width: 100%;
		min-width: 175px;
	}

	.footer-col-wrap.quick-links {
		width: 30%;
		min-width: 175px;
	}

	.footer-col-wrap.support {
		width: 60%;
		min-width: 200px;
	}

	.footer-col-wrap.connect {
		width: 20%;
		min-width: 175px;
		flex: 0 auto;
	}

	.social-outer-wrap {
		width: 100%;
		text-align: center;
		flex-direction: column;
		margin-top: 1.4rem;
		display: flex;
	}

	.social-media-wrapper {
		float: none;
		justify-content: flex-start;
		margin-bottom: 0;
		display: flex;
	}

	.multicol-wrap {
		border-top-style: none;
		flex-direction: column;
		margin-top: 0;
		padding-top: 0;
	}

	.google-translate-outer-wrap {
		width: 100%;
	}

	.footer-nav-list {
		margin-top: 0.55rem;
		margin-left: 0;
	}

	.div-block-42.reviews {
		margin-top: 75px;
		margin-bottom: 40px;
	}

	.icon-block {
		margin-left: 10px;
		margin-right: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.div-block-46 {
		right: 55%;
	}

	.secondary-nav-text.indicator-text {
		min-width: 14px;
		padding-bottom: 2px;
		line-height: 0.75rem;
	}

	.container-4 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.container-4.nav-container-wrap {
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.secondary-nav-list {
		height: auto;
		max-width: 84%;
		flex: auto;
		align-items: stretch;
	}

	.logo {
		height: 100%;
		align-self: stretch;
		margin: 0 0;
		padding-top: 0.5rem;
		padding-left: 0;
	}

	.menu-button-2 {
		width: 18%;
		height: 4rem;
		max-width: 16%;
		min-width: 4rem;
		text-align: center;
		flex: 1 0 16%;
		justify-content: center;
		padding-left: 0;
		padding-right: 0;
		font-size: 1.25rem;
		line-height: 4rem;
	}

	.two-part-button-toggle {
		width: 15%;
	}

	.nav-link-2.two-part-mobile {
		width: 85%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		flex: 1;
	}

	.mobile-bar-wrap {
		justify-content: flex-end;
		align-self: stretch;
		align-items: center;
		margin-top: 18px;
		display: flex;
	}

	.logo-image {
		width: 135px;
		margin-top: 0;
		margin-bottom: 0.25rem;
		transform: translate(0, -69px);
	}

	.secondary-nav-icon {
		width: 1.5rem;
		height: 1.5rem;
	}

	.secondary-nav-icon.account {
		width: auto;
	}

	.menu-button-icon {
		width: 100%;
		margin-left: auto;
		margin-right: auto;
		font-size: 2rem;
		line-height: 4rem;
	}

	.secondary-nav-list-link {
		margin-left: 0;
		margin-right: 1.5rem;
		padding-left: 0;
		padding-right: 0;
		display: flex;
	}

	.menu-button-text {
		font-size: 0.875rem;
		line-height: 3.5rem;
	}

	.header-bar-column.header-bar-left-column {
		font-size: 0.75rem;
		line-height: 1.5em;
	}

	.form-field.header-search-field {
		font-size: 1em;
	}

	.content-heading {
		font-size: 2.25rem;
		line-height: 2.25rem;
	}

	.content-heading.projects {
		margin-bottom: 15px;
		font-size: 1.8rem;
		line-height: 2rem;
	}

	.paragraph-5.hero-image {
		margin-bottom: 20px;
		font-size: 0.8rem;
		line-height: 1rem;
	}

	.project-block-content {
		height: auto;
		flex-flow: column;
		justify-content: flex-start;
		padding-bottom: 2rem;
	}

	.div-block-69.projects {
		padding-left: 15px;
	}

	.links-a {
		padding-left: 20px;
	}

	.links.project-1,
	.links._2 {
		padding-top: 15px;
		padding-left: 15px;
		padding-right: 15px;
	}

	.div-block-65.projetcs {
		width: 10px;
	}

	.project-block {
		width: 100%;
		height: 150px;
	}

	.project-block._2,
	.project-block.black._04 {
		width: 100%;
	}

	.project-block.two {
		padding-left: 18px;
		padding-right: 18px;
	}

	.arrow-button.projects {
		margin-top: 203px;
		padding-left: 20px;
	}

	.content-headings-2.quote {
		margin-top: 0;
		margin-bottom: 0;
		font-size: 1.5rem;
		line-height: 1.7rem;
	}

	.content-block-two {
		height: auto;
		padding: 6rem 0 0 8rem;
	}

	.content-block-two.solid-background {
		height: auto;
		align-items: center;
		display: block;
	}

	.div-block-72 {
		height: 400px;
		padding: 20px;
	}

	.form-2 {
		width: 150px;
	}

	.content-block {
		width: 100%;
		margin-bottom: 2rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.content-block:hover {
		background-size: auto, cover;
	}

	.content-block-inner-content {
		width: 100%;
		height: auto;
	}

	.form-style {
		width: 350px;
	}

	.testimonial-wrapper {
		flex-flow: column wrap;
		margin-bottom: 0;
	}

	.block-quote {
		margin-bottom: 1rem;
	}

	.search-bar {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
		display: none;
		top: 0;
	}

	.testimonial-column {
		width: 100%;
		margin-bottom: 2rem;
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.menu-nav-button {
		height: auto;
		flex-shrink: 0;
		align-items: center;
		padding-left: 0;
		padding-right: 0;
		display: block;
	}

	.menu-button-icon-2 {
		height: auto;
		align-items: center;
		font-size: 2rem;
		line-height: 2rem;
		display: block;
	}

	.header-container {
		align-items: center;
	}

	.header-container.top {
		justify-content: space-between;
	}

	.brand-2 {
		width: 300px;
		height: 36px;
		padding-left: 0;
		display: none;
	}

	.image-8 {
		transform: translate(0, -34px);
	}

	.mobile-brand.w--current {
		display: block;
	}

	.cart-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.menu-button-4 {
		margin-right: 20px;
	}

	.top-header-wrapper {
		display: flex;
	}

	.account-link {
		width: 1.5rem;
		height: 1.5rem;
	}

	.image-9 {
		width: 60px;
	}

	.inside-page-header-wrap {
		padding-left: 0;
		padding-right: 0;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.main-content-section {
		margin-right: 0;
	}

	.inside-page-background-image {
		min-height: 80px;
	}

	.content-container.threequarterwidth {
		padding-right: 0;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.inside-content-beckground {
		padding-bottom: 1.5rem;
		padding-left: 30px;
		padding-right: 30px;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-content-beckground-2 {
		padding-bottom: 1.5rem;
		padding-left: 30px;
		padding-right: 30px;
	}

	.inside-page-header-content-wrap-3 {
		padding-left: 0;
		padding-right: 0;
	}

	.h2-header-link-2 {
		font-size: 1.75rem;
	}

	.h3-header-link-2 {
		font-size: 1.5rem;
	}

	.h4-header-link-2 {
		font-size: 1.25rem;
	}

	.block-quote-3 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.inside-page-background-image-2 {
		min-height: 80px;
	}

	.sidebar-section-wrap {
		margin-left: 0;
	}

	.intro-paragraph-3,
	.h5-header-link-2 {
		font-size: 1.125rem;
	}

	.content-block-2,
	.content-block-3 {
		width: 100%;
		margin-bottom: 2rem;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.icon-6 {
		margin-right: 10px;
	}

	.icon-7 {
		margin-left: 10px;
	}

	.project-block-one {
		width: 100%;
		margin-bottom: 2rem;
		padding-top: 4rem;
	}

	.project-block-two {
		width: 100%;
		margin-bottom: 1rem;
		padding-top: 4rem;
	}

	.project-block-three {
		width: 100%;
		padding-top: 4rem;
	}

	.nav-bar-invisible-scroll-block {
		height: 87px;
	}

	.main-nav-link.mobile-duplicate {
		width: 84%;
		padding-left: 20px;
	}

	.search-icon-2.mobile {
		margin-top: 0;
		margin-left: 0;
	}

	.nav-menu-3 {
		margin-top: 0;
	}

	.logo-3 {
		justify-content: center;
		align-self: stretch;
		margin: 0 20px;
		padding-top: 0;
		padding-left: 18px;
		display: flex;
	}

	.search-content {
		width: 50vw;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		margin-top: 100px;
		margin-right: 40px;
		display: flex;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-button-icon {
		margin-left: auto;
	}

	.navigation {
		align-items: center;
	}

	.mobile-menu-arrow {
		width: 16%;
	}

	.icon-wrapper-3 {
		right: 90px;
	}

	.menu-button-5 {
		margin-left: 0;
	}

	.right-block {
		top: 23%;
	}

	.image-4 {
		top: -53%;
	}

	.button-4 {
		margin-right: 0;
	}

	.div-block-151 {
		flex-direction: column;
	}

	.item-right {
		bottom: -7%;
	}

	.heading {
		line-height: 1;
	}

	.main-button-2 {
		z-index: 460;
		background-color: rgba(0, 0, 0, 0);
		margin-top: -1px;
		margin-bottom: 1px;
		margin-right: 50px;
		position: relative;
		top: auto;
		bottom: 0%;
		left: 0%;
		right: 0%;
	}

	.location-link {
		margin-right: 0;
		padding-right: 0;
	}

	.wrapper-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: center;
	}

	.contact-us {
		margin-right: 0;
		padding-right: 0;
	}

	.login-button-main {
		font-size: 12px;
		display: none;
	}

	.link-text-2 {
		font-size: 0.85rem;
	}

	.top-nav {
		padding-left: 10px;
		padding-right: 10px;
		position: relative;
	}

	.div-block-21 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.search-2 {
		z-index: 460;
		margin-top: 20px;
		position: relative;
	}

	.div-block-153 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.uui-navbar02_dropdown-content-right-2 {
		padding: 2rem 1rem;
	}

	.search-bar-2 {
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.uui-button-link {
		font-size: 1rem;
	}

	.uui-button-link:hover {
		color: #6941c6;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-row-gap: 1.5rem;
		flex-direction: column;
		grid-template-rows: auto;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.login-button-main-mobile {
		font-size: 12px;
		display: block;
	}

	.uui-navbar02_dropdown-link-list {
		max-width: none;
		grid-row-gap: 0.5rem;
	}

	.uui-navbar02_blog-item {
		padding: 0;
	}

	.uui-navbar02_blog-item:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.nav-link-6 {
		font-size: 1rem;
	}

	.search-icon-3 {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.uui-button-link-gray-2 {
		font-size: 1rem;
	}

	.uui-button-link-gray-2:hover {
		color: #475467;
	}

	.uui-navbar02_dropdown-toggle-2 {
		font-size: 1rem;
	}

	.mega-menu {
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.uui-navbar02_menu-2 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.uui-button-row {
		align-self: stretch;
	}

	.nav-link-enquire {
		font-size: 1rem;
	}

	.heading-large {
		font-size: 2.5rem;
	}

	.search-icon-4 {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.uui-navbar02_menu-3 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.mega-menu-2 {
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.nav-link-7,
	.nav-link-enquire-2,
	.uui-navbar02_dropdown-toggle-3 {
		font-size: 1rem;
	}

	.padding-section-medium {
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.div-block-6 {
		margin-bottom: 0;
	}

	.features_grid {
		grid-template-columns: 1fr;
	}

	.news-section {
		z-index: 500;
		background-color: #fff;
		flex-wrap: wrap;
		padding-top: 20px;
		padding-bottom: 20px;
		position: relative;
	}

	.div-block-157 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.features_components {
		grid-row-gap: 2rem;
	}

	.div-block-159 {
		width: 80%;
	}

	.features_header {
		grid-row-gap: 2rem;
		grid-template-columns: 1fr;
	}

	.padding-global {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
	}

	.project-block-four {
		width: 100%;
		padding-top: 4rem;
	}

	.content-wrap-left {
		padding-left: 0;
		padding-right: 0;
	}

	.pricing-div-01 {
		grid-template-columns: 1fr;
	}

	.footer-section-01 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.content-wrap-left-03 {
		padding-left: 0;
		padding-right: 0;
	}

	.pricing-card-01._1 {
		border-right-style: solid;
	}

	.pricing-card-01._3 {
		border-left-style: solid;
	}

	.grid-sec-1 {
		margin-bottom: 40px;
	}

	.content-grid-03 {
		grid-column-gap: 20px;
		grid-row-gap: 20px;
	}

	.content-section-03 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.content-left-inner-03 {
		margin-left: 40px;
		margin-right: 20px;
	}

	.section-title {
		font-size: 20px;
	}

	.card-01-grid {
		grid-template-columns: 1fr;
	}

	.brand-link.in-footer {
		padding-left: 0;
	}

	.clients-inner-01 {
		flex-wrap: wrap;
		justify-content: center;
	}

	.footer-01-div {
		grid-template-columns: 2fr;
	}

	.heading-1 {
		font-size: 35px;
		line-height: 45px;
	}

	.button-6 {
		font-size: 14px;
	}

	.content-section-01 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.client-logo-2 {
		width: 50%;
	}

	.content-section-inner-01 {
		width: auto;
		max-width: none;
	}

	.heading-2 {
		font-size: 14px;
	}

	.big-image-form-02 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.tabs-section-01 {
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('/assets/images/maroon_bg-2.svg');
		background-position: 0 0, 49% 0;
	}

	.container-3 {
		padding-left: 16px;
	}

	.grid-3 {
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
	}

	.primary {
		height: 100%;
		text-align: center;
		align-items: center;
		padding-top: 0;
		padding-bottom: 0;
		display: flex;
	}

	.primary.inside {
		height: auto;
		padding-top: 13px;
		padding-bottom: 13px;
		display: inline-block;
	}

	.text-block-37,
	.text-block-38,
	.text-block-39 {
		font-size: 14px;
	}

	.section-title-tap {
		font-size: 38px;
		line-height: 35px;
	}

	.in-tabs-image-3 {
		height: 180px;
		min-height: auto;
	}

	.section-title-about {
		margin-bottom: 20px;
		font-size: 38px;
		line-height: 38px;
	}

	.heading-2-copy-copy,
	.text-block-40,
	.text-block-41,
	.text-block-45 {
		font-size: 14px;
	}

	.column-2 {
		flex-direction: row;
		justify-content: center;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.grip-logo-link {
		float: none;
		margin-bottom: 0;
	}

	.footer-signoff-link-wrap {
		float: none;
		display: inline-block;
	}

	.column-3 {
		align-items: center;
		overflow: hidden;
	}

	.footer-copyright {
		float: none;
	}

	.property-slider-widget-2 {
		display: flex;
	}

	.badge-new {
		padding: 12px 10px;
	}

	.btn-primary-2 {
		padding: 20px 32px;
	}

	.paragraph-19 {
		font-size: 16px;
	}

	.icon-10 {
		color: #8b8b8b;
		font-size: 2.05rem;
	}

	.property-spotlight-mask-2 {
		width: 100%;
	}

	.badge-special-offer {
		padding: 12px 10px;
	}

	.property-slider-arrow-link-4 {
		box-shadow: none;
		background-color: #fff;
		border-width: 5px;
		flex-direction: column;
		margin-top: 200px;
		margin-left: 20px;
		margin-right: 20px;
		bottom: 610px;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		box-shadow: none;
		background-color: #fff;
		margin: 200px 20px auto;
		bottom: 610px;
	}

	.property-spotlight-slide-2 {
		width: 100%;
		margin-left: 0%;
		margin-right: 0%;
	}

	.grid-3-columns {
		grid-template-columns: 1fr;
	}

	.display-2 {
		font-size: 48px;
		line-height: 56px;
	}

	.wrapper-3 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		justify-content: center;
	}

	.search-4 {
		z-index: 460;
		margin-top: 20px;
		position: relative;
	}

	.div-block-161 {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.link-text-5 {
		font-size: 0.85rem;
	}

	.search-icon-5 {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.login-button-main-mobile-2 {
		font-size: 12px;
		display: none;
	}

	.nav-link-copy {
		font-size: 1rem;
	}

	.mega-desktop {
		min-height: 4.5rem;
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.uui-navbar02_menu-4 {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.nav-link-9,
	.nav-link-copy-2,
	.uui-navbar02_dropdown-toggle-4 {
		font-size: 1rem;
	}

	.div-block-153-old {
		margin-bottom: 0;
		padding-top: 10px;
		padding-bottom: 10px;
		position: fixed;
	}

	.button-6-slider-midle {
		height: 100%;
		text-align: center;
	}

	.button-6-copy {
		font-size: 14px;
	}

	.mobile-menu-arrow-4 {
		width: 16%;
	}

	.secondary-nav-list-2 {
		max-width: 50%;
		flex: 1;
	}

	.secondary-nav-list-item-2 {
		max-width: 100%;
		flex: auto;
	}

	.secondary-nav-list-link-2 {
		flex: 1;
		justify-content: center;
		padding-top: 2px;
		padding-left: 0;
		padding-right: 0;
	}

	.header-search-wrap-2 {
		padding-bottom: 1px;
	}

	.form-field-2.header-search-field {
		height: 3.25rem;
		font-size: 1em;
	}

	.button-7.header-search-button {
		width: 15%;
		height: 3.25rem;
		font-size: 1.125rem;
	}

	.log-in-link,
	.cart-new {
		margin-right: 0;
		padding-right: 0;
	}

	.header-search-wrap-2-mobile {
		padding-bottom: 1px;
	}

	.nav-link-9-mobile,
	.nav-link-9-copy {
		font-size: 1rem;
	}

	.button-6-slider-new {
		font-size: 14px;
	}

	.content-left-inner-03-copy {
		margin-left: 40px;
		margin-right: 20px;
	}

	.tabs-section-01-copy {
		background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('/assets/images/maroon_bg-2.svg');
		background-position: 0 0, 49% 0;
	}

	.footer-grip-link-2.white-logo {
		width: 133px;
	}

	.main-content-wrapper-2 {
		padding-top: 0;
		overflow: visible;
	}

	.main-content-wrapper-2.no-sidebars {
		width: auto;
		margin-left: 10px;
		margin-right: 10px;
	}

	.heading-5 {
		margin-bottom: 20px;
		font-size: 32px;
	}

	.heading-1 {
		font-size: 32px;
	}

	.heading-30 {
		margin-bottom: 0;
	}

	.left-col-item-wrap {
		margin-left: 0;
	}

	.breadcrumb-list-3 {
		font-size: 0.6875rem;
	}

	.container-6 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.inside-container-wraper {
		flex-direction: column;
	}

	.intro-text-style-2 {
		color: #39383a;
	}

	.main-content-wrapper-3 {
		padding-top: 0;
		overflow: visible;
	}

	.main-content-wrapper-3.no-sidebars {
		width: auto;
		margin-left: 10px;
		margin-right: 10px;
	}

	.heading-34 {
		margin-bottom: 20px;
		font-size: 32px;
	}

	.heading-36 {
		font-size: 32px;
	}

	.heading-37 {
		margin-bottom: 0;
	}

	.breadcrumb-list-4 {
		font-size: 0.6875rem;
	}

	.container-7 {
		padding-left: 10px;
		padding-right: 10px;
	}

	.container-7-outer {
		padding-top: 120px;
	}

	.button-main {
		height: auto;
		text-align: center;
		align-items: center;
	}

	.div-block-163 {
		width: 100%;
		margin-top: 30px;
	}

	.right-col-item-wrap {
		margin-left: 0;
	}

	.heading-42 {
		margin-bottom: 0;
	}

	.button-6-slider-new-copy {
		font-size: 14px;
	}

	.cart-icon {
		margin-left: 60px;
	}

	.cart-icon.mobile {
		margin-left: 10px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.account-2.mobile {
		background-size: 23px;
		margin-left: 0;
		padding-left: 20px;
	}
}

@media screen and (max-width: 479px) {
	.slider {
		height: 50vh;
	}

	.right-arrow,
	.left-arrow {
		display: none;
	}

	.home-content-boxes {
		height: auto;
		flex-wrap: wrap;
		justify-content: space-around;
		display: flex;
	}

	.div-block {
		width: 100%;
		height: 350px;
		position: static;
	}

	.div-block.four {
		width: 100%;
		height: 90px;
	}

	.div-block-2 {
		width: 100%;
		height: 350px;
		position: static;
	}

	.div-block-2.two {
		width: 100%;
		height: 200px;
	}

	.div-block-3 {
		width: 100%;
		height: 350px;
		position: static;
	}

	.slide-heading {
		width: 100%;
		max-width: none;
		text-align: center;
		margin-top: 0;
		margin-bottom: 5px;
		font-size: 18px;
		line-height: 1.5rem;
	}

	.slide-inner-content {
		justify-content: center;
		padding-left: 2rem;
		padding-right: 2rem;
		top: 0%;
	}

	.text-block,
	.text-block.gray {
		text-align: center;
	}

	.text-block.hero-image {
		margin-bottom: 20px;
		font-size: 1rem;
		line-height: 1.25rem;
		text-decoration: none;
		display: none;
	}

	.button {
		margin-bottom: 1rem;
	}

	.div-block-5 {
		align-items: center;
	}

	.content-headings {
		font-size: 2rem;
		line-height: 2.2rem;
	}

	.content-headings.quote {
		font-size: 14px;
	}

	.content-headings.blue {
		font-size: 18px;
	}

	.content-headings.white {
		text-align: center;
	}

	.content-headings.white.form-section {
		text-align: center;
		line-height: 2.2rem;
	}

	.content-headings.testimonials {
		font-size: 2rem;
		line-height: 2.2rem;
	}

	.div-block-9 {
		flex-direction: column;
	}

	.div-block-10 {
		flex-wrap: wrap;
	}

	.home-content-section.trainer {
		height: auto;
		flex-flow: column;
	}

	.home-content-section.reviews {
		height: auto;
		flex-wrap: nowrap;
		justify-content: flex-start;
		display: flex;
	}

	.home-content-section.banner {
		margin-top: 0;
	}

	.home-content-section.banner.image {
		height: auto;
		flex-direction: column;
		margin-top: 0;
	}

	.home-content-section.banner.two {
		height: 800px;
		flex-wrap: nowrap;
		margin-top: 100px;
	}

	.section-content {
		width: 100%;
		height: auto;
		padding: 2rem 1.5rem;
		position: static;
	}

	.section-content.contact-call-out {
		justify-content: center;
		padding-top: 3rem;
		padding-bottom: 3rem;
	}

	.div-block-12 {
		width: 100%;
		height: 250px;
		background-position: 50% 70%;
		flex-wrap: wrap;
		display: block;
		position: static;
	}

	.div-block-13 {
		text-align: left;
		flex-direction: column;
		padding-left: 10px;
		padding-right: 10px;
		display: block;
	}

	.paragraph {
		font-size: 1rem;
		line-height: 1.25rem;
	}

	.paragraph.white {
		text-align: center;
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.div-block-15 {
		margin-bottom: 10px;
		padding: 25px;
	}

	.div-block-16 {
		flex-direction: column;
		display: block;
	}

	.review-block {
		width: 100%;
		height: 175px;
		flex-flow: column wrap;
		justify-content: center;
		align-items: center;
		margin-bottom: 20px;
		display: flex;
		position: static;
	}

	.review-block.two {
		height: 175px;
		margin-bottom: 20px;
		position: static;
	}

	.review-block.three {
		height: 175px;
		position: static;
	}

	.paragraph-2,
	.paragraph-3,
	.paragraph-4 {
		text-align: center;
	}

	.rights-container-block {
		justify-content: center;
		padding-bottom: 1rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		display: block;
	}

	.footer-column-rights {
		width: 100%;
		flex-direction: column;
		margin-left: auto;
		margin-right: auto;
		padding-bottom: 1rem;
	}

	.footer-text {
		text-align: left;
		letter-spacing: 0;
		margin-left: 10px;
		margin-right: 10px;
	}

	.footer-text.rights {
		letter-spacing: 0;
	}

	.grip-link {
		width: 100%;
		justify-content: center;
		padding-right: 0;
	}

	.quote-block {
		margin-bottom: 0;
	}

	.footer-nav-list-link {
		background-image: none;
		background-repeat: repeat;
		background-size: auto;
		margin-left: 0;
		padding-left: 0;
		line-height: 1.25rem;
	}

	.footer-right-col-wrap {
		width: 100%;
	}

	.container-block.footer-container {
		padding-left: 1.5rem;
		padding-right: 1.5rem;
	}

	.social-media-link-block.linkedin {
		margin-right: 0;
	}

	.footer-nav-wrapper {
		justify-content: center;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
	}

	.footer-col-wrap {
		width: 100%;
		min-width: 0;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-right: 0;
		display: flex;
	}

	.footer-col-wrap.quick-links {
		margin-top: 2rem;
		padding-bottom: 0;
	}

	.footer-col-wrap.support {
		padding-bottom: 0;
	}

	.footer-col-wrap.connect {
		align-items: center;
		margin-top: 2rem;
	}

	.social-outer-wrap {
		align-items: center;
		margin-top: 0;
	}

	.social-media-wrapper {
		justify-content: center;
		margin-top: 0.25rem;
	}

	.multicol-wrap {
		flex-direction: column;
	}

	.contact-link {
		line-height: 1rem;
	}

	.footer-nav-list {
		text-align: center;
		margin-top: 0;
		margin-left: 0;
	}

	.div-block-42.reviews {
		margin-top: 400px;
	}

	.div-block-44 {
		width: 100%;
		height: 800px;
		flex-wrap: wrap;
		margin-top: 0;
		position: static;
	}

	.icon-block.two {
		height: 250px;
		margin-left: 0;
		margin-right: 0;
	}

	.icon-block.three {
		height: 250px;
	}

	.div-block-46 {
		width: 100%;
		right: 10%;
	}

	.container-4.nav-container-wrap {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.secondary-nav-list {
		height: 3.5rem;
		max-height: 3.5rem;
		max-width: none;
		flex-grow: 1;
		order: -1;
	}

	.logo {
		width: 140px;
		height: 100%;
		margin: 0;
		padding-top: 0;
		display: flex;
	}

	.logo.footer {
		width: auto;
		justify-content: center;
		margin-left: 0;
	}

	.menu-button-2 {
		height: 3.5rem;
		min-width: 3.75rem;
		margin-top: -170px;
		line-height: 3.5rem;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.mobile-bar-wrap {
		flex-basis: 100%;
		justify-content: space-between;
		align-self: center;
		align-items: center;
	}

	.logo-image {
		width: 130px;
		margin-top: 0;
		margin-bottom: 0;
		transform: translate(0, -33px);
	}

	.menu-button-icon {
		line-height: 3.5rem;
	}

	.secondary-nav-list-link {
		padding-left: 0.375rem;
		padding-right: 0.375rem;
	}

	.menu-button-text {
		font-size: 1rem;
		line-height: 3.5rem;
	}

	.header-bar-wrapper {
		padding-left: 1rem;
		padding-right: 0;
	}

	.content-heading.projects {
		margin-bottom: 16.5px;
		font-size: 1.5rem;
	}

	.paragraph-5.hero-image {
		margin-bottom: 20px;
	}

	.project-block-content {
		width: 100%;
		height: auto;
		flex-direction: column;
		justify-content: space-between;
		padding-bottom: 2rem;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		display: block;
	}

	.div-block-68.projects {
		margin-bottom: 20px;
	}

	.links-a {
		padding-top: 20px;
	}

	.links.project-1 {
		height: auto;
		padding-bottom: 15px;
	}

	.links._2 {
		height: auto;
		padding-top: 15px;
		padding-bottom: 15px;
	}

	.links._3 {
		height: auto;
		padding: 15px;
	}

	.div-block-65.projetcs {
		width: 4px;
		height: 40px;
	}

	.project-block {
		width: 100%;
		height: 100px;
		flex-direction: row;
		display: flex;
		position: static;
	}

	.project-block._2 {
		width: 100%;
		margin-top: -1px;
	}

	.project-block.black {
		width: 100%;
		padding-left: 0;
	}

	.project-block.two {
		width: 100%;
		height: 250px;
	}

	.arrow-button.projects {
		margin-top: -50px;
	}

	.content-headings-2 {
		font-size: 14px;
		line-height: 20px;
	}

	.content-headings-2.quote {
		font-size: 1.25rem;
		line-height: 1.75rem;
	}

	.content-block-two {
		width: 100%;
		height: auto;
		padding-top: 0;
		padding-left: 0;
		overflow: hidden;
	}

	.content-block-two.solid-background {
		margin-left: 0;
		right: auto;
	}

	.div-block-72 {
		width: 100%;
		height: 300px;
	}

	.content-block {
		align-items: center;
		margin-bottom: 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.form-block-wrapper {
		margin-bottom: 0;
	}

	.field-label {
		width: 100%;
		padding-left: 1rem;
	}

	.content-block-inner-content {
		width: 100%;
		height: auto;
		padding: 0;
		position: static;
	}

	.form-style {
		width: 225px;
	}

	.testimonial-wrapper {
		margin-bottom: 1rem;
	}

	.testimonials-name,
	.block-quote {
		text-align: center;
	}

	.search-icon {
		padding-left: 1.5rem;
	}

	.search-bar {
		display: none;
		top: 0;
	}

	.testimonial-column {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		display: flex;
	}

	.header-contents-wrap {
		padding-left: 1rem;
		padding-right: 1rem;
	}

	.menu-nav-button {
		height: 5.25rem;
		border-left-style: none;
		justify-content: flex-start;
		margin-left: auto;
		padding-left: 0;
		padding-right: 0;
		right: auto;
	}

	.menu-nav-button.w--open {
		color: rgba(255, 255, 255, 0.4);
	}

	.menu-button-icon-2 {
		line-height: 5.25rem;
	}

	.header-container {
		justify-content: space-between;
	}

	.form-content {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.brand-2 {
		height: 30px;
	}

	.image-8 {
		transform: translate(0, -31px);
	}

	.top-header-wrapper {
		display: flex;
	}

	.div-block-86 {
		padding-left: 10px;
	}

	.image-9 {
		width: 50px;
	}

	.image-block {
		width: 100%;
		height: auto;
	}

	.button-styling {
		flex-wrap: wrap;
		padding-left: 0;
	}

	.heading-6 {
		line-height: 3rem;
	}

	.breadcrumb-link-1.breadcrumb-link,
	.breadcrumb-link-1.breadcrumb-link-2 {
		font-size: 0.8rem;
	}

	.content-container.threequarterwidth {
		padding-left: 0;
		padding-right: 0;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.inside-pages-paragraph {
		font-size: 0.9rem;
	}

	.inner-image-content {
		background-position: 20%;
	}

	.inner-image-content.recent-blog-post {
		height: 250px;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.inside-content-beckground {
		padding-left: 20px;
		padding-right: 20px;
	}

	.text-block-21 {
		font-size: 0.8rem;
	}

	.div-block-140 {
		width: 100%;
		height: auto;
	}

	.inside-content-beckground-2 {
		padding-left: 20px;
		padding-right: 20px;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.intro-paragraph-3 {
		line-height: 1.5rem;
	}

	.paragraph-14 {
		font-size: 0.9rem;
	}

	.div-block-138 {
		background-position: 20%;
	}

	.div-block-138.recent-blog-post {
		height: 250px;
	}

	.text-block-22 {
		font-size: 0.8rem;
	}

	.content-block-2,
	.content-block-3 {
		margin-bottom: 1rem;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	.project-block-one {
		flex-direction: row;
		margin-bottom: 1rem;
	}

	.inner-content.projects {
		align-items: center;
	}

	.div-block-146 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.i {
		flex-direction: column;
		align-items: center;
		display: flex;
	}

	.div-block-148 {
		flex-direction: column;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.nav-bar-invisible-scroll-block {
		height: 71px;
	}

	.main-nav-link.mobile-duplicate {
		width: 80%;
	}

	.form-block-3 {
		width: 60%;
	}

	.search-icon-2 {
		margin-left: 10px;
		margin-right: 10px;
	}

	.search-icon-2.mobile {
		margin-left: auto;
		display: block;
	}

	.nav-menu-3 {
		margin-top: -5px;
	}

	.image-14 {
		max-width: 100%;
		padding-right: 0;
	}

	.nav-link-5.mobileonly.account {
		display: block;
	}

	.logo-3 {
		flex: 0 auto;
		margin: 0 auto 0.25rem 0;
		padding-top: 0;
		padding-left: 7px;
	}

	.search-content {
		width: 80vw;
		height: auto;
		border-top: 1px solid rgba(91, 91, 91, 0.15);
		align-items: center;
		margin-top: 100px;
		margin-left: 0%;
		margin-right: 15px;
		padding-top: 20px;
		padding-bottom: 20px;
		display: flex;
		top: 0;
		left: auto;
		right: 0;
	}

	.search-button-icon {
		background-size: 25px;
		border-radius: 0;
		margin-top: 0;
		padding-left: 15px;
		padding-right: 15px;
	}

	.search-button-icon:hover {
		border-radius: 20px;
	}

	.navigation {
		flex-direction: row;
		margin-left: 0;
		padding-top: 0;
		padding-bottom: 0;
	}

	.cart {
		margin-top: 0;
		margin-left: auto;
		margin-right: 0;
	}

	.mobile-menu-arrow {
		width: 20%;
		position: relative;
	}

	.icon-wrapper-3 {
		margin-left: 0;
		margin-right: 0;
		position: static;
		left: 0;
		right: 0;
	}

	.account-button-icon {
		background-size: 18px;
	}

	.cart-button-icon {
		background-size: 21px;
	}

	.account {
		margin-left: 0;
		margin-right: 0;
	}

	.account.mobile-view {
		display: none;
	}

	.menu-button-5 {
		margin-left: 0;
		margin-right: 0;
		padding-left: 5px;
		padding-right: 7px;
	}

	.slider-inner-content {
		width: 100%;
		align-items: center;
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
		padding-left: 0;
	}

	.right-block {
		top: 35%;
	}

	.section-container {
		height: 110vh;
	}

	.right-arrow-3 {
		width: 50px;
		height: 50px;
		align-items: center;
		display: flex;
	}

	.left-arrow-3 {
		width: 50px;
		height: 50px;
		justify-content: center;
		align-items: center;
		display: flex;
	}

	.image-4 {
		width: 250px;
		height: 250px;
		top: -25%;
	}

	.button-4 {
		margin-left: auto;
		margin-right: auto;
	}

	.div-block-151 {
		flex-direction: column;
	}

	.image-container {
		width: 70%;
		margin-left: auto;
		margin-right: auto;
		position: absolute;
		top: 10%;
		bottom: auto;
		left: 0%;
		right: 0%;
	}

	.item-right {
		padding-top: 51px;
		bottom: -14%;
	}

	.heading {
		font-size: 2vh;
		line-height: 1;
	}

	.icon {
		width: 35px;
		height: 35px;
		justify-content: center;
		align-items: center;
		font-size: 21px;
		display: flex;
	}

	.text-block-6 {
		font-size: 2vh;
	}

	.careers-link {
		padding-right: 0;
	}

	.main-button-2 {
		margin-top: 0;
		margin-bottom: 5px;
		margin-right: 0;
		display: none;
	}

	.location-link {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.contact-us {
		margin-left: 0.75rem;
		margin-right: 0.75rem;
	}

	.login-button-main {
		width: 136px;
		background-size: 15px;
		margin-top: 8px;
		padding: 5px 15px 5px 35px;
		font-size: 11px;
		display: block;
		position: absolute;
		top: 0%;
		bottom: auto;
		left: auto;
		right: 0%;
	}

	.search-input-2 {
		height: 30px;
		margin-right: -30px;
	}

	.link-text-2 {
		opacity: 1;
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.top-nav {
		padding-top: 10px;
		padding-bottom: 0;
	}

	.search-button {
		width: 30px;
		height: 30px;
	}

	.div-block-21 {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.search-2 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.div-block-153 {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.uui-navbar02_dropdown-content-right-2 {
		max-width: none;
		padding-left: 0.75rem;
		padding-right: 0.75rem;
	}

	.search-bar-2 {
		width: 90%;
		top: 81px;
	}

	.dropdown-link-3 {
		padding-left: 0;
	}

	.uui-navbar02_dropdown-content-left-2 {
		grid-template-columns: 1fr;
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	.uui-navbar02_dropdown-content-wrapper {
		width: 100%;
	}

	.login-button-main-mobile {
		margin-top: 0;
		margin-right: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 35px;
		font-size: 11px;
		display: none;
	}

	.uui-logo_component-5 {
		height: 3rem;
	}

	.search {
		margin-right: 0.5rem;
	}

	.uui-navbar02_blog-item {
		flex-direction: column;
		display: flex;
	}

	.menu-icon_component-2 {
		margin-right: -0.7rem;
	}

	.uui-navbar02_blog-content {
		margin-top: 0.5rem;
	}

	.search-icon-3 {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 10px;
	}

	.mega-menu {
		min-height: 4.8rem;
		top: 0;
	}

	.uui-navbar02_dropdown-blog-item-wrapper {
		grid-column-gap: 0px;
		grid-row-gap: 0.5rem;
		grid-template-columns: 1fr;
		grid-auto-columns: 1fr;
	}

	.content-3.mod--categories {
		flex-direction: column;
		grid-template-columns: 1fr;
		display: flex;
	}

	.search-icon-4 {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 0;
	}

	.search-3 {
		margin-right: 0.5rem;
	}

	.mega-menu-2 {
		z-index: 1000;
		min-height: 4.8rem;
		top: 0;
	}

	.uui-logo_component-6 {
		height: 3rem;
	}

	.slider-4 {
		height: 194px;
		border-width: 10px;
		box-shadow: 0 2px 5px 6px rgba(0, 0, 0, 0.2), 0 0 4px 3px rgba(223, 216, 188, 0.69);
	}

	.div-block-155 {
		margin-top: 20px;
	}

	.div-block-6 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.div-block-156 {
		border-radius: 0;
	}

	.news-section {
		margin-top: 60px;
		padding: 0;
	}

	.div-block-157 {
		width: 90%;
	}

	.image-16 {
		border-radius: 0;
	}

	.text-block-36 {
		margin-left: 20px;
		margin-right: 20px;
	}

	.div-block-159 {
		width: 100%;
		margin-bottom: 20px;
	}

	.heading-22 {
		margin-top: 20px;
		margin-left: 20px;
		margin-right: 20px;
		font-size: 20px;
		line-height: 25px;
	}

	.text-block-6-copy {
		font-size: 2vh;
	}

	.link-text-3 {
		white-space: nowrap;
		flex: 0 auto;
	}

	.button-copy,
	.button-2b,
	.button-3b {
		margin-bottom: 1rem;
	}

	.contact-link-copy {
		line-height: 1rem;
	}

	.content-wrap-left {
		margin-bottom: 20px;
	}

	.heading-3 {
		align-self: center;
	}

	.tabs-menu-01 {
		border-right-style: none;
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.content-grid-03 {
		grid-template-columns: 1fr;
	}

	.inner-hero-content-04 {
		padding: 0;
	}

	.content-left-inner-03 {
		border-left-style: none;
		align-items: center;
		margin-bottom: 20px;
		margin-left: 20px;
		padding-left: 0;
	}

	.section-title {
		margin-bottom: 20px;
		margin-right: 0;
	}

	.clients-section-01 {
		flex-direction: column;
	}

	.in-tabs-image {
		min-height: 300px;
	}

	.button-wrapper {
		flex-direction: column;
	}

	.tab-link-01 {
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.brand-link {
		padding-left: 0;
	}

	.paragraph-17.center {
		text-align: center;
	}

	.icon-div.smaller.icons-position {
		width: 100%;
		align-items: center;
	}

	.button-6 {
		width: 100%;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.button-6.categories {
		width: auto;
	}

	.tabs-01 {
		flex-direction: column;
	}

	.form-section-inner-02 {
		width: 100%;
		flex-direction: column;
		padding: 20px 10px;
	}

	.client-logo-2,
	.nexsletter-form-block-01 {
		width: 100%;
	}

	.hero-section-04,
	.hero-section-04.no-slant {
		padding-top: 100px;
		padding-bottom: 100px;
	}

	.form-section-02 {
		width: auto;
		display: block;
	}

	.tabs-content-01 {
		padding: 20px;
	}

	.heading-2.pricing {
		text-align: center;
	}

	.big-image-form-02 {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	.tabs-section-01 {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.section-6 {
		display: none;
	}

	.section-6.is-red.footer-links {
		display: block;
	}

	.container-3 {
		padding-left: 0;
	}

	.grid-3 {
		width: auto;
		object-fit: fill;
		flex: 0 auto;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		justify-items: stretch;
		margin-left: 0;
		margin-right: 0;
	}

	.links-2.on-footer {
		padding-right: 0;
	}

	.heading-2-copy.pricing {
		text-align: center;
	}

	.secondary-button-about {
		width: 100%;
		margin-left: 0;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.primary {
		height: 50px;
	}

	.primary.inside {
		height: auto;
		margin-right: 0;
		padding-top: 13px;
		padding-bottom: 13px;
		display: inline-block;
	}

	.secondary.style {
		margin-left: 20px;
	}

	.section-title-tap {
		font-size: 28px;
	}

	.in-tabs-image-3,
	.in-tabs-image-2 {
		min-height: 300px;
	}

	.section-title-about {
		text-align: center;
		font-size: 25px;
		line-height: 29px;
	}

	.heading-2-copy.pricing {
		text-align: center;
	}

	.heading-2-copy-copy {
		text-align: center;
		justify-content: center;
	}

	.heading-2-copy-copy.pricing {
		text-align: center;
	}

	.column-2 {
		flex-direction: column;
		align-items: center;
		padding-left: 0;
	}

	.footer-signoff-links.last {
		margin-right: 0;
	}

	.grip-logo-link {
		float: none;
		display: none;
	}

	.web-development-by {
		width: auto;
		left: 87px;
	}

	.column-3 {
		margin-bottom: 0.5rem;
	}

	.footer-copyright {
		text-align: center;
	}

	.property-slider-widget-2 {
		width: 100%;
		height: 100%;
		padding-top: 2rem;
		padding-left: 0;
		padding-right: 0;
	}

	.badge-new {
		font-size: 14px;
		line-height: 16px;
	}

	.badge-new.small {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		line-height: 16px;
	}

	.btn-primary-2 {
		width: 100%;
		padding-top: 18px;
		padding-bottom: 18px;
	}

	.btn-primary-2.buy {
		font-size: 14px;
	}

	.icon-10 {
		background-color: #fff;
		font-size: 1.5rem;
	}

	.icon-10:hover {
		background-color: #fff;
		border-radius: 50px;
	}

	.event-img {
		background-image: url('/assets/images/Group-10.jpg');
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: cover;
	}

	.property-spotlight-mask-2 {
		padding-left: 0;
		padding-right: 0;
	}

	.badge-special-offer {
		font-size: 14px;
		line-height: 16px;
	}

	.badge-special-offer.small {
		padding-top: 10px;
		padding-bottom: 10px;
		font-size: 14px;
		line-height: 16px;
	}

	.property-slider-arrow-link-4 {
		width: 45px;
		height: 45px;
		box-shadow: none;
		border-width: 4px;
		margin-left: 0;
		margin-right: 10px;
		font-size: 1.25rem;
		bottom: 600px;
	}

	.property-slider-arrow-link-4.property-arrow-right {
		box-shadow: none;
		margin-left: 1.5rem;
		margin-right: 0;
		bottom: 600px;
	}

	.property-slider-arrow-link-4.property-arrow-right:hover {
		background-color: rgba(0, 0, 0, 0);
	}

	.property-spotlight-slide-2 {
		width: 100%;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
	}

	.content-card-03-a,
	.content-card-03-b {
		align-items: center;
		padding-left: 20px;
	}

	.image-17 {
		width: auto;
		align-self: center;
	}

	.content-card-03-c,
	.content-card-03-d {
		align-items: center;
		padding-left: 20px;
	}

	.subscribe-wrapper {
		margin-right: 0;
		padding: 10px;
	}

	.heading-15 {
		margin-top: 0;
		font-size: 18px;
	}

	.mg-bottom-24px {
		margin-bottom: 16px;
	}

	.heading-25 {
		margin-top: 0;
		font-size: 18px;
	}

	.card-copy.content-left {
		padding-top: 40px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.grid-3-columns {
		width: 100%;
		grid-template-columns: 1fr;
		justify-items: stretch;
		padding-left: 20px;
		padding-right: 20px;
	}

	.heading-17 {
		margin-top: 0;
		font-size: 18px;
	}

	.card.content-left {
		padding-top: 40px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.card.content-left.link-card {
		width: 100%;
		background-size: 200px;
		padding-left: 0;
		padding-right: 0;
	}

	.card.content-left.link-card.promo-4 {
		width: auto;
		background-size: cover;
	}

	.heading-26,
	.heading-27,
	.heading-28 {
		margin-top: 0;
		font-size: 18px;
	}

	.search-4 {
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		grid-template-rows: auto auto;
		grid-template-columns: 1fr 1fr;
		grid-auto-columns: 1fr;
		justify-content: center;
		margin-top: 0;
		margin-bottom: 0;
		display: none;
	}

	.div-block-161 {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.link-text-5 {
		opacity: 1;
		font-size: 0.85rem;
		line-height: 1rem;
	}

	.search-icon-5 {
		width: 35px;
		height: 35px;
		background-size: 13px;
		margin-left: 10px;
	}

	.login-button-main-mobile-2 {
		margin-top: 0;
		margin-right: 0;
		padding-top: 5px;
		padding-bottom: 5px;
		padding-left: 35px;
		font-size: 11px;
		display: none;
	}

	.text-block-48 {
		font-size: 1rem;
	}

	.uui-navbar02_menu-button-4.w--open {
		z-index: 50;
	}

	.mega-desktop {
		min-height: 4.8rem;
		top: -1px;
	}

	.uui-navbar02_item-heading-4 {
		color: var(--beige-2);
		font-family: Aleo, sans-serif;
		font-size: 14px;
	}

	.uui-navbar02_item-heading-4:hover {
		color: var(--beige-2);
	}

	.uui-navbar02_dropdown-list-4.w--open {
		background-color: rgba(0, 0, 0, 0);
		margin-bottom: 0;
	}

	.search-5 {
		margin-right: 0.5rem;
	}

	.div-block-153-old {
		justify-content: space-between;
		padding-top: 10px;
		padding-bottom: 10px;
		display: none;
	}

	.button-6-slider-midle {
		height: 50px;
	}

	.left-arrow-4,
	.right-arrow-5 {
		display: none;
	}

	.slide-nav-3 {
		bottom: -57px;
	}

	.button-6-copy {
		width: 50%;
		width: 50%;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.mobile-menu-arrow-4 {
		width: 20%;
		position: relative;
	}

	.nav-dropdown-link-4 {
		width: 84%;
		background-position: 95%;
	}

	.secondary-nav-list-2 {
		max-width: 136px;
	}

	.secondary-nav-list-item-2 {
		max-width: none;
		margin-right: 0.25rem;
	}

	.secondary-nav-list-link-2 {
		padding-top: 0;
		padding-left: 0;
		padding-right: 0;
	}

	.secondary-nav-icon-2 {
		margin-top: 0;
		margin-right: 0;
	}

	.secondary-nav-text-2 {
		display: none;
	}

	.header-search-form-2 {
		width: 80%;
	}

	.form-field-2.header-search-field {
		height: 3rem;
	}

	.button-7.header-search-button {
		width: 20%;
		height: 3rem;
	}

	.log-in-link,
	.cart-new {
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
	}

	.button-6-slider-new {
		width: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.content-left-inner-03-copy {
		border-left-style: none;
		align-items: center;
		margin-bottom: 20px;
		margin-left: 20px;
		padding-left: 0;
	}

	.tabs-section-01-copy {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.promo-borders {
		width: 98%;
	}

	.content-card-area {
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
	}

	.brands {
		width: 100%;
		align-items: center;
		padding-left: 20px;
	}

	.footer-grip-link-2.white-logo {
		margin-bottom: 10px;
	}

	.main-content-wrapper-2.no-sidebars {
		margin-left: 0;
		margin-right: 0;
	}

	.heading-5 {
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 30px;
	}

	.heading-3 {
		margin-bottom: 10px;
	}

	.inside-content-column {
		margin-right: 0;
	}

	.heading-1 {
		width: 100%;
		margin-top: 0;
		font-size: 30px;
	}

	.heading-30 {
		font-size: 24px;
	}

	.list-2 {
		padding-left: 20px;
	}

	.inside-page-content-wrap-no-sidebars-2 {
		margin-left: 0;
		margin-right: 0;
	}

	.button-8 {
		padding-top: 13px;
		padding-left: 22px;
		padding-right: 1.15px;
	}

	.inside-container-wraper {
		flex-direction: column;
	}

	.image-37 {
		width: 90%;
		max-width: none;
	}

	.heading-32 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.inside-page-header-content-wrap-2 {
		width: auto;
	}

	.main-content-wrapper-3.no-sidebars {
		margin-left: 0;
		margin-right: 0;
		padding-top: 0;
	}

	.heading-34 {
		margin-top: 0;
		margin-bottom: 20px;
		font-size: 30px;
	}

	.heading-36 {
		width: 100%;
		margin-top: 0;
		font-size: 30px;
		text-decoration: none;
	}

	.heading-37 {
		margin-bottom: 10px;
		font-size: 24px;
	}

	.list-3 {
		padding-left: 20px;
	}

	.container-7 {
		margin-top: 0;
	}

	.container-7-outer {
		padding-top: 100px;
	}

	.image-38 {
		width: 90%;
		max-width: none;
	}

	.heading-39 {
		margin-top: 0;
		margin-bottom: 10px;
	}

	.button-main {
		height: auto;
	}

	.button-secondary.style {
		color: var(--white);
	}

	.div-block-163 {
		width: auto;
	}

	.heading-40 {
		margin-top: 0;
		margin-bottom: 0;
	}

	.heading-42 {
		font-size: 24px;
	}

	.spark-brand-logo {
		width: 80%;
	}

	.image-39 {
		width: 100%;
		max-width: none;
		display: none;
	}

	.image-40 {
		display: block;
	}

	.button-tertiary.style {
		margin-top: 20px;
		margin-left: 0;
	}

	.button-6-slider-new-copy {
		width: auto;
		margin-top: 10px;
		margin-bottom: 10px;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.cart-icon {
		margin-left: 20px;
	}

	.cart-icon.mobile {
		width: 32px;
		background-size: 25px;
		margin-left: 10px;
		margin-right: 0;
		padding-left: 10px;
		padding-right: 10px;
		display: block;
	}

	.account-2 {
		margin-left: 10px;
	}

	.account-2.mobile {
		width: 25px;
		height: 25px;
		background-size: 20px;
		margin-left: 8px;
		padding-left: 10px;
		padding-right: 10px;
		display: block;
	}
}

#w-node-_3fbaacd5-c42b-aae1-9f3a-6befe6d257ec-665ce68d {
	order: 9999;
}

#w-node-_5fc32b8f-4c78-3aa5-e145-f3bb26d3e01e-665ce68d,
#w-node-_183b536a-4f68-d996-fa89-feb8d902e07a-665ce68d,
#w-node-_6de0b5c1-db6d-3e5f-b3a5-4171de77784d-665ce68d,
#w-node-f3ff4140-1ded-7755-9f2d-10d211756c6d-665ce68d {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-be169693-3f50-5246-2298-bb7b859553d7-859553d4 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: stretch;
}

#w-node-be169693-3f50-5246-2298-bb7b859553e6-859553d4,
#w-node-be169693-3f50-5246-2298-bb7b859553f5-859553d4,
#w-node-be169693-3f50-5246-2298-bb7b85955400-859553d4 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-be169693-3f50-5246-2298-bb7b85955405-859553d4 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: start;
	justify-self: start;
}

#w-node-_9f914b4f-6107-c340-2e6e-ab5dd9e3361d-d9e3360c,
#w-node-_52577647-2ca2-f039-43f5-7892ad5527d9-665ce691,
#w-node-_6360dc6b-80ff-ac85-5ef0-b4837d9e635a-665ce691,
#w-node-_6360dc6b-80ff-ac85-5ef0-b4837d9e635a-665ce696,
#w-node-_0c9c468e-816c-86f4-d9f7-56050456829a-9f8a29a6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3fbaacd5-c42b-aae1-9f3a-6befe6d257ec-9f8a29a6 {
	order: 9999;
}

#w-node-_5fc32b8f-4c78-3aa5-e145-f3bb26d3e01e-9f8a29a6,
#w-node-_183b536a-4f68-d996-fa89-feb8d902e07a-9f8a29a6,
#w-node-_6de0b5c1-db6d-3e5f-b3a5-4171de77784d-9f8a29a6,
#w-node-f3ff4140-1ded-7755-9f2d-10d211756c6d-9f8a29a6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-abb3019a-9e7d-524e-05de-a498ddf45f4a-9f8a29a6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: stretch;
}

#w-node-abb3019a-9e7d-524e-05de-a498ddf45f53-9f8a29a6,
#w-node-abb3019a-9e7d-524e-05de-a498ddf45f62-9f8a29a6,
#w-node-a9a752e2-03f6-6b15-40e7-2453e8da97e7-9f8a29a6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	justify-self: start;
}

#w-node-_2d07283f-9d5f-209e-710d-f4a5fb31b6f6-9f8a29a6 {
	grid-area: span 1 / span 1 / span 1 / span 1;
	align-self: start;
	justify-self: start;
}

@media screen and (max-width: 991px) {
	#w-node-df1b1ed4-c6c4-71b5-68f3-01a95ca1a4b8-665ce68d {
		justify-self: end;
	}

	#w-node-_5e5b0246-bfcd-5725-dd5a-a712792d88b4-665ce68d {
		justify-self: center;
	}

	#w-node-f252568b-e075-fd04-081b-bbb99a8e2f58-665ce68d {
		justify-self: start;
	}

	#w-node-_20992c80-c854-bbd5-cc84-b7dfde3d7ddc-665ce68d {
		grid-area: span 1 / span 3 / span 1 / span 3;
	}

	#w-node-df1b1ed4-c6c4-71b5-68f3-01a95ca1a4b8-9f8a29a6 {
		justify-self: end;
	}

	#w-node-_5e5b0246-bfcd-5725-dd5a-a712792d88b4-9f8a29a6 {
		justify-self: center;
	}

	#w-node-f252568b-e075-fd04-081b-bbb99a8e2f58-9f8a29a6 {
		justify-self: start;
	}

	#w-node-_20992c80-c854-bbd5-cc84-b7dfde3d7ddc-9f8a29a6 {
		grid-area: span 1 / span 3 / span 1 / span 3;
	}
}

/***************** OLD CSS **************/
/****************************************/
table.content {
	border: 0px;
}

table.content tr {
	border: 0px;
}

table.content th {
	background: #5d0004;
	text-align: left;
	color: #ffffff;
	font-size: 14px;
	font-family: 'Roboto';
	font-weight: normal;
	padding: 4px 12px 5px 12px;
	border: 0px;
	font-weight: 500;
}

table.content td {
	border: 0px;
	padding: 2px 12px 3px 12px;
	font-family: 'Roboto Condensed';
	font-size: 14px;
	font-weight: 400;
}

table.content tr:nth-child(even) td {
	background: #efefef;
}

body#external-first-data {
	background-color: white;
}

#locations_module.list .google_map_wrapper {
	width: 71%;
}

/*ecommerce*/
/*listing page*/
#products_module.responsive.cms_list .category-heading {
	display: flex;
}

#products_module.responsive.cms_list .category-heading div {
	display: block;
	width: 100%;
	float: none;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content {
	width: 40%;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content .promo-box-inside.locator {
	background-color: #eae0d1;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content h2 {
	color: var(--maroon-2);
	margin-top: 0;
	margin-bottom: 0px;
	font-size: 22px;
	line-height: 26px;
	font-family: Libre Baskerville, sans-serif;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content h2 a {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-size: 22px;
	line-height: 26px;
	font-family: Libre Baskerville, sans-serif;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content img {
	border-radius: 8px;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content p {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 1.6rem;
	color: #39383a;
	display: inline-block;
	width: 100%;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content p a {
	color: #1b1b1b;
	font-weight: 500;
	text-decoration: underline;
}

#products_module.responsive.cms_list .category-heading .category-heading-side-content p a:hover {
	color: var(--maroon);
	font-weight: 500;
}

@media screen and (max-width: 767px) {
	#products_module.responsive.cms_list .category-heading {
		display: block;
	}

	#products_module.responsive.cms_list .category-heading .category-heading-side-content {
		width: 70%;
		margin-bottom: 20px;
		margin-left: auto;
		margin-right: auto;
	}
}

@media screen and (max-width: 479px) {
	#products_module.responsive.cms_list .category-heading .category-heading-side-content {
		width: 100%;
	}
}

#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: 14px;
}

.pagination-wrapper {
	padding: 2px 0 10px;
	font-size: 12px;
	border-top: 1px solid var(--maroon);
}

.pagination-wrapper.pagination-wrapper-top {
	border-top: 0px;
}

.pagination-wrapper select {
	padding: 5px;
}

.pagination-wrapper .pagination-sort-items.single-page .views {
	float: left;
}

.pagination-wrapper .pagination-sort-items {
	padding-left: 0;
}

.item-price {
	color: #5d0004;
	font-weight: bold;
}

.items .item-price {
	font-size: 14px;
}

.pagination-filter.pagination-bg.pagination-page-selection-top {
	margin-right: 20px;
	justify-content: flex-end;
}

.fly-out-menu-container .fly-out-menu-button {
	background-color: #f3f3f3;
	text-transform: uppercase;
	font-size: 0.7rem;
	line-height: 1rem;
}

.pagination-wrapper .pagination-sort-items > .views a img,
.pagination-wrapper .pagination-sort-items > .views img {
	height: 25px;
	margin-top: -2px;
}

/*details page*/
#products_module.cms_entity .item-price {
	font-size: 1rem;
}

#products_module.cms_entity.responsive .product-sku {
	font-size: 11px;
	margin-bottom: 10px;
}

#products_module.responsive .add_to_cart_form .subtotal_row strong {
	font-size: 13px;
	float: none;
}

#products_module.responsive .add_to_cart_form .contentbox_item {
	margin-top: 0;
}

.add_to_cart_form_container .subtotal_row div {
	float: none !important;
	display: inline;
}

/*ask a question form*/
#products_module.responsive.cms_form .required-fields-information em,
#products_module.responsive.cms_form .ui-form-container .ui-form-label em {
	color: #ff0000;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
}

/*cart*/
#s-cart #s-promotions .s-form-container input {
	height: 26px;
}

#s-cart #s-promotions .s-form-container button {
	height: 27px;
}

/*checkout*/
#s-sign-in .ui-form-label em {
	color: #ff0000;
}

#s-customer .s-button {
	max-width: 335px;
}

#s-checkout .ui-form-label em {
	color: #ff0000;
}

#locations_module_search_contentbox.contentbox_item .item_field input#Keywords {
	width: 94%;
}

/* gdpr */
#cookie-consent-container {
	box-sizing: border-box;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	padding: 4px 8px;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	display: block;
}

#cookie-consent-container .consent-disclaimer p {
	color: #fff;
}

#cookie-consent-container .consent-disclaimer a {
	color: #fff;
	text-decoration: underline;
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Webflow **********************/
/*****************************************************/

/*********************** Header **********************/
/*****************************************************/
.cart-new.desktop {
	display: flex;
}

.cart-desktop {
	display: block;
}

.cart-icon.mobile,
.cart-mobile {
	display: none;
}

.login-dropdown.mobile {
	margin: 0;
	padding: 0;
	display: none;
}

.login-dropdown .w-dropdown-toggle {
	padding: 0px;
}

.login-dropdown .w-dropdown-list {
	padding-top: 7px;
	background-color: transparent;
	right: auto;
	left: -15px;
}

.login-dropdown .dropdown-list-inner {
	background-color: #fff;
	width: 10rem;
	background-color: #fff;
	border: 1px solid #eaecf0;
	border-top-width: 0;
	border-radius: 0 0 0.45rem 0.45rem;
	margin-top: 0px;
	padding: 5px;
}

.login-dropdown .w-dropdown-list a.w-dropdown-link {
	padding: 10px;
}

.login-dropdown .w-dropdown-list a.w-dropdown-link:hover {
	text-decoration: underline;
}

.consent-form-visible .mega-desktop {
	z-index: 0;
}

.consent-form-visible .section-slider {
	margin-top: 0px;
}

@media (max-width: 991px) {
	.cart-new.desktop,
	.cart-desktop {
		display: none;
	}

	.cart-icon.mobile,
	.cart-mobile {
		display: block;
	}

	.login-dropdown.mobile {
		display: inline-block;
	}
}

/***************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu ul {
	list-style-type: none;
	margin: 0px;
	padding: 0px;
	display: flex;
	align-content: center;
}

.accesible-navigation-menu ul li.nav-link-li {
	position: relative;
}

.accesible-navigation-menu ul li.hasChildren a.nav-link-7 {
	padding-right: 35px;
}

.accesible-navigation-menu li.hasChildren .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: auto;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul {
	display: none;
	border: 0;
	background-color: #000000;
	padding: 0rem;
	margin: 0px;
	list-style-type: none;
	width: 100%;
	margin-top: 0;
	min-width: 215px;
	padding: 0.5rem;
	margin-top: 10px;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li {
	display: flex;
	padding: 0;
	width: 100%;
	position: relative;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a {
	letter-spacing: 0.5px;
	color: #fff;
	text-transform: capitalize;
	border: 0px solid rgba(0, 0, 0, 0);
	border-radius: 0;
	margin-bottom: 0.35rem;
	margin-left: 0;
	margin-right: 0;
	padding: 0.35em 0.75em 0.35em 0.75rem;
	font-family: Aleo, sans-serif;
	font-size: 14px;
	font-weight: 400;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul a:hover,
.accesible-navigation-menu li.hasChildren .container-wrapper ul a.w--current {
	color: #fff;
	background-color: rgba(63, 62, 62, 0.3);
	border-style: solid;
	border-color: #fff;
	text-decoration: underline;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul {
	display: inline-block;
}

.accesible-navigation-menu li.hasChildren.open .container-wrapper ul ul,
.accesible-navigation-menu li.hasChildren:hover .container-wrapper ul ul,
.accesible-navigation-menu li.hasChildren .container-wrapper ul ul {
	display: none;
	position: absolute;
	left: 100%;
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul ul.centerVertically {
	transform: translateY(-25%);
}

.accesible-navigation-menu li.hasChildren .container-wrapper ul li.haschildren-2:hover ul {
	display: inline-block;
}

.accesible-navigation-menu .rotated {
	color: #fff;
	transform: rotate(-90deg);
	margin-right: 10px;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}

	.mm-page.mm-slideout {
		width: 100% !important;
		display: inherit !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mm-wrapper_opened .mega-desktop {
	z-index: 100 !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #000;
}

.mobile-navigation-menu li a.nav-a,
.mm-navbar__title {
	text-transform: uppercase;
	font-family: Libre Baskerville, sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	text-decoration: none;
	color: #f5f3e8;
	padding: 15px 20px;
}

.mobile-navigation-menu li a.nav-a:hover,
.mobile-navigation-menu li a.nav-a.active {
	text-decoration: underline;
}

.mm-btn_next:after {
	border-color: #f5f3e8;
}

.mm-listitem:after {
	left: 0px;
}

.mobile-navigation-menu li.has-submenu div.mm-panel {
	padding-top: 0px;
}

.mobile-navigation-menu li.has-submenu div.mm-panel a.nav-a {
	font-size: 15px;
	line-height: 18px;
	text-transform: none;
	padding: 10px 20px;
}

.mm-wrapper_opened .w-nav-overlay {
	display: none !important;
}

.header-search-form-2.mobile {
	margin: 15px 20px;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit !important;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

.cbtext a {
	color: #333;
}

.content-3.mod--categories .primary {
	margin-left: 20px;
}

.nl-form-container {
	display: flex;
	flex-wrap: wrap;
	max-width: 17vw;
}

.nl-form-container .text-field-01.half {
	width: calc(50% - 5px);
	margin-right: 5px;
}

.nl-form-container .text-field-01.email-field {
	width: calc(100% - 104px);
}

.nl-form-container input[type="text"],
.nl-form-container input[type="submit"] {
	margin-bottom: 7px;
}

.content-3.mod--categories {
	min-height: 5.3125rem;
	height: auto;
}

@media screen and (max-width: 991px) {
	.nl-form-container {
		max-width: 100%;
	}

	.content-3.mod--categories .primary {
		margin-left: 0px;
	}
}

@media screen and (max-width: 767px) {
	.card.content-left.link-card.promo-4 {
		width: 90%;
	}

	.promo-borders {
		width: calc(100% - 6px);
	}

	.promo-borders-in {
		width: 100%;
	}

	.content-card-03-a {
		width: 100%;
	}
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.slider-text p:last-child {
	margin-bottom: 0px;
}

@media screen and (max-width: 991px) {
	.text-block.hero-image.slider-text {
		display: none;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list-4 li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list-4 li:last-child a:hover,
.breadcrumb-list-4 li:last-child a {
	color: #313131;
	letter-spacing: 0.02rem;
	text-transform: uppercase;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem;
	line-height: 1rem;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-weight: normal;
}

.left-nav-list-link-3.active {
	color: #672300;
	background-image: none;
	background-size: auto;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

.left-nav-nested-list-link-2.active {
	color: #672300;
	background-image: url('../images/angle-right_6f6e6d.svg');
	background-position: 0;
	background-repeat: no-repeat;
	background-size: 6px;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
}

@media (min-width: 992px) {
	.inside-content-column.has-left-nav {
		width: calc(80% - 40px);
		margin-left: 20px;
	}

	.inside-content-column.has-left-nav.has-right-nav {
		width: 100%;
	}
}

/************** Right Sidebar ********************/
/*************************************************/
.right-sidebar td img {
	max-width: none;
}

.right-sidebar .box {
	border-bottom: 1px solid #672300;
	margin-bottom: 0.9rem;
	padding-bottom: 3px;
}

.right-sidebar .box:last-child {
	border-bottom: 0px;
}

.right-sidebar .box p {
	color: #39383a;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.right-sidebar .box h4,
.right-sidebar .box h3 {
	color: #672300;
	margin-top: 0;
	padding-left: 5px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 24px;
	line-height: 30px;
	font-weight: bold;
	margin-bottom: 10px;
}

.right-sidebar .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar a {
	color: #313131;
	font-family: Poppins, sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.4em;
	transition-property: none;
}

.right-sidebar a:hover {
	color: #3c4a20;
}

.right-sidebar .box.tools input[type="text"] {
	width: 100%;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.inside-content-column a,
#cookie-consent-form-container form .consent-body .category-tabs a {
	color: #313131;
}

.inside-content-column a:hover,
#cookie-consent-form-container form .consent-body .category-tabs a:hover {
	color: #672300;
}

#cookie-consent-form-container form .consent-body .category-tabs .tab:last-of-type {
	padding-bottom: 1.2rem;
}

.inside-page-content-wrap-no-sidebars-2 td img {
	max-width: none;
}

h1 {
	color: var(--maroon-2);
	text-align: left;
	text-transform: capitalize;
	font-family: Libre Baskerville, sans-serif;
	font-size: 36px;
	font-weight: 700;
	line-height: 44px;
}

h2 {
	color: var(--maroon-2);
	margin-top: 0;
	font-family: Libre Baskerville, sans-serif;
}

h3 {
	color: var(--maroon-2);
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 23px;
	line-height: 29px;
}

h4 {
	color: var(--maroon-2);
	font-family: Libre Baskerville, sans-serif;
	font-weight: 600;
}

h5 {
	color: var(--maroon-2);
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0;
	text-transform: uppercase;
}

h6 {
	color: var(--maroon-2);
	font-family: Poppins, sans-serif;
}

@media screen and (max-width: 991px) {
	h1 {
		color: var(--maroon-2);
		font-size: 38px;
	}

	h4 {
		color: var(--maroon-2);
		font-size: 20px;
	}
}

@media screen and (max-width: 767px) {
	h1 {
		font-size: 32px;
	}

	h4 {
		margin-bottom: 0;
	}
}

@media screen and (max-width: 479px) {
	h1 {
		font-size: 30px;
	}

	h2 {
		margin-top: 0;
		margin-bottom: 10px;
	}

	h4 {
		margin-bottom: 10px;
		font-size: 24px;
	}
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: #672300 !important;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: #000 !important;
}

.inside-content-column p {
	color: #39383a;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

.inside-content-column li {
	font-family: Poppins, sans-serif;
	font-size: 1rem;
}

/* buttons */
.inside-row .button,
.inside-row a.primary,
.homeboxes a.primary,
.primary,
.inside-row button[type="submit"],
.inside-row .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	border: 3px solid var(--olive-2);
	background-color: var(--slate-grey) !important;
	color: white !important;
	text-transform: none;
	border-radius: 0 !important;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
	box-shadow: 0 0 7px #2b150b;
	cursor: pointer;
	text-decoration: none;
	margin: 0px;
	box-shadow: none;
	background-image: none !important;
}

.inside-row .button:hover,
.inside-row a.primary:hover,
.homeboxes a.primary:hover,
.primary:hover,
.inside-row button[type="submit"]:hover,
.inside-row .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	color: #fff !important;
	background-color: #485875 !important;
}

.secondary,
.inside-row a.secondary,
.homeboxes a.secondary,
.inside-row button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-row .cms_form_button.secondary,
.inside-row .button.secondary,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger {
	display: inline-block;
	border: 3px solid #485875;
	background-color: #485875 !important;
	color: white !important;
	text-transform: none;
	border-radius: 0 !important;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
	box-shadow: 0 0 7px #2b150b;
	cursor: pointer;
	text-decoration: none;
	margin: 0px;
	box-shadow: none;
	background-image: none !important;
}

.secondary:hover,
.inside-row a.secondary:hover,
.inside-row button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-row .cms_form_button.secondary:hover,
.inside-row .button:hover,
.homeboxes a.secondary:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover {
	color: #fff !important;
	background-color: #485875 !important;
}

.tertiary,
.inside-row button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-row a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	border: 3px solid #5c6e91;
	background-color: #fff !important;
	color: #3f3f3f !important;
	text-transform: none;
	border-radius: 0 !important;
	padding: 13px 22px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 14px;
	font-weight: 700;
	line-height: 22px;
	transition: all 0.2s;
	box-shadow: 0 0 7px #2b150b;
	cursor: pointer;
	text-decoration: none;
	margin: 0px;
	box-shadow: none;
	background-image: none !important;
}

.tertiary:hover,
.inside-row a.tertiary:hover,
.inside-row button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	color: #3f3f3f !important;
	background-color: #fff !important;
	border-color: #8d835b !important;
}

/* messages */
#message.success {
	color: #000;
	background-color: #caf3aa;
	border: 2px solid #48a700;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
}

#message.error {
	color: #000;
	background-color: #ffccd0;
	border: 2px solid #721926;
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	font-size: 1.125rem;
	font-weight: 400;
}

#message.success p {
	color: #000;
	font-weight: 400;
}

#message.error p {
	color: #000;
	font-weight: 400;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #fff;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #672300;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 1.125em;
}

table.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #485875;
	border-bottom: 0px solid white;
	color: white;
	text-align: left;
	font-family: Poppins, sans-serif;
	font-size: 1.125rem;
	line-height: 1.125em;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
	transition: none;
}

table.styled th a:hover {
	color: inherit;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 1.05rem;
	line-height: 1.4125rem;
	font-family: Poppins, sans-serif;
}

table.styled.striped tr:nth-child(even) {
	background: #eee;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	vertical-align: middle;
	padding: 8px 10px;
	border-style: solid;
	border-width: 1px;
	border-color: #666666;
	font-size: 0.875rem;
	margin-bottom: 10px;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	background-color: rgba(103, 35, 0, 0.1);
	border-left-width: 0;
	border-left-color: #672300;
	padding: 20px 30px;
	border: 2px solid #672300;
}

blockquote p {
	font-size: inherit !important;
	line-height: inherit !important;
	font-family: inherit !important;
	color: inherit !important;
	font-weight: inherit !important;
}

blockquote p:last-child {
	margin-bottom: 0px;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.footer_link-list {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.footer_link-list li {
	margin: 0px;
	padding: 0px;
	padding-bottom: 4px;
}

.footer_link-list li a {
	display: inline-block;
}

.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
}

h2.heading-4 a {
	color: inherit !important;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

.container-3 {
	max-width: 960px;
}

@media screen and (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
		color: #fff !important;
		text-decoration: none;
		border: 1px solid transparent;
	}

	.phone-mobile:hover {
		color: #fff !important;
	}

	.container-3 {
		max-width: 728px;
	}
}

@media screen and (max-width: 767px) {
	.grid-3 {
		display: grid;
		grid-row-gap: 40px;
		grid-column-gap: 40px;
	}

	.container-3 {
		width: auto;
	}
}

@media screen and (max-width: 479px) {
	#GRIPFooterLogo {
		margin-bottom: 10px;
	}
}

/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields {
	padding: 0 0 0 200px;
}

.ui-form-container .ui-form-label {
	left: -210px;
	width: 200px;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

.ui-form-label {
	color: #333;
	font-size: 14px;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

.form-shipping-info .ui-form-buttons,
.form-payment-info .ui-form-buttons,
#s-checkout .ui-form-buttons.ui-form-field-place-order {
	padding: 0 0 0 0px !important;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (min-width: 992px) {
	#account_module.form .required_message {
		margin-right: 120px;
	}
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* submission forms */
#submissionforms_module {
	text-align: left;
}

#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input,
#submissionforms_module.cms_form .sigWrapper.current {
	border-color: #666;
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

#locations_module.entity h2 {
	font-size: 25px;
	line-height: 30px;
	margin-top: 15px;
}

#locations_module.entity h2.title {
	margin-top: 0px;
	font-size: 30px;
	line-height: 35px;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

#news_module.cms_list td img {
	max-width: none;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}

	#news_module.cms_list .cms_list_item table,
	#news_module.cms_list .cms_list_item tr,
	#news_module.cms_list .cms_list_item td,
	#news_module.cms_list .cms_list_item th,
	#news_module.cms_list .cms_list_item tbody {
		display: inline-block;
		width: 100%;
		margin: 0px;
		padding: 0px;
	}

	#news_module.cms_list .cms_list_item .cms_date h3 {
		margin: 0px;
		font-size: 20px;
		line-height: 25px;
	}
}

/* newseltter */
#newsletters_module.entity ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

#newsletters_module.entity h3,
#newsletters_module.entity h4 {
	margin-top: 0px;
}

#newsletters_module.form .form_item .field {
	padding-bottom: 15px;
}

#newsletters_module.list .item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0px 1.5rem 0px;
}

@media (min-width: 992px) {
	#newsletters_module.form .form_buttons {
		margin-top: 15px;
		text-align: left;
		margin-left: 36.6%;
	}
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

#photoalbums_module.cms_entity .slideshow-wrapper #main-slideshow .slide-caption {
	background-color: #666;
	font-family: inherit;
	color: #ffffff;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */
.right-sidebar .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* rss */
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

/* services */
#services_module.list .list_item .title h3 {
	margin-top: 0px;
}

#services_module.entity .image {
	float: none;
	margin: 0px 0px 10px 0px;
}

#services_module.list .list_item .footer {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	padding-bottom: 0px;
	margin-top: 0px;
}

#services_module.list .list_item {
	margin-bottom: 1.5rem;
	margin-top: 0.5rem;
}

/* testimonials */
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_item_divider,
#testimonials_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* gdpr */
#cookie-consent-container .consent-all-trigger,
#cookie-consent-container .consent-all-trigger:hover,
#cookie-consent-container .reject-all-trigger,
#cookie-consent-container .reject-all-trigger:hover {
	padding: 13px 22px !important;
	border: 3px solid #8d835b !important;
	background-color: #5c6e91 !important;
	color: #fff !important;
}

#cookie-consent-form-container form .consent-header {
	background-color: #f9f9f9;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	text-align: center;
	display: block;
	max-width: 460px;
}

#cookie-consent-container.minimized {
	right: 100px;
}

#cookie-consent-container {
	padding-right: 5rem;
}

@media (max-width: 767px) {
	#cookie-consent-container.minimized {
		right: 60px;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/* events */
#events_module.homepage_contentbox {
	background-color: #fff;
	padding: 20px;
	width: 90%;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	width: 100px;
}

#events_module.homepage_contentbox .event_list table tr td.title {
	width: calc(100% - 100px);
}

/* news */
#news_module.homepage_contentbox {
	background-color: #fff;
	padding: 20px;
	width: 90%;
}

#news_module.homepage_contentbox .cms_title h3 {
	font-size: 1.1rem;
	margin: 0px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1rem;
	margin: 0px;
}

#news_module.homepage_contentbox .cms_description {
	margin-bottom: 20px;
}

/* partners */
#partners_module.homepage_contentbox {
	background-color: #fff;
	padding: 20px;
	width: 90%;
}

/* feeds */
#feeds_module.homepage_contentbox {
	background-color: #fff;
	padding: 20px;
	width: 90%;
}

/* testimonials */
#testimonials_module_contentbox {
	background-color: #fff;
	padding: 20px;
	width: 90%;
}

/****************** eCommerce ******************/
/***************************************************/
/* product categorsies */
#products_module.responsive.cms_list .items.items-categories {
	width: 90%;
}

#products_module.responsive.cms_list .items.items-categories .item-category .item-image {
	border: none !important;
	padding: 5px;
}

#products_module.responsive.cms_list .items.items-categories .item-category .item-image a {
	height: 200px;
	line-height: 200px;
}

#products_module.responsive .items .item-wrapper .item .item-description {
	text-align: center;
}

#products_module.responsive.cms_list .items.items-categories .item-wrapper.item-category .item-description,
#products_module.responsive.cms_list .items.items-categories .item-wrapper.item-category .item-description .metadata1.cms_title h3,
#products_module.responsive.cms_list .items.items-categories .item-wrapper.item-category .item-description .metadata1.cms_title h3 a {
	color: #313131;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	font-weight: 700;
}

#products_module.responsive.cms_list .items.items-categories .item-wrapper.item-category .item-description .metadata1.cms_title h3 a:hover {
	color: var(--maroon-2);
}

@media (max-width: 767px) {
	#products_module.responsive.cms_list .items.items-categories {
		width: 100%;
	}
}

/* porduct list */
#products_module.responsive.cms_list .items .item-wrapper .item .item-image,
#products_module.responsive .related-items .items .item-wrapper .item .item-image {
	border: 1px solid #000;
}

#cross_sells .items .item-wrapper .item,
#products_module.responsive.cms_list .items.grid-view .item-wrapper .item,
#products_module.responsive .related-items .items.grid-view .item-wrapper .item {
	margin-right: 15px;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description .metadata1.cms_title h3,
#products_module.responsive .related-items .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	margin-top: 7px;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description .metadata1.cms_title h3,
#products_module.responsive.cms_list .items .item-wrapper .item .item-description .metadata1.cms_title h3 a,
#products_module.responsive .related-items .item-wrapper .item .item-description .metadata1.cms_title h3,
#products_module.responsive .related-items .item-wrapper .item .item-description .metadata1.cms_title h3 a {
	color: #313131 !important;
	font-family: Poppins, sans-serif;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: 500;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-description .metadata1.cms_title h3 a:hover,
#products_module.responsive .related-items .items .item-wrapper .item .item-description .metadata1.cms_title h3 a:hover {
	color: var(--maroon-2) !important;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-prices,
#products_module.responsive.cms_list .items .item-wrapper .item .item-prices .item-price,
#products_module.responsive .related-items .items .item-wrapper .item .item-prices,
#products_module.responsive .related-items .items .item-wrapper .item .item-prices .item-price {
	color: #313131;
	margin-top: 10px;
	font-size: 1.25rem;
	font-weight: normal;
	font-family: Poppins, sans-serif;
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-short-description p,
#products_module.responsive .related-items .items .item-wrapper .item .item-short-description p {
	background-color: var(--maroon);
	border-radius: 4px;
	padding: 2px 5px;
	color: var(--white);
	text-transform: none !important;
	font-family: Poppins, sans-serif;
	font-size: 0.8rem !important;
	line-height: 0.85rem;
	font-weight: 500;
	margin-top: 0px !important;
	margin-bottom: 5px !important;
	caret-color: var(--white);
}

#products_module.responsive.cms_list .items .item-wrapper .item .item-short-description p *,
#products_module.responsive .related-items .items .item-wrapper .item .item-short-description p * {
	color: inherit !important;
}

/* porduct details */
#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	color: var(--maroon-2);
	margin-bottom: 10px;
	font-family: Libre Baskerville, sans-serif;
	font-size: 23px;
	line-height: 29px;
	margin-top: 40px;
	display: inline-block;
	width: 100%;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item-content {
	border: 0px;
	border-top: 1px solid #d6d6d6;
	font-family: Poppins, sans-serif;
	font-size: 1.1rem;
	font-weight: 300;
	line-height: 1.4;
}

#products_module.responsive.cms_entity .product-details-tabs .tab-item,
#products_module.responsive.cms_entity .product-details-tabs .tab-item:hover {
	border: 0px;
	background-color: transparent;
	border-bottom: 3px solid transparent !important;
	font-family: Poppins, sans-serif;
	font-size: 1.25rem;
	text-transform: none;
	font-weight: normal;
}

#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item,
#products_module.responsive.cms_entity .product-details-tabs .selected-tab-item:hover {
	border: 0px;
	border-bottom: 3px solid #1b1b1b !important;
	background-color: transparent;
	font-weight: normal;
}

#products_module.responsive.cms_entity .item-price {
	font-family: Poppins, sans-serif;
	font-size: 1.6rem;
	font-weight: normal;
	line-height: 1.4;
	color: #333;
}

#products_module.responsive.cms_entity .product-sku {
	font-family: Poppins, sans-serif;
	font-size: 14px;
	line-height: 20px;
	color: #333;
}

#products_module.responsive.cms_entity .cms_form_button.primary.cart-estimate-button {
	border-width: 3px;
	border-color: var(--maroon-2) !important;
	background-color: var(--maroon-2) !important;
	font-size: 14px;
	padding: 13px 22px;
	max-width: 320px;
	margin-top: 15px;
	margin-right: 20px;
}

#products_module.responsive.cms_entity .cms_form_button.primary.cart-estimate-button:hover {
	background-color: #571f02 !important;
	text-decoration: underline;
}

#products_module.responsive.cms_entity .add_to_cart_form #AddToWishList {
	background-color: var(--dark-slate-blue-2) !important;
	border-width: 3px;
	border-color: #485875 !important;
	font-size: 14px;
	padding: 13px 22px;
	max-width: 320px;
	margin-top: 15px;
}

#products_module.responsive.cms_entity .add_to_cart_form #AddToWishList:hover {
	background-color: #2c4168 !important;
	text-decoration: underline;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	background-color: #fff;
}

#products_module.responsive.cms_entity .add_to_cart_form .submit_row_center {
	text-align: left;
}

#products_module.responsive.cms_entity .link-button.ask-question,
#products_module.responsive.cms_entity .link-button.write-review {
	font-weight: 500;
}

#products_module.responsive.cms_entity .add_to_cart_form .quantity_row,
#products_module.responsive.cms_entity .add_to_cart_form .contentbox_item {
	color: #333;
}

#products_module.responsive.cms_entity .mini-slide-image-outer-wrapper {
	border: 1px solid #672300;
}

#products_module.responsive.cms_entity .mini-slide-image-outer-wrapper.selected {
	border: 1px solid #ffc600;
}

@media screen and (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive.cms_entity .add_to_cart_form .cart-estimate-button,
	#products_module.responsive.cms_entity .add_to_cart_form .cart-estimate-button {
		width: 100%;
		max-width: 400px !important;
	}
}

@media screen and (min-width: 1025px) {
	#products_module.responsive.cms_entity .product-details-slideshow-wrapper {
		width: 50%;
	}

	#products_module.responsive.cms_entity .product-details-info-wrapper {
		width: 50%;
	}
}
